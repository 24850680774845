import React from "react";
import { useParams, Navigate } from 'react-router-dom';
import WelcomeSection from "../components/WelcomeSection";
import DistributorsProviders from "../components/DistributorsProviders";
import ReferralProgram from "../components/ReferralProgram";
import SolutionsCards from "../components/SolutionsCards";
import CarouselSolutions from "../components/CarouselSolutions";
import Distributors from "../components/Distributors";
import Banner from "../components/Banner";
import ContactFormIsos from "../components/ContactFormIsos";
import data from '../data/Isos.json'
import DistributorsProducts from "../components/DistributorsProducts";
import DistributorsValues from "../components/DistributorsValues";


const Isos = () => {
    const { id } = useParams();
    const productData = data.find((product) => product.id === id);

    if (!productData) {
        return <Navigate to="/404" />;
    } 
    
    return (
        <div className="isos">
            <WelcomeSection data={productData.welcome[0]} />
            <DistributorsProviders data={productData.technicalSpecifications} />
            <ReferralProgram data={productData.referralProgram[0]}/>
            <SolutionsCards data={productData.solutionPes[0]} />
            <DistributorsProducts data={productData.products[0]} />
            <CarouselSolutions data={productData.carosuelSolutions[0]} />
            <DistributorsValues data={productData.technicalSpecificationsTwo} />
            <Distributors data={productData.distributors[0]}/>
            <Banner data={productData.bannerSection[0]} />
            <ContactFormIsos />
        </div>
    );
};

export default Isos;