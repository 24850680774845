import React from 'react';

const Plugins = ({ data }) => {
  return (
    <section className='mx-auto max-w-6xl '>
      <h2 className=' text-5xl text-center xs:text-3xl'>{data[0].title}</h2>

      <div className='grid grid-cols-3 xs:grid-cols-1 lg:mx-8 xs:gap-y-4 xs:mx-4 gap-x-4 mt-14'>
        {data[1].map((item, index) => (
          <div key={index} className='border-gray-200 space-y-2 border shadow-sm p-4'>
            <img src={item.img} alt={item.title} />
            <h3>{item.title}</h3>            
            <p>{item.textRegular}</p>
          </div>
        ))}
      </div>      
    </section>
  );
};

export default Plugins;
