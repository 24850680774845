import React from "react";
import { useParams } from "react-router-dom";
import data from "../data/BlogArticle09.json";

const BlogArticle09 = () => {
    const { id } = useParams();
    const productData = data.find((product) => product.id === id);

    return (
        <div className={`${productData.id}-page product-page mx-auto overflow-hidden`}>
            <header className="mb-12">
                <img
                    src={productData.general[0].mainImage}
                    alt="Pagos contactless"
                    className="w-full h-full object-contain mb-10"
                />
                <div className="mx-auto w-[90%] xs:text-center">
                    <p className="text-3xl mb-4 xs:text-xl 2xl:text-2xl">{productData.header[0].pOne}</p>
                    <p className="text-3xl mb-4 xs:text-xl 2xl:text-2xl">{productData.header[0].pTwo}</p>
                </div>
            </header>

            <section className="grid grid-cols-2 mx-auto items-center gap-8 mb-20 w-[90%] xs:grid-cols-1">
                <div className="md:w-1/2 xs:w-full xs:text-center">
                    <h2 className="text-4xl font-bold text-[#ec0000] mb-10 xs:text-xl 2xl:text-3xl">
                        {productData.sectionOne[0].title}
                    </h2>
                    <p className="text-3xl text-gray-700 mb-12 xs:text-xl 2xl:text-2xl">
                        {productData.sectionOne[0].pOne}
                    </p>
                    <p className="text-3xl text-gray-700 py-12 xs:text-xl 2xl:text-2xl">
                        {productData.sectionOne[0].pTwo}
                    </p>
                </div>
                <img
                    src={productData.sectionOne[0].image}
                    alt="Pagos contactless"
                    className="pl-12 h-[80%] mx-auto object-contain xs:h-72 xs:pl-0"
                />
            </section>

            <section className="relative mb-20 xs:flex">
                <div className="relative h-full">
                    <img
                        src={productData.sectionTwo[0].image}
                        alt="Tecnología NFC"
                        className="w-full h-full object-contain"
                    />
                    <div className="absolute top-0 left-0 w-[50%] h-full p-10 lg:relative lg:p-0 lg:w-full">
                        <div className="lg:text-center">
                            <h2 className="ml-16 text-4xl font-bold text-[#ec0000] mb-6 mt-10 2xl:text-3xl 2xl:ml-6 lg:mr-0 xs:text-xl xs:ml-0">
                                {productData.sectionTwo[0].title}
                            </h2>
                            <div className="ml-16 text-3xl py-10 2xl:text-xl lg:ml-0 lg:p-10 xs:text-lg 2xl:ml-6 xs:ml-0">
                                <p className="mb-10 2xl:mb-0">{productData.sectionTwo[0].pOne}</p>
                                <p className="mb-10 pt-10 2xl:mb-0">{productData.sectionTwo[0].pTwo}</p>
                                <p className="mb-10 pt-10 2xl:pt-10">
                                    {productData.sectionTwo[0].pThree}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-20 w-[90%] mx-auto xs:text-center">
                <h2 className="text-4xl font-bold text-[#ec0000] mb-6 xs:text-xl 2xl:text-3xl">
                    {productData.sectionThree[0].title}
                </h2>
                <p className="text-3xl mb-4 xs:text-xl 2xl:text-2xl">
                    {productData.sectionThree[0].pOne
                        .split(
                            "Esta gran innovación permite que emprendedores y pequeños negocios que tienen que desplazarse lleven una Terminal en su celular para seguir cobran-do en cualquier lugar y en cualquier momento."
                        )
                        .map((part, index) =>
                            index === 0 ? (
                                part
                            ) : (
                                <span key={index}>
                                    <span className="font-bold">
                                        Esta gran innovación permite que emprendedores y pequeños
                                        negocios que tienen que desplazarse lleven una Terminal en
                                        su celular para seguir cobran-do en cualquier lugar y en
                                        cualquier momento.
                                    </span>
                                    {part}
                                </span>
                            )
                        )}
                </p>
                <p className="text-3xl xs:text-xl 2xl:text-2xl">{productData.sectionThree[0].pTwo}</p>
            </section>

            <section className="mb-20 gap-4">
                <img
                    src={productData.sectionFour[0].gTappImage01}
                    alt="GTapp"
                    className="w-full object-contain"
                />
                <img
                    src={productData.sectionFour[0].gTappStepsImage}
                    alt="Pasos GTapp"
                    className="w-full object-contain mt-10"
                />
            </section>

            <section className="mb-20 gap-4">
                <div className="w-screen relative left-1/2 right-1/2 -mx-[50vw] bg-[#ec0000] py-10 mb-10">
                    <h2 className="text-3xl font-bold text-white text-center container mx-auto px-4 xs:text-xl">
                        {productData.sectionFive[0].title}
                    </h2>
                </div>
                <img
                    src={productData.sectionFive[0].gTappStepsImage}
                    alt="GTapp"
                    className="w-full object-contain pt-10"
                />
                <img
                    src={productData.sectionFive[0].gTappStepsImage2}
                    alt="Pasos GTapp"
                    className="w-full object-contain mt-10"
                />
                <div className="w-screen relative left-1/2 right-1/2 -mx-[50vw] bg-[#f1f1f1] py-10 mt-10">
                    <p className="text-3xl  text-gray-700 text-center container mx-auto px-4 xs:text-xl 2xl:text-2xl">
                        {productData.sectionFive[0].pOne}
                    </p>
                    <p className="text-2xl  text-gray-700 text-center container mx-auto px-4 xs:text-xl 2xl:text-xl">
                        {productData.sectionFive[0].pTwo}
                    </p>
                </div>
            </section>

            <section className="grid grid-cols-2 items-center justify-between mx-auto gap-8 mb-20 w-[90%] xs:grid-cols-1">
                <div className="">
                    <h2 className="text-4xl font-bold text-[#ec0000] mb-10 xs:text-xl xs:text-center 2xl:text-3xl flex items-center gap-4 xs:gap-0 xs:block">
                        {productData.sectionSix[0].title}
                        <img
                            src={productData.sectionSix[0].titleImage}
                            alt="Recomendaciones GTapp"
                            className="h-24 xs:h-12 2xl:h-20 xs:mx-auto"
                        />
                    </h2>
                    <p className="text-3xl mb-12 xs:text-xl xs:text-center 2xl:text-2xl">
                        {productData.sectionSix[0].pOne}
                    </p>
                    <p className="text-3xl xs:text-xl xs:text-center 2xl:text-2xl">
                        {productData.sectionSix[0].pTwo
                            .split("¡G Tapp es para ti!")
                            .map((part, index) =>
                                index === 0 ? (
                                    part
                                ) : (
                                    <span key={index}>
                                        <span className="font-bold">¡G Tapp es para ti!</span>
                                        {part}
                                    </span>
                                )
                            )}
                    </p>
                </div>
                <img
                    src={productData.sectionSix[0].image}
                    alt="Recomendaciones GTapp"
                    className="pl-14 h-[80%] mx-auto object-contain xs:pl-0 xs:h-72 2xl:h-[65%]"
                />
            </section>


            <section className="relative mb-20 xs:flex">
                <div className="relative h-full 2xl:block">
                    <img
                        src={productData.sectionSeven[0].image}
                        alt="Tecnología NFC"
                        className="w-full h-full object-cover md:object-contain"
                    />
                    <div className="absolute top-0 left-0 w-[50%] h-full p-10 lg:relative lg:w-full md:p-0">
                        <div className="lg:text-center">
                            <h2 className="ml-16 text-4xl font-bold text-[#ec0000] mb-6 mt-10 2xl:text-3xl 2xl:ml-6 xs:ml-0 xs:text-xl">
                                {productData.sectionSeven[0].title}
                            </h2>
                            <div className="ml-16 text-3xl py-10 2xl:text-xl 2xl:ml-6 xs:ml-0">
                                <p className="mb-10">{productData.sectionSeven[0].pOne}</p>
                                <img
                                    src={productData.sectionSeven[0].payMethodsImage}
                                    alt="Tecnología NFC"
                                    className="w-full h-full object-cover md:object-contain 2xl:h-12"
                                />
                                <p className="mb-10 pt-10">
                                    {productData.sectionSeven[0].pTwo}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-20">
                <div className="grid grid-cols-2 mx-auto items-center gap-8 mb-20 w-[90%] xs:grid-cols-1">
                    <div className="xs:text-center">
                        <h2 className="text-4xl font-bold text-[#ec0000] mb-10 xs:text-xl 2xl:text-3xl">
                            {productData.sectionEight[0].title}
                        </h2>
                        <p className="text-3xl text-gray-700 mb-4 xs:text-xl 2xl:text-2xl">
                            {productData.sectionEight[0].pOne}
                        </p>
                        <img
                            src={productData.sectionEight[0].advantagesImage}
                            alt="Ventajas"
                            className="w-full mt-[20%] 2xl:mt-0"
                        />
                    </div>
                    <img
                        src={productData.sectionEight[0].image}
                        alt="Ventajas GTapp"
                        className="object-contain xs:mx-auto xs:h-72 2xl:h-50"
                    />
                </div>
            </section>

            <section className="text-center relative">
                <div className="flex items-start justify-end w-[75%] mx-auto xs:justify-center 2xl:w-[80%] lg:justify-center">
                    <img
                        src={productData.finalSection[0].logoImage}
                        alt="Getnet Logo"
                        className="h-40 xs:h-16 2xl:h-32"
                    />
                </div>
                <h2 className="text-5xl max-w-6xl mx-auto xs:text-xl 2xl:text-4xl 2xl:max-w-4xl lg:p-6">
                    {productData.finalSection[0].title
                        .split("G Tapp")
                        .map((part, index) =>
                            index === 0 ? (
                                part
                            ) : (
                                <span key={index}>
                                    <span className="font-bold">G Tapp</span>
                                    {part}
                                </span>
                            )
                        )}
                </h2>
            </section>
        </div>
    );
};

export default BlogArticle09;
