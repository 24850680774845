import React from "react";
import '../styles/BlogSectionArticle.css'

const BlogSectionArticle = ({ data }) => {
    return (
        <div className={`blog-section-article ${data.class}`}>
            {data.title && <h2>{data.title}</h2>}
            {data.pOneB && <p><b>{data.pOneB}</b></p>}
            {data.pOne && <p>{data.pOne}</p>}
            {data.pTwoB && <p><b>{data.pTwoB}</b></p>}
            {data.pTwo && <p>{data.pTwo}</p>}
            {data.pThreeB && <p><b>{data.pThreeB}</b></p>}
            {data.pThree && <p>{data.pThree}</p>}
            {data.image && <img src={data.image} alt={data.title}/>}
            {data.pFourB && <p><b>{data.pFourB}</b></p>}
            {data.pFour && <p>{data.pFour}</p>}
            {data.pFiveB && <p><b>{data.pFiveB}</b></p>}
            {data.pFive && <p>{data.pFive}</p>}            
            {data.pSix && <p>{data.pSix}</p>}
        </div>
    );

};

export default BlogSectionArticle;