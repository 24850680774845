import React from "react";
import data from "../data/Isos.json";
import "../styles/ProductSection.css";

const DistributorsProducts = () => {
    const products = data[0]?.products[0]?.product;
    const isMobile = window.innerWidth <= 768;

    return (
        <section className="m-2 xs:m-4 pb-32 xs:pb-6">
            <h2 className="text-center pt-10 text-5xl xs:text-3xl  lg:text-4xl">
                {data[0]?.products[0]?.title}
            </h2>
            <div className="grid grid-cols-2 xs:grid-cols-1 2xl:mx-20 xl:mx-2 xs:mx-0 mt-14">
                {products.map((product, index) => (
                    <div
                        key={index}
                        className={`${isMobile ? " principal-product" : product.class} ${index > 0 && "mt-5"
                            }  ${index > 0 && !isMobile && "mx-40 lg:mx-2"} `}
                    >
                        <div className={`${index === 0 && "max-w-6xl lg:mx-20"} xs:mx-0`}>
                            {product.subheading && (
                                <h4
                                    className={`${index > 0 || (index === 0 && isMobile)
                                        ? "text-left text-[#ec0000] text-lg"
                                        : "product-subheading"
                                        }`}
                                >
                                    {product.subheading.toUpperCase()}
                                </h4>
                            )}
                            <h3
                                className={`${index > 0 || (index === 0 && isMobile)
                                    ? "text-start"
                                    : "text-center"
                                    } text-[#EC0000]`}
                            >
                                {product.titleOne}{" "}
                                <span className="text-black">{product.titleTwo}</span>
                            </h3>
                            <div
                                className={`${index > 0 || (index === 0 && isMobile)
                                    ? "text-start"
                                    : "text-center"
                                    }`}
                            >
                                <h3 className="text-black font-bold text-xl">
                                    {product?.textBold}
                                    <span className="product-regular">
                                        {product?.textRegular}
                                    </span>
                                </h3>
                            </div>
                        </div>
                        <div
                            className={`flex ${index === 0 ? "flex-col" : "flex-col-reverse"
                                } xs:flex-col gap-y-2`}
                        >
                            <div className={`flex`}>
                                <a href={product.link} className="mx-auto">
                                    <img
                                        src={product.productImg}
                                        alt={product.titleOne}
                                        className="w-full h-full object-contain"
                                    />
                                </a>

                            </div>
                            <div
                                className={`flex flex-row  gap-x-2 ${index === 0
                                    ? "justify-center items-center"
                                    : "justify-start items-start mt-4"
                                    } ${index > 0 && 'xs:justify-center xs:items-start xs:pr-40'}  gap-y-10`}
                            >
                                {product.actionSecondary && (
                                    <a href={product.link}>
                                        <button className={`action-secondary`}>
                                            {product.actionSecondary}
                                        </button>
                                    </a>
                                )}
                                {product.actionPrimary && (
                                    <a
                                        href={`${product.linkTwo}?source=${encodeURIComponent(
                                            product.source
                                        )}`}
                                    >
                                        <button className="action-primary px-4 py-2">
                                            {product.actionPrimary}
                                        </button>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default DistributorsProducts;
