import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Welcome from '../components//Welcome';
import Details from '../components//Details';
import BannerGtapp from '../components//BannerGtapp';
import TechnicalSpecifications from '../components//TechnicalSpecifications';
import Functionalities from '../components//Functionalities';
import BannerSayYes from '../components//BannerSayYes';
import DetailsProduct from '../components//DetailsProduct';
import Iframe from '../components//Iframe';
import Sectors from '../components//Sectors';
import FAQ from '../components//faq';
import CarouselSolutions from '../components//CarouselSolutions';
import data from '../data/GTapp.json';

const GTapp = () => {

    useEffect(() => {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          console.log("Acceso a la cámara concedido", stream);
          stream.getTracks().forEach(track => track.stop());
        })
        .catch((error) => {
          console.error("Permiso de cámara denegado:", error);
        });
  
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log("Ubicación obtenida:", position);
          },
          (error) => {
            console.error("Permiso de ubicación denegado:", error);
          }
        );
      } else {
        console.error("Geolocalización no disponible en este navegador.");
      }
    }, []);
  

  const productData = data.find((product) => product.id === 'gtapp'); 

  if (!productData) {
    return <Navigate to="/404" />;
  }

  return (
    <div className={`${productData.id}-page product-page`}>
      <Welcome data={productData.welcome[0]} />
      <Details details={productData.details} />
      <BannerGtapp data={productData.bannerGtapp[0]} />
      <TechnicalSpecifications data={productData.technicalSpecifications} />
      <Functionalities data={productData.functionalities} />
      <BannerSayYes data={productData.bannerSayYes[0]} />
      <DetailsProduct data={productData.detailsProduct} />
      <Iframe data={productData.Iframe[0]}/>
      <Sectors data={productData.sectors[0]} bg={true} />
      <FAQ data={productData.faq[0]} />
      <CarouselSolutions data={productData.carosuelSolutions[0]} />
    </div>
  );
};

export default GTapp;

