import React, { useEffect, useState } from "react";
import Sidebar from "../../components/dashboard/layout/Sidebar";
import { Settings, Image, LayoutDashboard, Home } from "lucide-react";
import DynamicCarouselBanner from "../../components/dashboard/banner/DynamicCarouselBanner";
import { doc, fireStore, getDoc, updateDoc } from "../../utils/firebase/config";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { uploadFileToStorage } from "../../services/uploadFile";

const Banners = () => {
  const [initialValues, setInitialValues] = useState({
    title: "",
    cards: [
      {
        cardImage: "",
        mainText: "",
        secondaryText: "",
        textRegular: "",
        fileDownload: "",
      },
    ],
  });

  useEffect(() => {
    (async () => {
      const redDoc = (
        await getDoc(doc(fireStore, `home/sYoXZj1hFy1qJXDOFo93`))
      ).data();

      if (!redDoc) {
        return;
      }

      // Set initial values from Firestore
      setInitialValues({
        title: redDoc.paymentSolutions?.title || "",
        cards: redDoc.paymentSolutions?.cards || [
          {
            cardImage: "",
            mainText: "",
            secondaryText: "",
            textRegular: "",
            fileDownload: "",
            redirectLink: "",
          },
        ],
      });
    })();
  }, []);

  // Yup validation schema
  const validationSchema = Yup.object({
    title: Yup.string().required("El título es requerido"),
    cards: Yup.array()
      .of(
        Yup.object({
          cardImage: Yup.string().required("La URL de la imagen es requerida"),
          mainText: Yup.string(),
          secondaryText: Yup.string(),
          textRegular: Yup.string(),
          fileDownload: Yup.string(),
          redirectLink: Yup.string(),
        })
      )
      .min(1, "Debe haber al menos una tarjeta"),
  });

  const handleSubmit = async (values) => {
    try {
      await updateDoc(doc(fireStore, `home/sYoXZj1hFy1qJXDOFo93`), {
        paymentSolutions: values,
      });
      setInitialValues(values);
      alert("Banner actualizado exitosamente!");
    } catch (error) {
      console.error("Error updating banner: ", error);
      alert("Error al actualizar el banner.");
    }
  };

  // Función para manejar la carga de imágenes
  const handleImageUpload = async (file, setFieldValue, index) => {
    if (!file) return;

    const url = await uploadFileToStorage(file);
    if (url) {
      setFieldValue(`cards.${index}.cardImage`, url); // Actualiza el campo cardImage con la URL
    } else {
      alert("Error al subir la imagen. Inténtalo de nuevo.");
    }
  };

  // Función para manejar la carga de archivos (PDF o Excel)
  const handleFileUpload = async (file, setFieldValue, index) => {
    if (!file) return;

    // Validar el tipo de archivo
    const allowedTypes = [
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    if (!allowedTypes.includes(file.type)) {
      alert("Solo se permiten archivos PDF o Excel.");
      return;
    }

    const url = await uploadFileToStorage(file);
    if (url) {
      setFieldValue(`cards.${index}.fileDownload`, url); // Actualiza el campo fileDownload con la URL
    } else {
      alert("Error al subir el archivo. Inténtalo de nuevo.");
    }
  };

  return (
    <div className="flex mx-auto mt-32">
      <Sidebar />
      <div className="flex-1 p-5">
        <h1 className="text-2xl font-bold flex items-center gap-2">
          <Settings size={24} />
          Configuración de banners
        </h1>
        <p className="text-gray-700 mt-2 flex items-center gap-2">
          <Home size={18} />
          Esta sección está destinada a configurar el banner inicial de la vista
          principal (Home).
        </p>
        <p className="text-gray-700 mt-2 flex items-center gap-2">
          <Image size={18} />
          Aquí puedes personalizar las imágenes, textos y enlaces que aparecerán
          en el banner.
        </p>
        <p className="text-gray-700 mt-2 flex items-center gap-2">
          <LayoutDashboard size={18} />
          Asegúrate de guardar los cambios después de realizar las
          modificaciones.
        </p>
        <h3 className="text-xl font-bold flex items-center gap-2">
          <Settings size={24} />
          Preview Banner Actual
        </h3>
        {/* Preview Image */}
        <DynamicCarouselBanner paymentSolutionsElement={initialValues} />

        {/* Formik Form */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize // Reinitialize form when initialValues change
        >
          {({ values, setFieldValue }) => (
            <Form className="mt-6">
              {/* Title Field */}
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">
                  Título
                </label>
                <Field
                  type="text"
                  name="title"
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="Ingrese el título"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Cards FieldArray */}
              <FieldArray name="cards">
                {({ push, remove }) => (
                  <>
                    {values.cards.map((card, index) => (
                      <div key={index} className="mb-4 border p-4 rounded">
                        <h4 className="text-lg font-bold mb-2">
                          Card {index + 1}
                        </h4>
                        {/* Card Image Upload */}
                        <div className="mb-2">
                          <label className="block text-gray-700 font-bold mb-2">
                            Subir imagen (Requerido)
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                handleImageUpload(file, setFieldValue, index);
                              }
                            }}
                            className="w-full p-2 border border-gray-300 rounded"
                          />
                          {card.cardImage && (
                            <div className="mt-2">
                              <img
                                src={card.cardImage}
                                alt="Preview"
                                className="w-32 h-32 object-cover rounded"
                              />
                            </div>
                          )}
                          <ErrorMessage
                            name={`cards.${index}.cardImage`}
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        {/* Main Text */}
                        <div className="mb-2">
                          <label className="block text-gray-700 font-bold mb-2">
                            Texto principal
                          </label>
                          <Field
                            type="text"
                            name={`cards.${index}.mainText`}
                            className="w-full p-2 border border-gray-300 rounded"
                            placeholder="Ingrese el texto principal"
                          />
                        </div>
                        {/* Secondary Text */}
                        <div className="mb-2">
                          <label className="block text-gray-700 font-bold mb-2">
                            Texto secundario
                          </label>
                          <Field
                            type="text"
                            name={`cards.${index}.secondaryText`}
                            className="w-full p-2 border border-gray-300 rounded"
                            placeholder="Ingrese el texto secundario"
                          />
                        </div>
                        {/* Text Regular */}
                        <div className="mb-2">
                          <label className="block text-gray-700 font-bold mb-2">
                            Texto regular
                          </label>
                          <Field
                            type="text"
                            name={`cards.${index}.textRegular`}
                            className="w-full p-2 border border-gray-300 rounded"
                            placeholder="Ingrese el texto regular"
                          />
                        </div>
                        {/* File Download */}
                        <div className="mb-2">
                          <label className="block text-gray-700 font-bold mb-2">
                            Subir archivo (PDF o Excel)
                          </label>
                          <input
                            type="file"
                            accept=".pdf,.xls,.xlsx"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                handleFileUpload(file, setFieldValue, index);
                              }
                            }}
                            className="w-full p-2 border border-gray-300 rounded"
                          />
                          {card.fileDownload && (
                            <div className="mt-2">
                              <a
                                href={card.fileDownload}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 underline"
                              >
                                Ver archivo subido
                              </a>
                            </div>
                          )}
                        </div>
                        {/* Redirect Link */}
                        <div className="mb-2">
                          <label className="block text-gray-700 font-bold mb-2">
                            Link de redirección (Opcional)
                          </label>
                          <Field
                            type="text"
                            name={`cards.${index}.redirectLink`}
                            className="w-full p-2 border border-gray-300 rounded"
                            placeholder="Ingrese la URL de redirección"
                          />
                          <ErrorMessage
                            name={`cards.${index}.redirectLink`}
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        {/* Remove Card Button */}
                        {values.cards.length > 1 && (
                          <button
                            type="button"
                            onClick={() => remove(index)}
                            className="bg-red-500 text-white px-4 py-2 rounded"
                          >
                            Eliminar Card
                          </button>
                        )}
                      </div>
                    ))}
                    {/* Add Card Button */}
                    <button
                      type="button"
                      onClick={() =>
                        push({
                          cardImage: "",
                          mainText: "",
                          secondaryText: "",
                          textRegular: "",
                          fileDownload: "",
                        })
                      }
                      className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
                    >
                      Agregar Card
                    </button>
                  </>
                )}
              </FieldArray>

              {/* Submit Button */}
              <button
                type="submit"
                className="bg-green-500 text-white px-4 py-2 ml-2 rounded"
              >
                Guardar Cambios
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Banners;
