import React from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/dashboard/layout/Sidebar";
import { templates } from "../blog/utils/template";

const CreateTemplateForm = () => {
  const { id } = useParams();

  const selectedTemplate = templates.find(
    (template) => template.id === parseInt(id)
  );

  if (!selectedTemplate) {
    return (
      <div className="flex mt-32 w-full">
        <Sidebar />
        <h1 className="text-center">Template no encontrado</h1> 
      </div>
    );
  }

  return (
    <div className="flex mt-32 w-full">
      <Sidebar />
      <div className="flex w-full gap-y-10 mx-auto flex-col justify-center items-center">
        <h1>{selectedTemplate.name}</h1>
        <div className="w-full px-6">{selectedTemplate.render(null, true)}</div>
      </div>
    </div>
  );
};

export default CreateTemplateForm;
