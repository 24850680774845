const PayPalSpecifications = ({ data }) => {
    const formatTextWithSuperscript = (text) => {
        const formattedText = text.replace(/(?<!\d\/|\d\.)(\d+)(?!(\d|%|\/\d|\.\d))/g, (match) => `<sup>${match}</sup>`);

        return formattedText
            .split("\n")
            .map(paragraph => paragraph.trim() ? `<span style="color: #EC0000;">•</span> ${paragraph}` : "")
            .join("<br>");
    };

    return (
        <div className="w-full py-12 bg-[#EDEEF0]">
            <div className="max-w-7xl mx-auto px-4">
                <h1 className="text-5xl text-center mb-24 pt-10 xs:text-3xl xs:mb-12 xs:pt-0">
                    {data.title}
                </h1>
                <div className="grid grid-cols-3 md:grid-cols-1 gap-14 text-center">
                    {[data.titleOne, data.titleTwo, data.titleThree].map((title, index) => (
                        <div key={index} className="space-y-4 min-h-[200px] flex flex-col">
                            <h2 className="text-2xl font-semibold text-[#EC0000] pb-4 w-full">
                                {title}
                            </h2>
                            <p className="text-xl text-gray-700 leading-relaxed w-full xs:pr-0"
                                dangerouslySetInnerHTML={{ __html: formatTextWithSuperscript([data.textOne, data.textTwo, data.textThree][index]) }} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PayPalSpecifications;
