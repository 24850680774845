import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Importamos useNavigate
import "../../../styles/PaymentSolutionsCarousel.css";
import useSendEvents from "../../../hooks/useSendEvents";

const DynamicCarouselBanner = ({ paymentSolutionsElement }) => {
  const [currentCard, setCurrentCard] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const navigate = useNavigate(); // Hook de navegación

  const { handleEvent, handleEventWithCallBackAction } = useSendEvents();
  const paymentSolutions = paymentSolutionsElement?.cards || [];

  useEffect(() => {
    const loadImages = async () => {
      const imagePromises = paymentSolutions.map((card) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = card.cardImage;
          img.onload = resolve;
          img.onerror = reject;
        });
      });

      try {
        await Promise.all(imagePromises);
        setImagesLoaded(true);
      } catch (error) {
        console.error("Error al cargar las imágenes:", error);
      }
    };

    if (paymentSolutions.length > 0) {
      loadImages();
    }
  }, [paymentSolutions]);

  if (!paymentSolutions.length) {
    return <p>No hay datos disponibles para el carrusel.</p>;
  }

  const nextCard = () => {
    setCurrentCard((prevCard) => (prevCard + 1) % paymentSolutions.length);
  };

  const prevCard = () => {
    setCurrentCard(
      (prevCard) =>
        (prevCard - 1 + paymentSolutions.length) % paymentSolutions.length
    );
  };

  const validIsFullBanner = (index) => {
    const card = paymentSolutions[index];
    return (
      (!card.mainText || card.mainText.trim() === "") &&
      (!card.secondaryText || card.secondaryText.trim() === "") &&
      (!card.textRegular || card.textRegular.trim() === "")
    );
  };

  const handleCardClick = (index) => {
    const card = paymentSolutions[index];

    if (card.redirectLink) {
      navigate(card.redirectLink);
    } else if (card.fileDownload) {
      downloadPdf(index);
    }
  };

  const downloadPdf = (index) => {
    const fileUrl = paymentSolutions[index]?.fileDownload;
    if (fileUrl) {
      const a = document.createElement("a");
      a.href = fileUrl;
      a.download = fileUrl.split("/").pop() || "documento.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const updateCards = () => {
    return paymentSolutions.map((card, index) => {
      const offset =
        (index - currentCard + paymentSolutions.length) %
        paymentSolutions.length;

      let zIndex, transform, opacity;
      if (offset === 0) {
        zIndex = 3;
        transform = "translateY(-20px) scale(1.05)";
        opacity = 1;
      } else if (offset === 1) {
        zIndex = 2;
        transform = "translateY(-40px) scale(0.95)";
        opacity = 0.5;
      } else if (offset === 2) {
        zIndex = 1;
        transform = "translateY(-60px) scale(0.9)";
        opacity = 0.3;
      } else if (offset === 3) {
        zIndex = 0;
        transform = "translateY(-80px) scale(0.85)";
        opacity = 0.2;
      }

      const isClickable = card.redirectLink || card.fileDownload;

      return (
        <div
          key={index}
          className="absolute w-full h-[100%] xl:h-96 xs:h-[100%] top-0 left-0 bg-[#CEDEE7] transform-origin-center transition-transform duration-300 ease-linear rounded-none border-none"
          style={{
            zIndex,
            transform,
            opacity,
            cursor: isClickable ? "pointer" : "default",
          }}
          onClick={() => isClickable && handleCardClick(index)}
        >
          {validIsFullBanner(index) ? (
            <div className="w-full h-full">
              {imagesLoaded ? (
                <img
                  src={card?.cardImage}
                  alt="Payment Solutions"
                  className="w-full h-full object-fit"
                  loading="lazy"
                />
              ) : (
                <div className="w-full h-full flex justify-center items-center">
                  <div className="spinner"></div>
                </div>
              )}
            </div>
          ) : (
            <div className="w-full h-full sm:mr-10 grid grid-cols-2 xs:grid-cols-1">
              <div className="flex justify-center items-center mx-20 lg:mx-10 xs:hidden">
                {imagesLoaded ? (
                  <img
                    src={card?.cardImage}
                    alt={card?.mainText || "Card image"}
                    className="w-full h-96 xl:h-72 object-cover"
                    loading="lazy"
                  />
                ) : (
                  <div className="w-full h-96 flex justify-center items-center">
                    <div className="spinner"></div>
                  </div>
                )}
              </div>
              <div className="card-text w-full flex flex-col text-center pt-4 justify-center px-4">
                <h3 className="text-3xl lg:text-lg md:text-xs xs:text-xs text-black">
                  <span className="text-3xl lg:text-lg md:text-xs xs:text-xs">
                    {card?.mainText}
                  </span>
                  {card.secondaryText}
                </h3>
                <p className="text-start text-black pr-4 xs:text-xs">
                  {card.textRegular}
                </p>
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="my-20 pb-96 sm:pb-2">
      <div className="relative">
        <h2 className="text-black z-50 absolute top-0 left-0 w-full text-center text-5xl xs:text-3xl lg:text-4xl">
          {paymentSolutionsElement?.title}
        </h2>
        <div className="carousel-container fixed top-60 sm:top-20 left-0 right-0 bottom-0 flex justify-center items-center">
          <div className="card-stack relative mx-10">{updateCards()}</div>
          <button
            className="button left"
            onClick={() =>
              handleEventWithCallBackAction(
                () =>
                  handleEvent({
                    event: "interaction",
                    action_type: "click on page",
                    selection_name: "preview icon",
                    module_type: "carousel payment solutions",
                    page_category: "home",
                  }),
                () => prevCard()
              )
            }
          >
            <span>&#8249;</span>
          </button>
          <button
            className="button right"
            onClick={() =>
              handleEventWithCallBackAction(
                () =>
                  handleEvent({
                    event: "interaction",
                    action_type: "click on page",
                    selection_name: "next icon",
                    module_type: "carousel payment solutions",
                    page_category: "home",
                  }),
                () => nextCard()
              )
            }
          >
            <span>&#8250;</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DynamicCarouselBanner;
