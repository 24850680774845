import React from "react";

const BannerImgBgW = ({ data }) => {
    return (
        <div className="relative w-full h-full pt-14 xs:pt-20 2xl:pt-20">
            <img
                src={data.mainImage}
                alt="Background"
                className="w-full h-full object-contain"
            />

            <div className="absolute top-0 left-20 w-full h-full bg-black bg-opacity-25 flex flex-col justify-center xs:items-center xs:text-center xs:left-0 2xl:left-0">
                <h1 className="ml-20 text-white text-6xl md:text-4xl lg:text-5xl max-w-2xl leading-snug md:leading-normal xs:text-2xl xs:max-w-xs lg:ml-10 2xl:text-4xl 2xl:max-w-md xs:ml-0 xs:pt-20 2xl:pt-20">
                    {data.textBold}
                </h1>
                <p className="mt-5 ml-20 text-white text-2xl max-w-xl xs:hidden lg:ml-10 2xl:text-xl 2xl:max-w-md">{data.textRegular}</p>
            </div>
        </div>

    );
};

export default BannerImgBgW;
