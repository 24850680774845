import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useSendEvents from "../hooks/useSendEvents";
const WelcomeSection = ({
  data,
  showIcons = true,
  color = "text-[#EC0000]",
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { handleEvent, handleEventWithCallBackRedirect } = useSendEvents();

  const handleDispatch = (link) => {
    if (link && location.pathname == "/nuestros-productos/pagosenpersona") {
      handleEventWithCallBackRedirect(
        () =>
          handleEvent({
            event: "select_content",
            action_type: "click on cta",
            selection_name: "hire now",
            module_type: "hero",
            page_category: "product page",
          }),
        link
      );
    } else {
      navigate(link);
    }
  };

  if (
    location.pathname ===
    "/nuestros-productos/sector/distribuidores-autorizados"
  ) {
    showIcons = false;
  }

  return (
    <div className="w-full mx-auto max-w-[80%] lg:max-w-7xl xs:max-w-[80%] 2xl:max-w-6xl pt-24 pb-10">
      <div className="relative grid grid-cols-3 lg:grid-cols-2 xs:grid-cols-1">
        <div className="flex flex-col justify-center max-w-md xs:px-4 items-start  gap-y-1 lg:pl-12 xs:pl-0">
          {data.text && (
            <p className={`${color} text-md font-bold`}>
              {data.text.toUpperCase()}
            </p>
          )}
          {data.title && (
            <h1 className="text-6xl leading-tight xs:text-3xl 2xl:text-5xl">
              {data.title}
            </h1>
          )}
          {data.subheading && (
            <p className="text-lg pt-1 2xl:text-medium">{data.subheading}</p>
          )}
          {data.actionPrimary && (
            <a>
              <button
                onClick={() =>
                  handleDispatch(
                    `${data.link}?source=${encodeURIComponent(data.sourceContact)}`
                  )
                }
                className="bg-[#EC0000] text-white text-2xl py-3 px-14 rounded-full mt-3 xs:mb-4 xs:px-6 xs:py-1 xs:text-xl 2xl:text-xl"
              >
                {data.actionPrimary}
              </button>
            </a>
          )}
        </div>
        <div className="flex h-full max-w-7xl pl-20  xs:w-full xs:px-24 gap-3 items-start 2xl:px-4 xs:pl-0 xs:max-w-[80%]">
          {data.primaryImg && (
            <img
              className="w-full h-full object-contain"
              src={data.primaryImg}
              alt="Primary"
            />
          )}
          {data.secondaryImg && (
            <img
              className="w-full h-full object-contain"
              src={data.secondaryImg}
              alt="Secondary"
            />
          )}
        </div>
        {showIcons && (
          <div className="absolute right-14 -bottom-24 h-24 xs:hidden 2xl:hidden">
            <img
              src="/media/images/blog/getnet-logo.png"
              alt="icon"
              className="w-full h-full object-cover"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WelcomeSection;
