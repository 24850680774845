import React, { useState } from "react";
import "../styles/Faq.css";
import useSendEvents from "../hooks/useSendEvents";

const FAQ = ({ data }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleQuestion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const { handleEvent, handleEventWithCallBackAction } = useSendEvents();

  return (
    <section className="flex flex-col justify-center items-center xs:justify-start xs:items-start xs:p-4 xs:pt-10">
      <h1 className="text-5xl xs:hidden mt-5">{data.heading}</h1>
      <div className="max-w-7xl">
        <div className="grid grid-cols-2 lg:grid-cols-2 xs:grid-cols-1 gap-x-2 mt-16 xl:px-4 xs:mt-2 ">
          <div className="px-20 lg:px-2 xs:px-0">
            <p className="text-sm text-[#EC0000] font-bold xs:text-center">{data.subheading.toUpperCase()}</p>
            <h2 className="text-6xl xl:text-4xl max-w-sm xs:max-w-7xl xs:text-center xs:text-5xl">{data.title}</h2>
            <p className="text-gray-500 max-w-[80%] mt-6 xs:max-w-7xl xs:text-center">{data.textRegular}</p>
          </div>
          <div>
            {data.questions.map((question, index) => (
              <div key={index} className={`border-t-2 border-gray-200 cursor-pointer`} onClick={() =>
                handleEventWithCallBackAction(
                  () =>
                    handleEvent({
                      event: "interaction",
                      action_type: "click on dropdown option",
                      selection_name: question.q,
                      module_type: "faq",
                      page_category: "product page",
                      faq_click: 'faq click'
                    }),
                  () => toggleQuestion(index)
                )

              }>
                <div className={`relative flex flex-row justify-between  ${expandedIndex === index && 'bg-[#f7f9fb]'}`}>
                  <button
                    className={`accordion-header px-4  ${expandedIndex === index ? "active" : ""}`}
                  >
                    {question.q}
                  </button>

                  <button
                    className={`text-red-500 absolute right-4 top-4 text-2xl transition duration-500 ${expandedIndex === index ? "-rotate-90 px-1" : "rotate-90"
                      }`}
                  >
                    <span>&#8250;</span>
                  </button>
                </div>
                <div
                  className={`overflow-hidden transition-all duration-1000 ease-in-out ${expandedIndex === index ? "max-h-[500px]" : "max-h-0"
                    }`}
                >
                  {expandedIndex === index && (
                    <div className="flex flex-col mb-4 p-4 relative">
                      <p>{question.a}    {
                        question.i && <div className="w-6 absolute right-[55%] 2xl:static 2xl:inline-block bottom-10 2xl:right-50"><img src={question.i} alt="contacleess" className="w-full h-full object-contain" /></div>
                      }</p>

                      {question.actionPrimary && (
                        <button className="action-terciary text-end mr-10">
                          <a href={question.link}>{question.actionPrimary}</a>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
