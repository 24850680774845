import React from "react";

const AboutFooter = ({ data }) => {
    return (
        <div className="relative w-full top-[100px] xs:top-0 xs:h-full xs:mt-20 lg:top-0 lg:mt-10">
            <img 
                src={data.mainImage} 
                alt="Background" 
                className="w-full h-full object-contain"
            />
            
            <div className="absolute top-0 left-20 w-full h-full bg-black bg-opacity-25 flex flex-col justify-center xs:items-center xs:text-center xs:left-0">
                <h1 className="text-white text-5xl md:text-4xl lg:text-5xl max-w-xl leading-snug md:leading-normal xs:text-2xl xs:max-w-xs">
                    {data.title}
                </h1>
            </div>
        </div>
    );
};

export default AboutFooter;