import React, {useEffect} from 'react';
import BlogSearch from '../components/BlogSearch';
import BlogArticles from '../components/BlogArticles';
import BlogTestimonials from '../components/BlogTestimonials';
import data from '../data/BlogPage.json';

const BlogPage = () => {


    const productData = data.find((product) => product.id === "blog");

    useEffect(() => {
                if (productData) {
                    document.title = `Novedades sobre medios de pago y negocios | Getnet`;
                } else {
                    document.title = "Página no encontrada";
                }
            }, [productData]);


    return (
        <div className={`${productData.id}-page product-page`}>
            <BlogSearch data={productData.welcome[0]} />
            <BlogArticles data={productData.blogSection[0]} />
            <BlogTestimonials data={productData.testimonials[0]} />
        </div>
    );
};

export default BlogPage;