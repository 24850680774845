import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthStore } from "../../stores/auth/auth.store";

export const PrivateGuard = ({ children }) => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const token = useAuthStore((state) => state.token);
  const logout = useAuthStore((state) => state.logout);
  const location = useLocation();

  const isTokenExpired = (token) => {
    const arrayToken = token.split(".");
    const tokenPayload = JSON.parse(atob(arrayToken[1]));
    return Math.floor(new Date().getTime() / 1000) >= tokenPayload?.exp;
  };

  useEffect(() => {
    if (token) {
      if (isTokenExpired(token)) {
        logout();
      }
    }
  }, [token]);

  if (isAuthenticated && location.pathname.startsWith("/auth")) {
    return (
      <Navigate
        to="/dashboard/banners"
        replace
        state={{ path: location.pathname }}
      />
    );
  }

  if (!isAuthenticated && location.pathname.startsWith("/dashboard")) {
    return (
      <Navigate to="/auth/signin" replace state={{ path: location.pathname }} />
    );
  }

  return children;
};
