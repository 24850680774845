import Template01 from "../template/Template01";
import Template02 from "../template/Template02";
import Template03 from "../template/Template03";
import { template01InitialValues } from "./initialValues/Template01InitialValues";
import { template02InitialValues } from "./initialValues/Template02InitialValues";
import { template03InitialValues } from "./initialValues/Template03InitialValues";

export const templates = [
  {
    id: 1,
    name: "Plantilla 1",
    render: (productData, isEditing = true) => (
      <div>
        <Template01
          data={productData || template01InitialValues}
          isEditing={isEditing}
        />
      </div>
    ),
  },
  {
    id: 2,
    name: "Plantilla 2",
    render: (productData, isEditing = true) => (
      <div>
        <Template02
          data={productData || template02InitialValues}
          isEditing={isEditing}
        />
      </div>
    ),
  },
  {
    id: 3,
    name: "Plantilla 3",
    render: (productData, isEditing = true) => (
      <div>
        <Template03
          data={productData || template03InitialValues}
          isEditing={isEditing}
        />
      </div>
    ),
  },
];
