import React from "react";

const Distributors = ({ data }) => {
    return (
        <div className="mx-auto text-center py-10 xs:hidden w-[80%]">
            {data.title && <h1 className="text-5xl xs:text-3xl pb-10">{data.title}</h1>}
            {data.image && <img src={data.image} alt={data.title} className="mx-auto block"/>}
        </div>
    );
};

export default Distributors;