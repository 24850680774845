import React, { useState } from "react";
import data from "../data/Home.json";
import useSendEvents from "../hooks/useSendEvents";

const TestimonialsCarousel = () => {
  const testimonials = data[0]?.testimonials || [];
  const [currentIndex, setCurrentIndex] = useState(0);

  const { handleEvent, handleEventWithCallBackAction } = useSendEvents();

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <section className="bg-[#9BC3D3] relative py-20 xl:py-14 xs:pt-10 xs:pb-2 lg:px-2 px-20 mb-14">
      <div className="relative overflow-hidden">
        <div
          className="flex transition-transform duration-700 ease-in-out"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-full grid grid-cols-2 lg:grid-cols-1 xs:grid-cols-1 gap-x-20 lg:gap-y-10 items-center lg:flex lg:flex-col-reverse"
            >
              <div className="flex flex-col ml-64 xl:ml-24 lg:ml-2 xs:ml-2 lg:h-56  text-black xs:h-56">
                <p className="text-5xl xl:text-4xl lg:text-xl  lg:text-start xs:text-center xs:text-2xl mr-[20%] lg:mr-[20%] xs:mr-0">
                  "{testimonial.text}"
                </p>
                <p className="text-3xl xl:text-2xl xs:text-lg sm:text-center">
                  {testimonial?.secondaryText}
                </p>
              </div>

              <div className="relative w-1/2 h-96 xl:w-2/3 lg:h-60 lg:w-full xs:h-60 xs:w-full">
                <img
                  src={testimonial.image}
                  alt="Testimonial"
                  className="absolute top-0 left-0 w-full h-full object-contain"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="absolute -bottom-16 left-1/2 transform -translate-x-1/2 flex gap-x-6 justify-center mt-4 wxs:left-1/2 xs:transform xs:-translate-x-1/2">
        <button
          onClick={() =>
            handleEventWithCallBackAction(
              () =>
                handleEvent({
                  event: "interaction",
                  action_type: "click on page",
                  selection_name: "preview icon",
                  module_type: "testimonials",
                  page_category: "home",
                }),
              () => handlePrev()
            )
          }
          className="bg-white text-gray-500 rounded-full h-8 w-8 border border-gray-300 shadow-md hover:bg-gray-200"
        >
          &#8249;
        </button>
        <button
          onClick={() =>
            handleEventWithCallBackAction(
              () =>
                handleEvent({
                  event: "interaction",
                  action_type: "click on page",
                  selection_name: "next icon",
                  module_type: "testimonials",
                  page_category: "home",
                }),
              () => handleNext()
            )
          }
          className="bg-white text-gray-500 rounded-full h-8 w-8 border border-gray-300 shadow-md hover:bg-gray-200"
        >
          &#8250;
        </button>
      </div>
    </section>
  );
};

export default TestimonialsCarousel;
