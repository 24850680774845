import React from "react";
import { useLocation } from "react-router-dom";
import useSendEvents from "../hooks/useSendEvents";

const Welcome = ({ data }) => {
  const location = useLocation();

  const {
    handleEvent,
    handleEventWithCallBackAction,
    handleEventWithCallBackRedirect,
  } = useSendEvents();


  const handleSubmit = () => {
      if(location.pathname === '/nuestros-productos/gtapp' || location.pathname === '/gtapp'){
        handleEventWithCallBackAction(
          () =>
            handleEvent({
              event: "select_content",
              action_type: "click on cta",
              selection_name: "hire now",
              module_type: "hero",
              page_category: "product page",
            }),
            window.scrollTo({
              top: 6000,
              behavior: "smooth", 
            })
        );

      } else {
        handleEventWithCallBackRedirect(
          () =>
            handleEvent({
              event: "select_content",
              action_type: "click on cta",
              selection_name: "hire now",
              module_type: "hero",
              page_category: "product page",
            }),
          `${data.link}?source=${encodeURIComponent(data.sourceContact)}`
        );
      }
  }

  return (
    <section className="flex flex-col xs:flex-col-reverse xs:mt-12 items-center xs:pt-16 pt-16">
      <img src={data.welcomeImg} alt={data.name} className="w-full xs:mt-4" />
      <div className="flex flex-col text-center xs:text-start xs:px-4">
        <h2 className="text-xs uppercase font-bold text-gray-700 xs:text-start xs:text-black xs:text-lg xs:font-light ">
          {data.welcomeSubheader}
        </h2>
        <h1 className="text-[80px] text-[#ec0000] sm:text-4xl xs:text-start xs:text-black">
          {data.welcomeTitleOne}{" "}
          <span className="text-black">{data.welcomeTitleTwo}</span>
        </h1>
        <p className="text-xl text-gray-700 sm:text-cente xs:text-start xs:text-gray-500">
          {data.welcomeText}
        </p>

          <button onClick={handleSubmit} className="sm:ml-0 bg-[#ec0000] px-8 py-2 rounded-full transition-colors duration-200 text-[#ffffff] font-semibold mx-auto cursor-pointer hover:bg-white hover:text-[#ec0000] hover:border-[#ec0000] border-2">
            {data.actionPrimary}
          </button>
      </div>
    </section>
  );
};

export default Welcome;
