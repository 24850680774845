import { useNavigate } from "react-router-dom";

const useSendEvents = () => {
  const navigate = useNavigate();

  const handleEvent = ({
    event = "",
    action_type = "",
    selection_name = "",
    module_type = "",
    page_category = "",
    link_url = window.location.href,
    value,
    faq_click,
    form_id
  }) => {
    window.dataLayer = window.dataLayer || [];

    const link_domain = new URL(link_url).hostname;

    const data = {
      event,
      action_type,
      selection_name,
      module_type,
      page_category,
      page_path: window.location.pathname,
      link_domain,
      link_url,
    };

    if (value !== undefined) {
      data.value = value;
    }

    
    if (faq_click !== undefined) {
      data.faq_click = faq_click;
    }

        
    if (form_id !== undefined) {
      data.form_id = form_id;
    }


    window.dataLayer.push(data);
  };

  const handleEventWithCallBackRedirect = (func, redirect) => {
    if (typeof func === "function") {
      func();
    }

    if (redirect) navigate(redirect);
  };

  const handleEventWithCallBackAction = (func, action) => {
    if (typeof func === "function") {
      func();
    }

    if (typeof action === "function" && action) {
      action();
    }
  };

  return {
    handleEvent,
    handleEventWithCallBackRedirect,
    handleEventWithCallBackAction,
  };
};

export default useSendEvents;
