import React from "react";

const WelcomePayPal = ({ data, color = 'text-[#EC0000]' }) => {
    const formatTextWithSuperscript = (text) => {
        return text.replace(/(\d+)(?!(\d|%))/g, (match) => `<sup>${match}</sup>`);
    };


    return (
        <div className="w-full mx-auto max-w-[80%] lg:max-w-7xl xs:max-w-[80%] 2xl:max-w-5xl mt-24">
            <div className="relative grid grid-cols-2 lg:grid-cols-2 xs:grid-cols-1">
                <div className="flex flex-col justify-center max-w-md xs:px-4 items-start pl-12 gap-y-1 lg:pl-12 xs:pl-0">
                    {data.text && (
                        <p className={`${color} text-md font-bold`}>
                            {data.text.toUpperCase()}
                        </p>
                    )}
                    {data.title && (
                        <h1 className="text-6xl leading-tight xs:text-3xl 2xl:text-5xl">{data.title}</h1>
                    )}
                    {data.subheading && (
                        <p 
                            className="text-lg pt-1 2xl:text-medium"
                            dangerouslySetInnerHTML={{ __html: formatTextWithSuperscript(data.subheading) }}
                        />
                    )}
                    {data.actionPrimary && (
                        <a href={`${data.link}?source=${encodeURIComponent(data.sourceContact)}`}>
                            <button className="bg-[#EC0000] text-white text-2xl py-3 px-14 rounded-full mt-3 xs:mb-4 xs:px-6 xs:py-1 xs:text-xl 2xl:text-xl">
                                {data.actionPrimary}
                            </button>
                        </a>
                    )}
                </div>
                <div className="flex h-full max-w-xl pl-20  xs:w-full xs:px-24 gap-3 items-start 2xl:px-4 xs:pl-0 xs:max-w-[80%]">
                    {data.primaryImg && (
                        <img
                            className="w-full h-[90%] object-contain 2xl:h-[80%]"
                            src={data.primaryImg}
                            alt="Primary"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default WelcomePayPal;
