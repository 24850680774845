import React, { useEffect, useState } from "react";
import "../styles/Sectors.css";
import { useLocation, useNavigate } from "react-router-dom";
import useSendEvents from "../hooks/useSendEvents";

const Sectors = ({ data, bg }) => {
  const [currentSlice, setCurrentSlice] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const updateItemsPerPage = () => {
      setItemsPerPage(window.innerWidth < 640 ? 1 : 3);
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);

  const maxSlice = Math.ceil(data.sector.length / itemsPerPage);

  const handlePrev = () => {
    if (currentSlice > 0) {
      setCurrentSlice((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentSlice < maxSlice - 1) {
      setCurrentSlice((prev) => prev + 1);
    }
  };

  const {
    handleEvent,
    handleEventWithCallBackAction,
    handleEventWithCallBackRedirect,
  } = useSendEvents();

  const products = ["/nuestros-productos/getsmart", "/nuestros-productos/gtapp", "/nuestros-productos/getadvance", "/nuestros-productos/getmini"]

  const dispatchAction = (href, event) => {
    if (location.pathname == "/nuestros-productos/sobregetnet") {
      handleEventWithCallBackRedirect(
        () =>
          handleEvent({
            event: "select_content",
            action_type: "click on cta",
            selection_name: "know more",
            module_type: "specialized solutions",
            page_category: "about getnet",
            value: event
          }),
        href
      );
    } else if (products.includes(href)) {
      handleEventWithCallBackRedirect(
        () =>
          handleEvent({
            event: "select_content",
            action_type: "click on cta",
            selection_name: "know more",
            module_type: "sectors section",
            page_category: "product page",
            value: event
          }),
        href
      );
    } else {
      navigate(href)
    }
  };

  return (
    <section className={`${bg ? "bg-white" : "bg-[#f5f9fb]"} py-20 xs:py-1`}>
      <div className="text-center mx-auto max-w-7xl xs:p-4">
        <h1 className="text-center text-6xl xs:text-3xl mb-3 lg:ml-5 xs:ml-0">
          {data.title}
        </h1>
        <p className="text-[#767676] text-lg xs:text-medium xs:px-2 2xl:text-center mx-auto xs:mt-4">
          {data.text}
        </p>
        {data.textTwo && (
          <p className="text-[#767676] text-lg xs:text-medium">
            {data.textTwo}
          </p>
        )}
        <div className="grid grid-cols-3 max-w-6xl ml-10 xs:grid-cols-1 gap-x-20 lg:gap-x-2 lg:px-2 mt-20 relative xs:right-6 lg:right-6">
          {data.sector
            .slice(
              currentSlice * itemsPerPage,
              currentSlice * itemsPerPage + itemsPerPage
            )
            .map((sector, index) => (
              <div
                key={index}
                className={`${bg ? "bg-[#faf9f9]" : "bg-white"}`}
              >
                <div className="h-80">
                  <img
                    src={sector.img}
                    alt={sector.titleCard}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="text-start p-4">
                  <h3 className="text-lg font-bold mb-4">{sector.titleCard}</h3>
                  <p className="h-20 lg:text-sm">{sector.textCard}</p>
                  <button
                    className="action-terciary pt-3 text-sm"
                    onClick={() => dispatchAction(sector.link, sector.event)}
                  >
                    <a className="text-[#ec0000] no-underline font-semibold">
                      {sector.actionTerciary}
                    </a>
                  </button>
                </div>
              </div>
            ))}
          {data.sector.length > itemsPerPage && (
            <div className="absolute bottom-[-80px] right-0 left-0 flex justify-center gap-x-4 xs:gap-x-2 xs:bottom-[-40px]">
              <button
                onClick={() =>
                  handleEventWithCallBackAction(
                    () =>
                      handleEvent({
                        event: "interaction",
                        action_type: "click on page",
                        selection_name: "preview icon",
                        module_type: "specialized solutions",
                        page_category: "about getnet",
                      }),
                    () => handlePrev()
                  )
                }
                disabled={currentSlice === 0}
                className="w-12 h-12 border border-[#EC0000] rounded-full flex items-center justify-center shadow-md disabled:opacity-50"
              >
                <span className="text-4xl leading-none flex items-center justify-center text-[#EC0000]">
                  &#8249;
                </span>
              </button>
              <button
                onClick={() =>
                  handleEventWithCallBackAction(
                    () =>
                      handleEvent({
                        event: "interaction",
                        action_type: "click on page",
                        selection_name: "next icon",
                        module_type: "specialized solutions",
                        page_category: "about getnet",
                      }),
                    () => handleNext()
                  )
                }
                disabled={currentSlice >= maxSlice - 1}
                className="w-12 h-12 border border-[#EC0000] rounded-full flex items-center justify-center shadow-md disabled:opacity-50"
              >
                <span className="text-4xl leading-none flex items-center justify-center text-[#EC0000]">
                  &#8250;
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Sectors;
