import React from "react";
import '../../../styles/BlogWelcome.css'

const BlogWelcomeDynamic = ({ data }) => {
  return (
    <div className="blog-welcome">
      <img src={data.mainImage} alt={data.title} />
      <h1>{data.title}</h1>
    </div>
  );
};
export default BlogWelcomeDynamic;
