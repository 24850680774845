export const recentArticles =   [
    {
        "date":"Getnet - 29 Noviembre 2024",
        "title":"Fidelización de Clientes en Temporada de Fiestas: Cómo Crear Relaciones Duraderas",
        "image":"/media/images/blog/art-02/blog-article-01.jpg",
        "link":"/blog/fidelizacion-clientes-temporada"
    },
    {
        "date":"Getnet - 5 Agosto 2024",
        "title":"Seguridad en transacciones en línea",
        "image":"/media/images/blog/art-03/blog-article-01.png",
        "link":"/blog/seguridad-en-transacciones-en-linea"
    },
    {
        "date":"Getnet - 10 Septiembre 2024",
        "title":"Comprende la diferencia entre gastos fijos y variables para mejorar la gestión de tu negocio",
        "image":"/media/images/blog/art-04/blog-article-01.png",
        "link":"/blog/gastos-fijos-variables"
    },
]