import React from "react";

const SolutionsCards = ({ data }) => {
    return (
        <section className="mx-auto items-center max-w-7xl text-center py-10 2xl:p-8">
            <h1 className="text-5xl mb-4 xs:text-3xl 2xl:text-4xl">{data.title}</h1>
            <p className="text-xl text-[#767676] mb-10 xs:text-xl 2xl:text-xl">{data.text}</p>
            <div className="grid grid-cols-2 mx-auto gap-x-6 xs:grid-cols-1 xs:gap-y-4">
                {data.sol.map((item, index) => (
                    <a href={item.link}>
                        <img src={item.image} alt="logo" className="cursor-pointer" />
                    </a>

                ))}

            </div>
        </section>
    );
};

export default SolutionsCards;