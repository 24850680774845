import React from 'react';
import '../styles/BannerMoreInformation.css';
import useSendEvents from '../hooks/useSendEvents';

const BannerSayYes = ({ data }) => {

  const { handleEvent, handleEventWithCallBackRedirect } = useSendEvents();


  return (
    <div className="banner-more-information">
      <h2>{data.title}</h2>
      <p>{data.textRegular}</p>
        <button 
                onClick={() =>
                  handleEventWithCallBackRedirect(
                    () =>
                      handleEvent({
                        event: "select_content",
                        action_type: "click on cta",
                        selection_name: "talk to an expert",
                        module_type: "banner more information",
                        page_category: "sectors page",
                      }),
                   `${data.link}?source=${encodeURIComponent(data.sourceContact)}`
                  )
                } className='action-primary'>{data.actionPrimary}</button>
    </div>
  );
};

export default BannerSayYes;
