import React from "react";

const PayPalServices = ({ data }) => {
    const formatTextWithSuperscript = (text) => {
        return text.replace(/(\d+)(?!(\d|%))/g, (match) => `<sup>${match}</sup>`);
    };
    return (
        <div className="bg-[#EDEEF0] flex py-10 px-5">
            <div className="grid grid-cols-2 overflow-hidden xs:grid-cols-1">
                <div className="w-[80%] p-5 flex justify-center items-center xs:w-full">
                    <img src={data.image} alt="PayPal" className="w-full h-auto object-cover" />
                </div>

                <div className="w-full p-8 flex flex-col">
                    <h2 className="text-6xl max-w-3xl pt-36 pb-20 2xl:text-4xl xs:pt-0 xs:text-3xl xs:pb-4 xs:text-center">{data.title}</h2>
                    <p className="text-2xl text-[#333333] mb-6 max-w-xl xs:text-center 2xl:text-xl">{data.textOne}</p>

                    <div className="flex items-start pt-40 2xl:pt-10 xs:items-center xs:flex-col xs:pt-0">
                        <span className="text-7xl font-bold text-[#EC0000] p-2 2xl:text-4xl">{data.percentage}</span>
                        <p 
                            className="text-[#333333] text-2xl max-w-lg pl-16 2xl:pl-6 xs:text-center xs:pl-0 2xl:text-xl 2xl:max-w-md"
                            dangerouslySetInnerHTML={{ __html: formatTextWithSuperscript(data.textTwo) }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayPalServices;
