import React from "react";

const BlogBannerRightDynamic = ({ data }) => {
  return (
    <div className="flex flex-col md:flex-row mx-4 md:mx-12 my-4 md:my-12 bg-gray-100 p-4 md:p-12 text-center text-base">
      <div className="w-full md:w-1/2 space-y-4">
        <h2 className="text-2xl font-bold">{data?.title}</h2>
        <p>{data?.pOne}</p>
        <p>{data?.pTwo}</p>
        <p>{data?.pThree}</p>
      </div>

      <div className=" w-full md:w-1/2 flex justify-center items-center mt-6 md:mt-0">
        <img
          src={data?.image}
          alt={data?.title}
          className="max-w-full h-auto rounded-lg shadow-md"
        />
      </div>
    </div>
  );
};

export default BlogBannerRightDynamic;
