import React from 'react';
import { useParams } from 'react-router-dom';
import data from '../data/BlogArticle08.json';

const BlogArticle08 = () => {
    const { id } = useParams();
    const productData = data.find((product) => product.id === id);


    return (
        <div className={`${productData.id}-page product-page max-w-7xl mx-60 px-2 py-10 xs:mx-auto lg:mx-auto`}>
            <section className="mb-12">
                <h1 className="text-5xl text-[#EC0000] mb-8 py-4 w-[70%] xs:text-3xl xs:w-auto lg:w-auto">
                    {productData.general[0].title}
                </h1>
                <hr className="border-t-2 border-gray-400 w-full mb-8" />

                <img
                    src={productData.general[0].mainImage}
                    alt="blog-article-01"
                    className="w-full h-full rounded-lg"
                />
            </section>

            <section className="mb-16">
                <div className="grid gap-8 mb-8 w-[70%] xs:grid-cols-1 xs:w-auto lg:w-auto">
                    <div>
                        <h2 className="text-3xl mb-8 xs:text-2xl">
                            {productData.sectionOne[0].title}
                        </h2>
                        <p className="text-lg">
                            {productData.sectionOne[0].pOne}
                        </p>
                        <p className="text-lg">
                            {productData.sectionOne[0].pTwo}
                        </p>
                        <p className="text-lg">
                            {productData.sectionOne[0].pThree}
                        </p>
                        <h3 className="text-[#EC0000] text-2l font-semibold mt-6">
                            {productData.sectionOne[0].titleTwo}
                        </h3>
                        <p className="text-lg">
                            {productData.sectionOne[0].pFour}
                        </p>
                    </div>
                    <img
                        src={productData.sectionOne[0].image}
                        alt="blog-article-02"
                        className="w-full h-full rounded-lg"
                    />
                </div>
            </section>
            <section className="text-start w-[70%] xs:w-auto lg:w-auto">
                <div className="gap-2">
                    <h2 className="text-3xl xs:text-2xl">
                        {productData.sectionTwo[0].title
                            .split(/(Acércate a nuestros expertos|Get Link and Pay)/)
                            .map((part, index) => {
                                if (part === "Acércate a nuestros expertos") {
                                    return (
                                        <span key={index} className="text-[#EC0000] underline">
                                            {part}
                                        </span>
                                    );
                                } else if (part === "Get Link and Pay") {
                                    return (
                                        <span key={index} className="font-bold">
                                            {part}
                                        </span>
                                    );
                                }
                                return part;
                            })}

                    </h2>
                </div>
            </section>
            <section className="mb-12">
                <div className="grid md:grid-cols-2 gap-8 mb-8 xs:grid-cols-1">
                    <img
                        src={productData.sectionTwo[0].image}
                        alt="blog-article-03"
                        className="w-full h-full order-last"
                    />
                </div>
            </section>
        </div>
    );
};

export default BlogArticle08;