import React from "react";

const BannerGtapp = ({ data }) => {
  return (
    <div className="flex flex-col gap-y-10 text-center p-3 max-w-6xl mx-auto">
      <div className=" text-black">
        <h1 className="text-5xl mb-4 xs:text-4xl">{data.title}</h1>
        <p className="text-md text-gray-500 mx-64 xs:mx-2 xs:text-start">{data.textRegular}</p>
      </div>

      {data.video && (
        <div className="text-center w-full">
          <video
            src={data.video}
            controls
            autoPlay
            loop
            muted
            className="w-full max-w-full h-auto"
          >
            Your browser does not support the video tag.
          </video>
        </div>
      )}

      {data.image && (
        <div className="w-full">
          <img src={data.image} alt={data.title} className="w-full" />
        </div>
      )}
    </div>
  );
};

export default BannerGtapp;
