import React, { useEffect } from 'react';
import data from '../data/PayPal.json';
import WelcomePayPal from '../components/WelcomePayPal';
import AdvantagesDigitalAndPerson from '../components/AdvantagesDigitalAndPerson';
import PayPalServices from '../components/PayPalServices';
import PayPalReasons from '../components/PayPalReasons';
import PayPalSpecifications from '../components/PayPalSpecifications';
import PayPalFeatures from '../components/PayPalFeatures';
import PayPalForm from '../components/PayPalForm';
import PayPalFAQ from '../components/PayPalFAQ';
import '../styles/PaymentPersons.css';

const PayPal = () => {
    const paymentData = data.find((item) => item.id === "paypal");

    useEffect(() => {
        if (paymentData) {
            document.title = `PayPal | Getnet.`;
        } else {
            document.title = "Página no encontrada";
        }
    }, [paymentData]);

    if (!paymentData) {
        return <p>Datos no encontrados</p>;
    }

    return (
        <div>
            <WelcomePayPal data={paymentData.welcome[0]} />
            <PayPalServices data={paymentData.paypalservices[0]}/>
            <AdvantagesDigitalAndPerson data={paymentData.advantages[0]}/>
            <PayPalReasons data={paymentData.paypalreasons[0]} />
            <PayPalSpecifications data={paymentData.paypalspecifications[0]} />
            <PayPalFeatures data={paymentData.paypalfeatures[0]} />
            <PayPalForm data={paymentData.paypalform[0]} />
            <PayPalFAQ data={paymentData.paypalfaq[0]} />
        </div>
    );
};

export default PayPal;
