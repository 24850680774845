import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Welcome from "../components/WelcomeSectors";
import CarosuelProducts from '../components/CarosuelProducts';
import Banner from '../components/Banner';
import Functionalities from '../components/Functionalities';
import Solutions from '../components/Solutions';
import BannerMoreInformation from '../components/BannerMoreInformation';
import Testimonials from '../components/TestimonialsBanner';
import CarouselSolutions from '../components/CarouselSolutions';
import data from "../data/Hoteles.json";

const Hoteles = () => {

    const { id } = useParams();
    const sectorData = data.find((sector) => sector.id === id);

    useEffect(() => {
            if (sectorData) {
                document.title = `Soluciones de pago para tu hotel | Getnet`;
            } else {
                document.title = "Página no encontrada";
            }
        }, [sectorData]);

    return (
        <div className={`${sectorData.id} sectors-page`}>
            <Welcome data={sectorData.welcome[0]} />
            <CarosuelProducts data={sectorData.carouselProducts} />
            <Banner data={sectorData.bannerSection[0]} />
            <Functionalities data={sectorData.functionalities}/>
            <Solutions data={sectorData.solutions[0]} />
            <BannerMoreInformation data={sectorData.moreInformation[0]} />
            <Testimonials data={sectorData.testimonials}/>
            <CarouselSolutions data={sectorData.carosuelSolutions[0]} />
        </div>
    ); 
}; 

export default Hoteles;