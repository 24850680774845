import React, { useEffect, useState } from "react";
import "../styles/IframeWidgetBotlers.css";

const IframeWidgetBotlers = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widget.botlers.io/sdk/main.js";
    script.id = "botlers-messaging-sdk-v2";
    script.setAttribute("bmid", "2cfde5ff604f4e2088968008740f6212");

    script.onload = () => {
      setTimeout(() => {
        setIsScriptLoaded(true);
      }, 1000);
    };

    document.body.appendChild(script);

    return () => {
      const existingScript = document.getElementById(
        "botlers-messaging-sdk-v2"
      );
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  if (!isScriptLoaded) {
    return <div></div>;
  }

  /*
  return (
    <div className="custom-chat-button">
      {/* <span>¡Platica con uno de nuestros <strong>expertos</strong>!</span>}
    </div>
  );
  */
};

export default IframeWidgetBotlers;
