import React from 'react';
import '../styles/BlogSearch.css'

const BlogSearch = ({ data }) => {

  return (
    <div className="blog-search xs:pt-10 2xl:pt-6">
      <div className='blog-search-text'>
        <h2>{data.title}</h2>
        <div className="input-container">
          {data.searchLogo && <img src={data.searchLogo} alt="Search" className="search-icon" />}
          <input placeholder={data.input} />
        </div>
      </div>
      <div className="images">
        {data.primaryImg && <img src={data.primaryImg} alt="Primary" />}
        {data.secondaryImg && <img src={data.secondaryImg} alt="Secondary" />}
      </div>
      <div className="getnet-logo">
        {data.getnetLogo && <img src={data.getnetLogo} alt="Getnet" />}
      </div>
    </div>
  );
};

export default BlogSearch;