import React, { useEffect, useState } from "react";


const PluginBenefits = ({ data, bg }) => {

    const [currentSlice, setCurrentSlice] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(3);

    useEffect(() => {
        const updateItemsPerPage = () => {
            setItemsPerPage(window.innerWidth < 640 ? 1 : 3);
        };

        updateItemsPerPage();
        window.addEventListener("resize", updateItemsPerPage);
        return () => window.removeEventListener("resize", updateItemsPerPage);
    }, []);

    const maxSlice = Math.ceil(data[1].length / itemsPerPage);

    const handlePrev = () => {
        if (currentSlice > 0) {
            setCurrentSlice((prev) => prev - 1);
        }
    };

    const handleNext = () => {
        if (currentSlice < maxSlice - 1) {
            setCurrentSlice((prev) => prev + 1);
        }
    };
    return (
        <section className={`${bg ? 'bg-white' : 'bg-[#f5f9fb]'} py-10 xs:py-1`}>
            <div className="text-center mx-auto max-w-7xl xs:p-4 2xl:max-w-6xl">
                <h1 className="text-6xl xs:text-center xs:text-3xl mb-10 pt-4">{data[0].title}</h1>
                <p className="text-xl text-[#444444] xs:text-center xs:text-xl pb-6">{data[0].textRegular}</p>
                <div className="grid grid-cols-3 xs:grid-cols-1 gap-x-5 lg:gap-x-2 lg:px-2 mt-10 relative">
                    {data[1]
                        .slice(currentSlice * itemsPerPage, currentSlice * itemsPerPage + itemsPerPage)
                        .map((plugins, index) => (
                            <div key={index} className={`${bg ? 'bg-[#faf9f9]' : 'bg-white'}`}>
                                <div className="h-80">
                                    <img
                                        src={plugins.image}
                                        className="w-full h-full object-cover"
                                        alt=""
                                    />
                                </div>
                                <div className="text-start p-4">
                                    <h3 className="text-xl font-bold text-[#262626]">{plugins.title}</h3>
                                    <p className="h-30]">{plugins.text}</p>
                                </div>
                            </div>
                        ))}
                    {data[1].length > itemsPerPage && (
                        <div className="absolute right-4 -bottom-10 flex gap-x-5">
                            <button
                                onClick={handlePrev}
                                disabled={currentSlice === 0}
                                className="w-12 h-12 bg-white border border-black rounded-full flex items-center justify-center shadow-md disabled:opacity-50"
                            >
                                <span className="text-4xl leading-none flex items-center justify-center">&#8249;</span>
                            </button>
                            <button
                                onClick={handleNext}
                                disabled={currentSlice >= maxSlice - 1}
                                className="w-12 h-12 bg-white border border-black rounded-full flex items-center justify-center shadow-md disabled:opacity-50"
                            >
                                <span className="text-4xl leading-none flex items-center justify-center">&#8250;</span>
                            </button>
                        </div>
                    )}

                </div>
            </div>
        </section>
    );
};

export default PluginBenefits;;
