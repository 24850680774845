import React from "react";

const ImageUploader = ({
  id,
  name,
  label,
  setFieldValue,
  fieldName,
  urlFieldName,
  imageUrl,
  placeholderText = "Haga clic para cargar o arrastre y suelte",
  fileTypes = "SVG, PNG, JPG o GIF",
}) => {
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Genera la URL temporal
      setFieldValue(fieldName, file); // Almacena el archivo
      setFieldValue(urlFieldName, imageUrl); // Almacena la URL temporal
    }
  };

  return (
    <div className="space-y-4">
      <div>

        <div className="flex items-center justify-center w-full my-2">
          <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition-all">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500">
                <span className="font-semibold">{placeholderText}</span>
              </p>
              <p className="text-xs text-gray-500">{fileTypes}</p>
            </div>
            <input
              id={id}
              name={name}
              type="file"
              className="hidden"
              onChange={handleImageChange}
            />
          </label>
        </div>
        {imageUrl && (
          <img
            src={imageUrl}
            alt="Vista previa"
            className="mt-2 w-32 h-32 object-cover rounded-md"
          />
        )}
      </div>
    </div>
  );
};

export default ImageUploader;