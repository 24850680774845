import React from "react";

const DetailsProduct = ({ data }) => {
  const { titleOne, titleTwo, image, textRegular, details } = data[0];

  return (
    <div className="bg-[#F0F0F0]">
      <div className="mx-auto max-w-6xl">
        <div className="flex flex-col  text-center pt-4">
          <h3 className="text-4xl xs:text-2xl">{titleOne}</h3>
          <h4 className="text-[#ec0000] text-4xl xs:text-lg">{titleTwo}</h4>
        </div>
        <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-2 text-3xl mt-10">
          <div className="flex flex-col justify-center ">
            <ul>
              {details.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <p className="text-lg text-gray-500 pl-[32px]">{textRegular}</p>
          </div>
          <div className="mx-20 mb-20">
            <img src={image} alt={titleTwo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsProduct;
