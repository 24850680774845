import React, { useEffect } from "react";
import Menu from "../components/Menu";
import { Route, Routes, useLocation } from "react-router-dom";
import BannerImgBg from "../components/BannerImgBg";
import PaymentSolutionsCarousel from "../components/PaymentSolutionsCarousel";
import ProductsSection from "../components/ProductSection";
import SectorsAccordion from "../components/SectorsAccordion";
import Blog from "../components/Blog";
import TestimonialsCarousel from "../components/TestimonialsCarousel";
import Personpayments from "../pages/PersonPayments";
import OnlinePayments from "../pages/OnlinePayments";
import ProductPage from "../components/ProductPage";
import SectorPage from "../components/SectorPage";
import BlogPage from "../pages/BlogPage";
import BlogPageArticle from "../components/BlogPageArticle";
import GTapp from "../pages/GTapp";
import { PrivateGuard } from "./guard/PrivateGuard";
import SignIn from "../pages/auth/SignIn";
import Banners from "../pages/dashboard/Banners";
import BlogDash from "../pages/dashboard/Blog";
import Footer from '../components/Footer';
import IframeWidgetBotlers from "../components/IframeWidgetBotlers"
import CreateTemplateForm from "../pages/dashboard/CreateTemplateForm";

const MainRouter = () => {

  const location = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [location.pathname])

  return (
    <PrivateGuard>
      <Menu />
      <Routes>
        <Route
          path="/"
          element={
            <div className="flex-1">
              <BannerImgBg />
              <PaymentSolutionsCarousel />
              <ProductsSection />
              <SectorsAccordion />
              <Blog />
              <TestimonialsCarousel />
            </div>
          }
        />

        <Route
          path="/nuestros-productos/pagosenpersona"
          element={<Personpayments />}
        />
        <Route
          path="/nuestros-productos/pagosdigitales"
          element={<OnlinePayments />}
        />
        <Route path="/nuestros-productos/:id" element={<ProductPage />} />
        <Route path="/nuestros-productos/sector/:id" element={<SectorPage />} />
        <Route path="/blog/index" element={<BlogPage />} />
        <Route path="/blog/:id" element={<BlogPageArticle />} />
        <Route path="/gtapp" element={<GTapp />} />
        <Route path="/auth/signin" element={<SignIn />} />

        {/* Dashboard */}
        <Route path="/dashboard/banners" element={<Banners />} />
        <Route path="/dashboard/blog" element={<BlogDash />} />
        <Route path="/dashboard/templateForm/:id" element={<CreateTemplateForm />} />
      </Routes>
      <IframeWidgetBotlers />
      <Footer />
    </PrivateGuard>
  );
};

export default MainRouter;
