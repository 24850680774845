import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import emailjs from "emailjs-com";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/ModalQuejasYSugerencias.css";
import useSendEvents from "../hooks/useSendEvents";

const ModalQuejasYSugerencias = ({ isOpen, onClose }) => {
  const {
    handleEvent,
    handleEventWithCallBackAction,
    handleEventWithCallBackRedirect,
  } = useSendEvents();

  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    rfc: "",
    afiliation: "",
    message: "",
    to_email: "atencionejecutivos@getnet.mx,soporte_magento@microformas.com.mx",
    source: "QuejasYSugerencias",
  });

  const [errors, setErrors] = useState({});

  if (!isOpen) return null;

  const validateField = (name, value) => {
    let error = "";

    if (name === "fullName" && (!value || value.length < 3)) {
      error = "El nombre completo debe tener al menos 3 caracteres.";
    }

    if (name === "email" && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
      error = "Por favor, ingresa un correo electrónico válido.";
    }

    if (name === "phone" && !/^\d{10}$/.test(value)) {
      error = "El teléfono debe tener exactamente 10 dígitos.";
    }

    if (name === "rfc" && !/^[A-ZÑ&]{3,4}\d{6}[A-Z\d]{3}$/.test(value)) {
      error = "Ingresa un RFC válido (Ejemplo: ABCD123456XYZ).";
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) newErrors[key] = error;
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const SERVICE_ID = "service_vpxsk5w";
    const TEMPLATE_ID = "template_kmbhse8";
    const USER_ID = "P7JKzjomBJdoKohll";

    if (!validateForm()) {
      alert("Por favor, corrige los errores antes de enviar.");
      return;
    }

    emailjs.send(SERVICE_ID, TEMPLATE_ID, formData, USER_ID).then(
      (response) => {
        alert("¡Formulario enviado exitosamente!");
        setFormData({
          fullName: "",
          email: "",
          phone: "",
          rfc: "",
          afiliation: "",
          message: "",
          to_email:
            "atencionejecutivos@getnet.mx,soporte_magento@microformas.com.mx",
          source: "QuejasYSugerencias",
        });
      },
      (error) => {
        console.error("Error al enviar el mensaje:", error);
        alert("Hubo un problema al enviar el formulario.");
      }
    );
  };

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <Button
          onClick={() =>
            handleEventWithCallBackAction(
              () =>
                handleEvent({
                  event: "form_view",
                  action_type: "access pop up form",
                  selection_name: "user view",
                  module_type: "complaints and suggestions",
                  page_category: "pop up form",
                  form_id: "QuejasYSugerencias",
                }),
              () => onClose()
            )
          }
          className="btn-modal-close"
        >
          X
        </Button>
        <h1>Buzón de quejas y sugerencias</h1>
        <div className="modal-sections">
          <div className="modal-text">
            <h2>Queremos escucharte</h2>
          </div>
          <Form onSubmit={handleSubmit} className="modal-content">
            <h2>¡Déjanos tus datos!</h2>
            <Form.Group className="mb-3" controlId="formFullName">
              <Form.Control
                type="text"
                placeholder="Nombre completo"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                onBlur={handleChange}
                isInvalid={!!errors.fullName}
                required
              />
            </Form.Group>
            <Row className="mb-3">
              {/* Email */}
              <Form.Group as={Col} className="mb-6" controlId="formEmail">
                <Form.Control
                  type="email"
                  placeholder="Dirección de e-mail"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-6" controlId="formPhone">
                <Form.Control
                  type="tel"
                  placeholder="Teléfono"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  onBlur={handleChange}
                  isInvalid={!!errors.phone}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="mb-6" controlId="formRFC">
                <Form.Control
                  type="text"
                  placeholder="Ingresa tu RFC"
                  name="rfc"
                  value={formData.rfc}
                  onChange={handleChange}
                  onBlur={handleChange}
                  isInvalid={!!errors.rfc}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-6" controlId="formAfiliation">
                <Form.Control
                  type="text"
                  placeholder="No. de Afiliación"
                  name="afiliation"
                  value={formData.afiliation}
                  onChange={handleChange}
                  onBlur={handleChange}
                />
              </Form.Group>
            </Row>
            {/* Mensaje */}
            <Form.Group className="mb-3" controlId="formMessage">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Mensaje"
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPrivacy">
              <Form.Check
                type="checkbox"
                label={
                  <span>
                    AVISO DE PRIVACIDAD: Acepto y conozco los{" "}
                    <a
                      href="/media/documents/Terminos-y-Condiciones.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Términos de uso
                    </a>{" "}
                    y{" "}
                    <a
                      href="/media/documents/Aviso2025.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Aviso y Política de Privacidad
                    </a>
                    .
                  </span>
                }
                checked={privacyAccepted}
                onChange={(e) => setPrivacyAccepted(e.target.checked)}
                isInvalid={!!errors.privacy}
                feedback={errors.privacy}
                required
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="action-primary modal-btn"
            >
              Enviar
            </Button>
          </Form>
        </div>
        <div className="modal-text-two">
          <h1>
            <span>¿Necesitas rollos para tu terminal?</span> Puedes solicitarlos
            a través de nuestro chatbot en Facebook, solo da clic 
            <button className="text-[#ec0000]"
              onClick={() => handleEventWithCallBackAction(
                () =>
                  handleEvent({
                    event: "external",
                    action_type: "click on link text",
                    selection_name: "here",
                    module_type: "complaints and suggestions",
                    page_category: "pop up form",
                  }),
                  () => {
                    window.location.href = "https://www.facebook.com/GetnetMx/";
                  }
              )}
            >
              aquí.
            </button>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default ModalQuejasYSugerencias;
