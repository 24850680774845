import React from "react";
import '../styles/Banner.css'

const Banner = ({ data }) => {
    return (
        <div className="mx-auto max-w-7xl justify-center items-center text-center flex flex-col lg:px-4 xs:text-start xs:justify-start xs:items-start 2xl:max-w-6xl">
            {data.title && <h1 className="text-5xl xs:text-center xs:text-3xl 2xl:text-4xl">{data.title}</h1>}
            {data.textRegular && <p className="mt-10 text-xl xs:text-start">{data.textRegular}</p>}
            {data.textRegularTwo && <p className="mb-10 text-xl xs:text-start">{data.textRegularTwo}</p>}
            {data.image && <img src={data.image} alt={data.title} />}
        </div>
    );
};

export default Banner; 