const PayPalFeatures = ({ data }) => {
    const formatTextWithSuperscript = (text) => {
        return text.replace(/(?<!\d\/|\d\.)(\d+)(?!(\d|%|\/\d|\.\d))/g, (match) => `<sup>${match}</sup>`);
    };
    const highlightedTexts = [
        "Registra o abre tu cuenta PayPal con Getnet",
        "3.80% + $4.00 MXN + IVA"
    ];

    const renderHighlightedText = (text) => {
        if (typeof text !== "string") return text;

        let parts = [text];

        highlightedTexts.forEach((highlight, index) => {
            parts = parts.flatMap((part) =>
                typeof part === "string" && part.includes(highlight)
                    ? part.split(highlight).flatMap((p, i, arr) =>
                        i < arr.length - 1
                            ? [p, <span key={`${index}-${i}`} className="font-bold text-[#EC0000]">{highlight}</span>]
                            : p
                    )
                    : part
            );
        });

        return parts;
    };
    return (
        <div className="w-full py-12">
            <div className="mx-auto px-4">
                <h1 className="max-w-7xl text-5xl text-center mb-24 pt-10 mx-auto 2xl:text-4xl 2xl:max-w-4xl leading-normal xs:text-3xl xs:mb-10" dangerouslySetInnerHTML={{ __html: formatTextWithSuperscript(data.title) }}>
                </h1>

                <div className="grid grid-cols-3 md:grid-cols-1 gap-8 text-center max-w-7xl mx-auto pb-4">
                    <div className="space-y-4">
                        <h2 className="text-5xl font-semibold text-[#EC0000] xs:text-4xl">
                            {data.percentageOne}
                        </h2>
                        <p className="text-2xl text-gray-700 leading-relaxed whitespace-pre-line">
                            {data.textOne}
                        </p>
                    </div>

                    <div className="space-y-4">
                        <h2 className="text-5xl font-semibold text-[#EC0000] xs:text-4xl">
                            {data.percentageTwo}
                        </h2>
                        <p className="text-2xl text-gray-700 leading-relaxed whitespace-pre-line">
                            {data.textTwo}
                        </p>
                    </div>

                    <div className="space-y-4">
                        <h2 className="text-5xl font-semibold text-[#EC0000] xs:text-4xl">
                            {data.percentageThree}
                        </h2>
                        <p className="text-2xl text-gray-700 leading-relaxed whitespace-pre-line">
                            {data.textThree}
                        </p>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-2 items-center bg-[#EDEEF0] p-10 mt-16 h-screen xs:grid-cols-1 xs:mt-0 xs:p-6">
                <div className="flex justify-end">
                    <img
                        src={data.image}
                        alt="PayPal Feature"
                        className="w-[80%] ml-auto mr-auto xs:w-full"
                    />
                </div>
                <div className="text-left pr-20 max-w-3xl mt-6 xs:max-w-7xl xs:pr-0 xs:mt-0">
                    <p className="text-5xl leading-relaxed 2xl:text-4xl xs:text-xl xs:text-center">
                        {renderHighlightedText(data.textFour)}
                        <sup>10</sup>
                    </p>
                </div>
            </div>

        </div>
    );
};

export default PayPalFeatures;
