import React, { useState } from "react";
import emailjs from "emailjs-com";

const PayPalForm = ({ data }) => {
  const [formData, setFormData] = useState({
    nombre: "",
    email: "",
    telefono: "",
    empresa: "",
    estado: "",
    to_email: "luimora@getnet.mx",
    privacidad: false,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Nuevo estado para el loader

  const validateField = (name, value) => {
    let error = "";

    if (name === "nombre" && (!value || value.length < 3)) {
      error = "El nombre debe tener al menos 3 caracteres.";
    }

    if (name === "email" && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
      error = "Por favor, ingresa un correo electrónico válido.";
    }

    if (name === "telefono" && !/^\d{10}$/.test(value)) {
      error = "El teléfono debe tener exactamente 10 dígitos.";
    }

    if (name === "empresa" && !value) {
      error = "El campo de empresa es obligatorio.";
    }

    if (name === "estado" && !value) {
      error = "El campo de estado es obligatorio.";
    }

    if (name === "privacidad" && !value) {
      error = "Debes aceptar el aviso de privacidad.";
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) newErrors[key] = error;
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const SERVICE_ID = "service_vpxsk5w";
    const TEMPLATE_ID = "template_jmg3k6a";
    const USER_ID = "P7JKzjomBJdoKohll";

    if (!validateForm()) {
      alert("Por favor, corrige los errores antes de enviar.");
      return;
    }

    setLoading(true); // Activar el loader

    emailjs.send(SERVICE_ID, TEMPLATE_ID, formData, USER_ID).then(
      (response) => {
        alert("¡Formulario enviado exitosamente!");
        setFormData({
          nombre: "",
          email: "",
          telefono: "",
          empresa: "",
          estado: "",
          to_email: "luimora@getnet.mx",
          privacidad: false,
        });
        setLoading(false); // Desactivar el loader
      },
      (error) => {
        alert("Hubo un problema al enviar el formulario.");
        setLoading(false); 
      }
    );
  };

  return (
    <div className="max-w-7xl mx-auto px-4 pt-24 pb-10 lg:pt-o lg:pb-10">
      <div className="flex gap-8 lg:flex-col lg:gap-6">
        {/* Columna de Texto */}
        <div className="p-6 flex-1 2xl:p-12 lg:text-center lg:w-full">
          <div className="space-y-4">
            <h2 className="text-5xl font-bold text-[#EC0000] leading-normal lg:text-4xl xs:text-2xl">
              {data.title}
            </h2>
            <p className="text-4xl leading-normal max-w-md lg:text-2xl lg:text-center lg:max-w-7xl xs:text-xl">
              {data.textOne}
            </p>
          </div>
        </div>

        {/* Columna del Formulario */}
        <div className="space-y-6 flex-1 ml-10 lg:ml-0 lg:w-full">
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div className="flex flex-col">
              <label htmlFor="nombre" className="mb-2 text-xl text-[#767676]">
                Nombre completo
              </label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                className="p-2 border rounded-md"
                value={formData.nombre}
                onChange={handleChange}
                required
              />
              {errors.nombre && <span className="text-red-600">{errors.nombre}</span>}
            </div>

            <div className="flex flex-col">
              <label htmlFor="email" className="mb-2 text-xl text-[#767676]">
                Dirección de e-mail
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="p-2 border rounded-md"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && <span className="text-red-600">{errors.email}</span>}
            </div>

            <div className="grid grid-cols-2 gap-x-4 lg:grid-cols-1 lg:w-full">
              <div className="flex flex-col">
                <label htmlFor="telefono" className="mb-2 text-xl text-[#767676]">
                  Teléfono
                </label>
                <input
                  type="tel"
                  id="telefono"
                  name="telefono"
                  className="p-2 border rounded-md"
                  value={formData.telefono}
                  onChange={handleChange}
                  required
                />
                {errors.telefono && <span className="text-red-600">{errors.telefono}</span>}
              </div>

              <div className="flex flex-col">
                <label htmlFor="empresa" className="mb-2 text-xl text-[#767676]">
                  Empresa
                </label>
                <input
                  type="text"
                  id="empresa"
                  name="empresa"
                  className="p-2 border rounded-md"
                  value={formData.empresa}
                  onChange={handleChange}
                  required
                />
                {errors.empresa && <span className="text-red-600">{errors.empresa}</span>}
              </div>
            </div>

            <div className="flex flex-col">
              <label htmlFor="estado" className="mb-2 text-xl text-[#767676]">
                Estado
              </label>
              <input
                type="text"
                id="estado"
                name="estado"
                className="p-2 border rounded-md"
                value={formData.estado}
                onChange={handleChange}
                required
              />
              {errors.estado && <span className="text-red-600">{errors.estado}</span>}
            </div>

            <div className="flex items-center gap-2 mt-4 mx-auto">
              <input
                type="checkbox"
                id="privacidad"
                name="privacidad"
                className="w-4 h-4"
                checked={formData.privacidad}
                onChange={handleChange}
                required
              />
              <label htmlFor="privacidad" className="text-lg">
                He leído y acepto el{' '}
                <a
                  href="/media/documents/Aviso2025.pdf"
                  className="no-underline hover:underline text-[#EC0000]"
                >
                  Aviso de Privacidad*
                </a>
              </label>
            </div>

            {errors.privacidad && <span className="text-red-600">{errors.privacidad}</span>}

            <button
              type="submit"
              className="bg-[#EC0000] text-white font-bold py-3 px-6 rounded-lg xs:w-full mt-4 w-[20%] mx-auto"
              disabled={loading}
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <div className="w-4 h-4 border-4 border-t-4 border-white rounded-full animate-spin"></div>
                </div>
              ) : (
                "Enviar"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PayPalForm;
