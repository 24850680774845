import React from 'react';
import { useParams } from 'react-router-dom';
import data from '../data/BlogArticle10.json';
import BlogRecent from '../components/BlogRecent';

const BlogArticle10 = () => {
    const { id } = useParams();
    const articleData = data.find((article) => article.id === id);

    if (!articleData) return <div className="text-center py-10 text-gray-500">Artículo no encontrado.</div>;

    return (
        <div className="pt-24 max-w-7xl mx-auto px-6">
            <img src={articleData.general[0].mainImage} alt={articleData.sectionOne[0].title} className="w-full rounded-lg mb-6" />
            <h1 className="text-4xl text-black text-center pt-4 pb-8 xs:text-3xl">{articleData.sectionOne[0].title}</h1>
            <p className="text-[#767676] mb-6 text-xl xs:text-lg xs:text-center">{articleData.sectionOne[0].pOne}</p>
            <h1 className="text-4xl text-black text-center pt-4 pb-8 xs:text-3xl">{articleData.sectionTwo[0].title}</h1>
            <p className="text-[#767676] mb-6 text-xl xs:text-lg xs:text-center">{articleData.sectionTwo[0].pOne}</p>
            <p className="text-[#767676] mb-6 text-xl xs:text-lg xs:text-center">{articleData.sectionTwo[0].pTwo}</p>
            <h2 className="text-4xl text-black text-center pt-4 pb-8 xs:text-3xl">
                {articleData.sectionThree[0].title}
            </h2>
            <p className="text-[#767676] mb-6 text-2xl text-center pb-4 xs:text-xl xs:text-center">
                {articleData.sectionThree[0].pOne}
            </p>
            <ul className="space-y-1">
                {articleData.sectionThree[0].advantages.map((adv, index) => (
                    <li key={index} className="flex items-start gap-2 max-w-6xl mx-auto">
                        <span className="w-2 h-2 bg-[#EC0000] flex-shrink-0 mt-2.5"></span>
                        <p className="text-xl text-[#767676] leading-relaxed xs:text-lg">
                            <span className="font-bold">{adv.title}</span> {adv.pOne}
                        </p>
                    </li>
                ))}
            </ul>
            <div className="bg-[#EC0000] p-12 my-6 rounded-lg text-center text-white text-2xl font-semibold xs:text-lg">
                {articleData.bannerOne[0].title}
            </div>
            <h2 className="text-4xl text-black text-center pt-4 pb-8 xs:text-3xl">{articleData.sectionFour[0].title}</h2>
            <p className="text-[#767676] mb-6 text-xl xs:text-lg xs:text-center">{articleData.sectionFour[0].pOne}</p>
            <h3 className="text-2xl font-bold text-[#EC0000] mb-4 text-center pt-4 pb-4 xs:text-xl">{articleData.sectionFour[0].titleTwo}</h3>
            <div className="grid md:grid-cols-1 gap-4">
                {articleData.sectionFour[0].items.map((item, index) => (
                    <div key={index} className="p-2 text-black rounded-lg flex flex-col items-center text-center">
                        <img src={item.image} alt={item.title} className="w-16 h-16 mb-2" />
                        <h4 className="font-bold text-[#EC0000] xs:text-xl">{item.title}</h4>
                        <p className="max-w-lg mx-auto text-xl xs:text-lg">{item.text}</p>
                    </div>
                ))}
            </div>
            <h2 className="text-4xl text-black text-center pt-4 pb-8 xs:text-3xl">
                {articleData.sectionFive[0].title.split(/(Get Checkout)/g).map((part, i) =>
                    part === "Get Checkout" ? (
                        <span key={i} className="text-[#EC0000] font-bold">{part}</span>
                    ) : (
                        part
                    )
                )}
            </h2>
            {[articleData.sectionFive[0].pOne, articleData.sectionFive[0].pTwo, articleData.sectionFive[0].pThree].map((paragraph, index) => (
                <p key={index} className="text-[#767676] mb-6 text-xl leading-relaxed xs:text-center xs:text-lg">
                    {paragraph.split(/(Get Checkout)/g).map((part, i) =>
                        part === "Get Checkout" ? (
                            <span key={i} className="text-[#EC0000] font-bold">{part}</span>
                        ) : (
                            part
                        )
                    )}
                </p>
            ))}
            <h3 className="text-[#767676] mb-6 text-2xl font-bold text-center pt-4 pb-8">{articleData.sectionFive[0].titleTwo}</h3>
            <BlogRecent data={articleData.recentArticles[0]} />
        </div>
    );
};

export default BlogArticle10;
