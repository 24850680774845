import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import emailjs from "emailjs-com";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../styles/ContactForm.css";
import inventory from "../data/InventoyContact.json";
import useSendEvents from "../hooks/useSendEvents";

function ContactForm() {
  const currentLocation = useLocation();
  const { handleEvent, handleEventWithCallBackAction } = useSendEvents();
  const [title, setTitle] = useState("Contacto")
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    lastname: "",
    email: "",
    phone: "",
    product: "",
    client: "",
    rfc: "",
    sector: "",
    to_email: "",
    source: "",
  });

  useEffect(() => {
    const params = new URLSearchParams(currentLocation.search);
    const source = params.get("source") || "";

    const sourceParts = source.split('/');

    let emails = inventory;
    sourceParts.forEach(part => {
      emails = emails[part] || {};  
      setTitle(part)
    });

    const getEmails = Object.values(emails).length > 0 ? emails : inventory["Default"];    
    const toEmailString = Array.isArray(getEmails)
      ? getEmails.join(",")
      : getEmails;


    const productOptions = [
      "Get Smart",
      "Get Mini",
      "Get Advance",
      "G Tapp",
      "Centro de Pagos",
      "Get Checkout",
      "Get Link and Pay",
    ];
   
    const product = productOptions.find((product) => product.replace(/\s+/g, '').toLocaleLowerCase() == title.replace(/\s+/g, '').toLocaleLowerCase()) || ""
    console.log(product, 'product')

    setFormData((prevData) => ({
      ...prevData,
      source,
      to_email: toEmailString,
      product,
    }));
  }, [currentLocation, title]);

  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    let error = "";

    if (name === "name" && (!value || value.length < 3)) {
      error = "El nombre debe tener al menos 3 caracteres.";
    }

    if (name === "lastname" && (!value || value.length < 3)) {
      error = "El apellido debe tener al menos 3 caracteres.";
    }

    if (name === "email" && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
      error = "Por favor, ingresa un correo electrónico válido.";
    }

    if (name === "phone" && !/^\d{10}$/.test(value)) {
      error = "El teléfono debe tener exactamente 10 dígitos.";
    }
    if (
      name === "rfc" &&
      value.trim() !== "" &&
      !/^[A-ZÑ&]{3,4}\d{6}[A-Z0-9]{3}$/.test(value)
    ) {
      error = "El RFC no es válido.";
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) newErrors[key] = error;
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const SERVICE_ID = "service_vpxsk5w";
    const TEMPLATE_ID = "template_kmbhse8";
    const USER_ID = "P7JKzjomBJdoKohll";

    if (!validateForm()) {
      alert("Por favor, corrige los errores antes de enviar.");
      return;
    }

    emailjs.send(SERVICE_ID, TEMPLATE_ID, formData, USER_ID).then(
      (response) => {
        alert("¡Formulario enviado exitosamente!");
        setFormData({
          name: "",
          lastname: "",
          email: "",
          phone: "",
          product: "",
          client: "",
          rfc: "",
          sector: "",
          to_email: "",
          source: "",
        });
      },
      (error) => {
        alert("Hubo un problema al enviar el formulario.");
      }
    );

    handleEventWithCallBackAction(() =>
      handleEvent({
        event: "form_view",
        action_type: "access form",
        selection_name: "user view",
        module_type: "form",
        page_category: "contact page",
        form_id: formData.source
      })
    );
  };

  return (
    <Form className="contact-form xs:pt-20" onSubmit={handleSubmit}>
      <h1 className="mx-auto mb-4">{title}</h1>
      <h1>¿Te gustaría hablar con uno de nuestros expertos?</h1>
      <p>Compártenos tus datos y nos pondremos en contacto contigo.</p>
      <Row className="mb-3 pt-4">
        <Form.Group as={Col} className="mb-6" controlId="formName">
          <Form.Control
            type="text"
            placeholder="Nombre*"
            name="name"
            value={formData.name}
            onChange={handleChange}
            onBlur={handleChange}
            isInvalid={!!errors.name}
            required
          />
        </Form.Group>
        <Form.Group as={Col} className="mb-6" controlId="formLastname">
          <Form.Control
            type="text"
            placeholder="Apellido*"
            name="lastname"
            value={formData.lastname}
            onChange={handleChange}
            onBlur={handleChange}
            isInvalid={!!errors.lastname}
            required
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="mb-6" controlId="formEmail">
          <Form.Control
            type="email"
            placeholder="Correo electrónico* (ejemplo: cliente@gmail.com)"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group as={Col} className="mb-6" controlId="formPhone">
          <Form.Control
            type="tel"
            placeholder="Número de teléfono* (ejemplo: 5555555555)"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            onBlur={handleChange}
            isInvalid={!!errors.phone}
            required
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="mb-6" controlId="formProduct">
          <Form.Select
            name="product"
            value={formData.product}
            onChange={handleChange}
            required
          >
            <option value="">¿Qué producto te interesa?</option>
            <option value="Get Smart">Get Smart</option>
            <option value="Get Mini">Get Mini</option>
            <option value="Get Advance">Get Advance</option>
            <option value="G Tapp">G Tapp</option>
            <option value="Centro de Pagos">Centro de Pagos</option>
            <option value="Get Checkout">Get Checkout</option>
            <option value="Get Link and Pay">Get Link and Pay</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} className="mb-6" controlId="formClient">
          <Form.Select
            name="client"
            value={formData.client}
            onChange={handleChange}
            required
          >
            <option value="">¿Eres cliente Santander?</option>
            <option value="Si">Si</option>
            <option value="No">No</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        {formData.client === "Si" && (
          <Form.Group as={Col} className="mb-6" controlId="formRFC">
            <Form.Control
              type="text"
              placeholder="RFC"
              name="rfc"
              value={formData.rfc}
              onChange={handleChange}
              onBlur={handleChange}
              isInvalid={!!errors.rfc}
            />
          </Form.Group>
        )}
        <Form.Group as={Col} className="mb-6" controlId="formSector">
          <Form.Select
            name="sector"
            value={formData.sector}
            onChange={handleChange}
            required
          >
            <option value="">Sector de negocio</option>
            <option value="Hoteles">Hoteles</option>
            <option value="Restaurantes">Restaurantes</option>
            <option value="Misceláneas">Misceláneas</option>
            <option value="PyMEs">PyMEs</option>
            <option value="Médicos">Médicos</option>
            <option value="Universidades">Universidades</option>
            <option value="Refaccionarias y Ferreterías">
              Refaccionarias y Ferreterías
            </option>
            <option value="otros">Otros</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Form.Group className="mb-3" controlId="formPrivacy">
        <Form.Check
          type="checkbox"
          label={
            <span>
              AVISO DE PRIVACIDAD: Acepto y conozco los{" "}
              <a
                href="/media/documents/Terminos-y-Condiciones.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Términos de uso
              </a>{" "}
              y{" "}
              <a
                href="/media/documents/Aviso2025.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Aviso y Política de Privacidad
              </a>
              .
            </span>
          }
          checked={privacyAccepted}
          onChange={(e) => setPrivacyAccepted(e.target.checked)}
          isInvalid={!!errors.privacy}
          feedback={errors.privacy}
          required
        />
      </Form.Group>
      <Button variant="primary" type="submit" className="action-primary">
        Enviar
      </Button>
    </Form>
  );
}

export default ContactForm;