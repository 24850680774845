import React, { useState } from "react";
import data from "../data/Home.json";
import "../styles/SectorsAccordion.css";
import useSendEvents from "../hooks/useSendEvents";

const SectorsAccordion = () => {
  const sectors = data[0]?.sectors[0]?.sector || [];
  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggle = (index) => {
    setActiveIndex(index);
  };

  const { handleEvent, handleEventWithCallBackRedirect } = useSendEvents();

  return (
    <div className="sectors xs:mx-1 px-4">
      <div className="mx-32 2xl:mx-5 xl:mx-5 my-20 lg:my-10 lg:mx-5 xs:my-5 xs:mx-1">
        <h2 className="text-5xl text-start ml-40 lg:text-start xs:ml-1 xs:text-4xl xs:text-center lg:ml-0">
          Soluciones de pago para distintos sectores.
        </h2>
        <div className="grid grid-cols-2 lg:grid-cols-1 xs:grid-cols-1 gap-x-2 mt-16 ">
          <div className="pr-10 pl-40 pt-10 pb-10 w-full h-[600px] lg:p-0 xs:h-full xs:p-1 2xl:pl-0">
            <img
              src={sectors[activeIndex]?.mainImage}
              alt={sectors[activeIndex]?.title}
              className="w-full h-full object-cover"
            />
          </div>

          <div>
            {sectors.map((sector, index) => (
              <div key={index} className="border-b-2 border-gray-200">
                <div className="flex flex-row justify-between">
                  <button
                    className={`accordion-header ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() => handleToggle(index)}
                  >
                    {sector.title}
                  </button>
                  <button
                    className={`text-red-500 text-2xl transition duration-500 ${
                      activeIndex === index ? "-rotate-90" : "rotate-90"
                    }`}
                  >
                    <span>&#8250;</span>
                  </button>
                </div>

                <div
                  className={`overflow-hidden transition-all duration-1000 ease-in-out ${
                    activeIndex === index ? "max-h-[500px]" : "max-h-0"
                  }`}
                >
                  {activeIndex === index && (
                    <div className="flex flex-col mb-4">
                      <p>{sector.text}</p>
                      {sector.textTwo && <small>{sector.textTwo}</small>}
                      <button
                        onClick={() =>
                          handleEventWithCallBackRedirect(
                            () =>
                              handleEvent({
                                event: "select_content",
                                action_type: "click on cta",
                                selection_name: "know more",
                                module_type: "sectors section",
                                page_category: "home",
                                value: sector.event
                              }),
                            sector.link
                          )
                        }
                        className="action-terciary text-end mr-10"
                      >
                        <a className="text-[#EC0000] no-underline">
                          {sector.actionSecondary}
                        </a>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectorsAccordion;
