import React from "react";
import '../styles/Modalities.css'

const Modalities = ({ data }) => {
    const header = data[0] || {};
    const modalitiesList = Array.isArray(data[1]) ? data[1] : [];

    return (
        <section className="modalities">
            <h1>{header.title || "Título no disponible"}</h1>
            <p>{header.textRegular || "Descripción no disponible"}</p>
            <div className="modalities-grid">
                {modalitiesList.map((item, index) => (
                    <div key={index} className="modalities-card">
                        <img src={item.image} alt={item.title || "Imagen sin título"} />
                        <h3>{item.title || "Título no disponible"}</h3>
                        <p>{item.textRegular || "Descripción no disponible"}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Modalities;
