import React, { useEffect, useState } from "react";
import { collection, deleteDoc, doc, fireStore, getDocs } from "../../../utils/firebase/config";
import { Button, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Spinner, Tooltip } from "@nextui-org/react";
import { Trash, ExternalLink } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const TableBlog = () => {
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const articlesCollection = collection(fireStore, "blogs");
        const articlesSnapshot = await getDocs(articlesCollection);
        const articlesList = articlesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setArticles(articlesList);
      } catch (error) {
        console.error("Error al obtener los artículos:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleDeleteArticle = async (id) => {
    const confirmDelete = await Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta acción no se puede deshacer.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    });

    if (confirmDelete.isConfirmed) {
      try {
        await deleteDoc(doc(fireStore, "blogs", id));
        setArticles(articles.filter((article) => article.id !== id));
        Swal.fire("Eliminado", "El artículo ha sido eliminado.", "success");
      } catch (error) {
        console.error("Error al eliminar el artículo:", error);
        Swal.fire("Error", "No se pudo eliminar el artículo.", "error");
      }
    }
  };

  return (
    <div className="p-6 ">
      {/* Título y Descripción */}
      <div className="mb-6">
      <h1 className="text-5xl font-bold text-center mb-4 text-gray-900">
      Lista de Articulos</h1>
      <p className="text-lg text-center text-gray-600 mb-8 max-w-2xl mx-auto">
          Aquí puedes ver y gestionar todos los Articulos publicados.
        </p>
      </div>

      {loading ? (
        <div className="flex justify-center">
          <Spinner size="lg" />
        </div>
      ) : (
        <Table aria-label="Lista de blogs" className="shadow-sm">
          <TableHeader>
            <TableColumn className="bg-gray-100">Template</TableColumn>
            <TableColumn className="bg-gray-100">Título</TableColumn>
            <TableColumn className="bg-gray-100">Fecha</TableColumn>
            <TableColumn className="bg-gray-100">Acciones</TableColumn>
          </TableHeader>
          <TableBody>
            {articles.map((article) => (
              <TableRow key={article.id} className="hover:bg-gray-50 transition-colors">
                <TableCell>Template {article.template}</TableCell>
                <TableCell>{article.preview?.title || "Sin título"}</TableCell>
                <TableCell>{article.preview?.date || "Sin fecha"}</TableCell>
                <TableCell>
                  <div className="flex gap-2">
                    {/* Botón Eliminar con Tooltip */}
                    <Tooltip content="Eliminar"  placement="bottom">
                      <Button
                        size="sm"
                        isIconOnly
                        onClick={() => handleDeleteArticle(article.id)}
                        className="bg-red-50  flex justify-center text-center rounded-full hover:bg-red-100"
                      >
                        <Trash className="text-red-500" size={16} />
                      </Button>
                    </Tooltip>

                    {/* Botón Ver con Tooltip */}
                    <Tooltip content="Ver artículo" placement="bottom">
                      <Button
                        size="sm"
                        isIconOnly
                        onClick={() => navigate(article.preview?.link || "#")}
                        className="bg-blue-50 flex justify-center text-center rounded-full hover:bg-blue-100"
                      >
                        <ExternalLink className="text-blue-500" size={16} />
                      </Button>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default TableBlog;