import React from "react";

const TestimonialsCarousel = ({ data }) => {

  return (
    <section className="bg-[#9BC3D3] relative py-8 xs:py-1 lg:px-2 px-20 mb-32">
      <div className="relative overflow-hidden">
          {data.map((testimonial, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-full grid grid-cols-2 lg:grid-cols-1 xs:grid-cols-1 py-10 gap-x-20 lg:gap-y-10 items-center lg:flex lg:flex-col-reverse"
            >
              <div className="flex flex-col ml-64 xl:ml-24 lg:ml-2 xs:ml-2 lg-h-56  text-black xs:h-56">
                <p className="text-5xl xl:text-3xl lg:text-xl lg:text-center xs:text-center lg:mr-24">
                  "{testimonial.text}"
                </p>
              </div>

              <div className="relative h-96 xl:w-2/3 lg:h-60 lg:w-full xs:h-60 xs:w-full">
                <img
                  src={testimonial.image}
                  alt="Testimonial"
                  className="absolute top-0 left-0 w-full h-full object-contain"
                />
              </div>
            </div>
          ))}
        </div>
    </section>
  );
};

export default TestimonialsCarousel;
