import React from 'react';
import '../styles/OurPlugins.css'

const OurPlugins = ({ data }) => {
  return (
    <section className="mx-32 lg:mx-20 xs:mx-4 my-10">
      <h2 className=" text-5xl text-center xs:text-3xl">{data[0].title}</h2>
      <p className="text-center mt-1 text-l lg:px-8 xs:px-2">{data[0].textRegular}</p>

      <div className="grid grid-cols-4 lg:grid-cols-2 lg:gap-y-4 xs:grid-cols-1 lg:mx-8 xs:gap-y-4 xs:mx-4 gap-x-10 mt-14">
      {data[1].map((item, index) => (
          <div key={index} className='border-gray-200 space-y-2 border shadow-md p-4 h-80'>
            <img src={item.image} alt={item.title} />
            <h3 className='text-2xl'>{item.title}</h3>            
            <p className='text-lg'>{item.text}</p>
          </div>
        ))}
      </div>      
    </section>
  );
};

export default OurPlugins;
