import React from "react";

const PayPalReasons = ({ data }) => {
    const formatTextWithSuperscript = (text) => {
        return text.replace(/(?<!\d\/|\d\.)(\d+)(?!(\d|%|\/\d|\.\d))/g, (match) => `<sup>${match}</sup>`);
    };

    return (
        <div className="flex px-5">
            <div className="grid grid-cols-2 overflow-hidden xs:grid-cols-1">
                <div className="p-5 flex justify-center items-center">
                    <h2 className="text-6xl max-w-5xl leading-normal pt-36 pb-20 pl-40 w-[80%] 2xl:text-5xl 2xl:w-full 2xl:pl-0 xs:pt-0 xs:pb-4 xs:pl-0 xs:w-full xs:text-3xl xs:text-center">{data.title}</h2>
                </div>

                <div className="w-full p-8">
                    <div className="items-start pt-20 space-y-10 xs:pt-0 xs:text-center">
                        <div className="flex items-center xs:flex-col">
                            <span className="text-6xl font-bold text-[#EC0000] 2xl:text-5xl xs:text-4xl">{data.percentageOne}</span>
                            <p className="text-[#333333] text-lg max-w-md pl-12 pt-4 xs:pl-0"
                                dangerouslySetInnerHTML={{ __html: formatTextWithSuperscript(data.textOne) }}
                            />
                        </div>
                        <div className="flex items-center xs:flex-col">
                            <span className="text-6xl font-bold text-[#EC0000] 2xl:text-5xl xs:text-4xl">{data.percentageTwo}</span>
                            <p className="text-[#333333] text-lg max-w-md pl-20 pt-4 xs:pl-0"
                                dangerouslySetInnerHTML={{ __html: formatTextWithSuperscript(data.textTwo) }}
                            />
                        </div>
                        <div className="flex items-center xs:flex-col">
                            <span className="text-6xl font-bold text-[#EC0000] 2xl:text-5xl xs:text-4xl">{data.percentageThree}</span>
                            <p className="text-[#333333] text-lg max-w-md pl-20 pt-4 xs:pl-0"
                                dangerouslySetInnerHTML={{ __html: formatTextWithSuperscript(data.textThree) }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayPalReasons;