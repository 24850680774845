import React from "react";
import { Settings, Home } from "lucide-react";
import Sidebar from "../../components/dashboard/layout/Sidebar";
import BlogTemplate from "../blog/template/BlogTemplates";
import TableBlog from "../../components/dashboard/blog/TableBlog";

const BlogDash = () => {
  return (
    <div className="flex mx-auto mt-32">
      <Sidebar />
      <div className="flex-1 p-5">
        <h1 className="text-2xl font-bold flex items-center gap-2">
          <Settings size={24} />
          Configuración de Blog
        </h1>
        <p className="text-gray-700 mt-2 flex items-center gap-2">
          <Home size={18} />
          Esta sección está destinada a configurar el blog de la vista blog
        </p>
        <TableBlog/>
        <BlogTemplate/>
      </div>
    </div>
  );
};

export default BlogDash;
