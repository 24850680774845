import React from "react";
import "../styles/Details.css";

const Details = ({ details, checkout = false }) => {
  return (
    <section className="details flex items-center my-10">
      <div>
        {details.map((item, index) => {
          const isGif = item.image?.endsWith(".gif");
          const isBgWhite = checkout

          return (
            <div
              key={index}
              className={`grid grid-cols-2 xs:flex xs:flex-col-reverse ${checkout ? "" : "space-y-10 xl:space-y-6"} lg:px-5 gap-x-10 xs:space-y-0 xs:my-2 xs:p-4`}
            >
              <div className="flex flex-col justify-center items-center text-center px-10 xs:p-0 xs:py-4 xs:mb-2">
                <div className={`${checkout ? "" : "my-36"} mx-20 lg:mx-2 xs:m-0 xs:px-4 text-start w-96`}>
                  <h3 className="text-6xl lg:text-5xl xs:text-5xl">{item.title}</h3>
                  <p className="pt-4 text-lg text-gray-500">{item.text}</p>
                  {item.imageInText && (
                    <div className="w-32">
                      <img
                        src={item.imageInText}
                        alt="icon"
                        className="w-full h-full object-contain"
                      />
                    </div>
                  )}
                  {item.imageInTextArray && (
                    <div className="grid grid-cols-4 gap-x-2">
                      {item.imageInTextArray.map((src, index) => (
                        <img
                          key={index}
                          src={`${src} ${index + 1}.svg`}
                          alt={index}
                          className="w-full h-full object-contain"
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div
                className={`w-full h-[600px] ${
                  isBgWhite
                    ? "bg-[#f5f9fb]"
                    : isGif
                    ? "bg-[#f3f0f6]"
                    : "bg-[#DEEDF2] py-4"
                } xs:h-[400px] ${checkout && "p-4"} xs:p-4`}
              >
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Details;
