import React, { useState, useEffect } from "react";
import useSendEvents from "../hooks/useSendEvents";

const ProductList = ({ data, showIcons = true, elements = 3 }) => {


  const {
    handleEvent,
    handleEventWithCallBackAction,
    handleEventWithCallBackRedirect,
  } = useSendEvents();

  const getChunkSize = (width) => {
    if (width <= 768) return 1; // Mobile
    if (width <= 1023) return 2; // lg (pantallas grandes)
    return 3; // Desktop
  };

  const [chunkSize, setChunkSize] = useState(getChunkSize(window.innerWidth));
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setChunkSize(getChunkSize(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Dividir los datos en grupos de acuerdo con el chunkSize
  const chunkedData = [];
  for (let i = 0; i < data.product.length; i += chunkSize) {
    chunkedData.push(data.product.slice(i, i + chunkSize));
  }

  useEffect(() => {
    if (chunkedData.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === chunkedData.length - 1 ? 0 : prevIndex + 1
        );
      }, 8000);
      return () => clearInterval(interval);
    }
  }, [chunkedData.length]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? chunkedData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === chunkedData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const hasMultipleSlides = chunkedData.length > 1;

  return (
    <div className="w-full mx-auto max-w-7xl relative top-10 2xl:max-w-6xl">
      <div className="product-list my-10">
        <h1 className="text-5xl xs:text-3xl">{data.title}</h1>
        <p className="pb-20">{data.subheading}</p>

        <section className="mx-auto max-w-7xl mt-10 overflow-hidden xs:mt-2 relative">
          <div
            className="w-full flex transition-transform duration-700 ease-in-out"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {chunkedData.map((group, groupIndex) => (
              <div
                key={groupIndex}
                className={`w-full flex-shrink-0 grid grid-cols-${elements} lg:grid-cols-2 xs:grid-cols-1 gap-x-24 lg:px-4 lg:gap-x-10`}
              >
                {group.map((item, index) => (
                  <div
                    key={index}
                    className="grid grid-rows-2 xs:flex xs:flex-col w-full"
                  >
                    <div className="bg-[#F5F9FB]">
                      <div className="h-96 px-4 py-10">
                        {item.mainImage && (
                          <img
                            src={item.mainImage}
                            alt={`${item.titleOne} ${item.titleTwo}`}
                            className="w-full h-full object-contain"
                          />
                        )}
                      </div>
                    </div>
                    <div className="grid grid-rows-2 justify-between px-4 bg-[#FBFBFB]">
                      <div className="flex flex-col text-start h-48">
                        <div className="flex flex-row gap-x-4 px-10 xl:px-2  xs:px-2 py-2 text-[#23779a]">
                          <p>{item.textOne}</p>
                          <p>{item.textTwo}</p>
                        </div>
                        <h3 className="text-3xl text-[#EC0000]">
                          {item.titleOne}{" "}
                          <span className="text-black">{item.titleTwo}</span>
                        </h3>
                        <h3 className="text-3xl text-black">{item.text}</h3>
                      </div>
                      <div className="flex mt-16">
                        {item.actionSecondary && (
                          <a href={item.link}>
                            <button className="action-secondary">
                              {item.actionSecondary}
                            </button>
                          </a>
                        )}
                        {item.actionPrimary && (
                          <button
                            onClick={() =>
                              handleEventWithCallBackRedirect(
                                () =>
                                  handleEvent({
                                    event: "select_content",
                                    action_type:
                                      data.title ==
                                      "Terminales para todo tipo de negocio."
                                        ? "click on cta"
                                        : "access to product details",
                                    selection_name:
                                      data.title ==
                                      "Terminales para todo tipo de negocio."
                                        ? "hire now"
                                        : "discover more",
                                    module_type: "product list slide",
                                    page_category: "product page",
                                    value: item.source.toLowerCase(),
                                  }),
                                `${item.link}?source=${encodeURIComponent(
                                  item.source
                                )}`
                              )
                            }
                          >
                            <button className="action-primary">
                              {item.actionPrimary}
                            </button>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </section>
        {hasMultipleSlides && (
          <div className="absolute right-2 lg:right-4 xs:right-2">
            <div className="flex justify-center items-center gap-x-6">
              <button
                onClick={() =>
                  handleEventWithCallBackAction(
                    () =>
                      handleEvent({
                        event: "interaction",
                        action_type: "click on page",
                        selection_name: "preview icon",
                        module_type: "product list slide",
                        page_category: "product page",
                      }),
                    () => handlePrev()
                  )
                }
                className="w-12 h-12 pt-3 bg-white border border-black rounded-full flex items-center justify-center z-10 shadow-md"
              >
                <span className="text-4xl leading-none flex items-center justify-center rotate-180">
                  &#8250;
                </span>
              </button>
              <button
                onClick={() =>
                  handleEventWithCallBackAction(
                    () =>
                      handleEvent({
                        event: "interaction",
                        action_type: "click on page",
                        selection_name: "next icon",
                        module_type: "product list slide",
                        page_category: "product page",
                      }),
                    () => handleNext()
                  )
                }
                className="w-12 h-12 text-center bg-white border border-black rounded-full flex items-center justify-center z-10 shadow-md"
              >
                <span className="text-4xl leading-none flex items-center justify-center">
                  &#8250;
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductList;
