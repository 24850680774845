import React from 'react';
import { useParams } from 'react-router-dom';
import GetSmart from '../pages/GetSmart';
import GetMini from '../pages/GetMini';
import GetAdvance from '../pages/GetAdvance';
import GTapp from '../pages/GTapp';
import GetCheckout from '../pages/GetCheckout';
import GetLinkAndPay from '../pages/GetLinkAndPay';
import CentroDePagos from '../pages/CentroDePagos';
import Calculadora from '../pages/Calculadora';
import ContactForm from '../pages/ContactForm';
import SobreGetnet from '../pages/SobreGetnet';
import GetnetSEP from '../pages/GetnetSEP';
import PayPal from '../pages/PayPal';

const ProductPage = () => {
  const { id } = useParams();

  if (id === 'getsmart') return <GetSmart />;
  if (id === 'getmini') return <GetMini />;
  if (id === 'paypal') return <PayPal />;
  if (id === 'getadvance') return <GetAdvance />;
  if (id === 'gtapp') return <GTapp />;
  if (id === 'getcheckout') return <GetCheckout />;
  if (id === 'getlinkandpay') return <GetLinkAndPay />;
  if (id === 'centrodepagos') return <CentroDePagos />;
  //if (id === 'calculadora') return <Calculadora />
  if (id === 'contacto') return <ContactForm />
  if (id === 'sobregetnet') return <SobreGetnet />
  //if (id === 'getnetsep') return <GetnetSEP />

  
  return <h1>Producto no encontrado</h1>;
};

export default ProductPage;
