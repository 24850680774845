import React from "react";

const DistributorsProviders = ({ data }) => {
    if (!data || data.length === 0) {
        return null;
    }

    return (
            <><h1 className="text-5xl xs:text-3xl text-center pt-12 pb-12 max-w-4xl mx-auto block">
            {data[0].titletechincalSpecifications}
        </h1><section className={`bg-[#DEEDF2] py-10`}>
                <div className="mx-auto max-w-7xl">
                    {data[0]?.subtitle && <h2>{data[0].subtitle}</h2>}
                    <div className="grid grid-cols-3 xs:grid-cols-1 max-w-7xl mx-autogap-x-10 xs:my-10 my-20 2xl:p-10">
                        {Array.isArray(data[1]) &&
                            data[1].map(
                                (item, index) => (item.title ||
                                    item.image ||
                                    item.subtitle ||
                                    item.actionTerciary) && (
                                        <div
                                            key={index}
                                            className="flex flex-col items-center text-center gap-y-2"
                                        >
                                            {item.title && <h3 className="pb-2">{item.title}</h3>}
                                            {item.image && (
                                                <div className="w-20">
                                                    <img
                                                        src={item.image}
                                                        alt={item.title || "Specification Image"}
                                                        className="w-full h-full object-contain" />
                                                </div>
                                            )}
                                            {item.subtitle && (
                                                <p className="pt-2 text-lg w-72">{item.subtitle}</p>
                                            )}
                                            {item.actionTerciary && (
                                                <a href={item.link} target="blank">
                                                    <button className="action-terciary">
                                                        {item.actionTerciary}
                                                    </button>
                                                </a>
                                            )}
                                        </div>
                                    )
                            )}
                    </div>
                    <div className="flex justify-center gap-x-4 text-lg">
                        {data[0].text && <p>{data[0].text}</p>}
                        {data[0].actionTerciary && (
                            <p className="action-terciary">
                                <a
                                    target="blank"
                                    className="no-underline text-[#ec0000]"
                                    href={data[0].link}
                                >
                                    {data[0].actionTerciary}
                                </a>
                            </p>
                        )}
                    </div>
                </div>
            </section></>
    );
};

export default DistributorsProviders;
