import React from "react";
import { useParams, Navigate } from 'react-router-dom';
import WelcomeSection from "../components/WelcomeSection";
import ProductsSection from "../components/ProductSectionIsos";
import BannerAbout from "../components/BannerAbout";
import OurPlugins from "../components/OurPlugins";
import data from '../data/OpenMarket.json';

const OpenMarket = () => {
    const { id } = useParams();
    const productData = data.find((product) => product.id === id);

    if (!productData) {
        return <Navigate to="/404" />;
    } 
    
    return (
        <div className="isos">
            <WelcomeSection data={productData.welcome[0]} />
            <ProductsSection data={productData.products[0]} />
            <ProductsSection data={productData.productsDigitals[0]} />
            <BannerAbout data={productData.bannerGlobal[0]}/>
            <OurPlugins data={productData.ourPlugins} />
        </div>
    );
};

export default OpenMarket;