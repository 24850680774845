import React, { useState, useEffect } from "react";
import "../styles/Functionalities.css";

const Functionalities = ({ data, cardStyle }) => {
  const getChunkSize = (width) => {
    if (width <= 768) return 1; // Mobile
    if (width <= 1023) return 2; // lg (pantallas grandes)
    return 3; // Desktop
  };

  const carouselData = data[1];

  const [chunkSize, setChunkSize] = useState(getChunkSize(window.innerWidth));
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setChunkSize(getChunkSize(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const chunkedData = [];
  for (let i = 0; i < carouselData.length; i += chunkSize) {
    chunkedData.push(carouselData.slice(i, i + chunkSize));
  }

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? chunkedData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === chunkedData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const hasMultipleSlides = chunkedData.length > 1;

  return (
    <div className="w-full my-10 xs:my-2 pb-20">
      <div className="flex flex-col text-center  pt-32 xs:px-4">
        <h1 className="text-6xl xs:text-center lg:text-5xl xs:text-3xl">
          {data[0].title.split(/(\bGet\b)/g).map((part, index) =>
            part === 'Get' ? (
              <span key={index} style={{ color: '#EC0000' }}>{part}</span>
            ) : (
              part
            )
          )}
        </h1>
        <p className="text-xl xs:text-start pt-10 xs:pt-4">
          {data[0].textRegular}
        </p>
      </div>

      <section className="mx-auto max-w-7xl mt-10  overflow-hidden xs:mt-2 relative">
        <div
          className="w-full flex transition-transform xl:px-10  xs:px-0 duration-700 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {chunkedData.map((group, groupIndex) => (
            <div
              key={groupIndex}
              className={`w-full flex-shrink-0 grid ${group.length === 2 ? "grid-cols-2" : "grid-cols-3"} lg:grid-cols-2 xs:grid-cols-1 gap-x-10 lg:px-10`}

            >
              {group.map((item, index) => (
                <div
                  key={index}
                  className="grid grid-rows-1 xs:flex xs:flex-col w-full"
                >
                  <div className="grid grid-rows-1 justify-between px-2 bg-white xs:px-4">
                    <div className="flex flex-col h-56 lg:h-64 mt-2">
                      <h3 className="text-4xl max-w-lg xs:text-2xl lg:text-3xl">{item.title}</h3>
                      <p className="text-xl text-[#767676] max-w-lg xs:text-lg lg:text-lg">{item.textRegular}</p>
                      <p className="text-lg text-[#767676] max-w-lg xs:text-sm lg:text-medium">{item.textRegularTwo}</p>
                    </div>
                    <div className="bg-[#DCEBF0] mt-3">
                      <div className="h-96 p-4">
                        {item.img && (
                          <img
                            src={item.img}
                            alt={item.title}
                            className="w-full h-full object-contain"
                          />
                        )}
                      </div>
                    </div>
                  </div>


                </div>
              ))}
            </div>
          ))}
        </div>
      </section>

      {hasMultipleSlides && (
        <div className="absolute right-32 mt-3 lg:right-4 xs:right-2">
          <div className="flex justify-center items-center gap-x-6">
            <button
              onClick={handlePrev}
              className="w-12 h-12 pt-3 bg-white border border-black rounded-full flex items-center justify-center z-10 shadow-md"
            >
              <span className="text-4xl leading-none flex items-center justify-center rotate-180">
                &#8250;
              </span>
            </button>
            <button
              onClick={handleNext}
              className="w-12 h-12 pb-2 text-center bg-white border border-black rounded-full flex items-center justify-center z-10 shadow-md"
            >
              <span className="text-4xl leading-none flex items-center justify-center">
                &#8250;
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Functionalities;
