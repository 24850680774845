import React from "react";
import "../styles/BannerSolution.css";
import useSendEvents from "../hooks/useSendEvents";

const BannerSolution = ({ data }) => {
  const banner = data[0];

  const { handleEvent, handleEventWithCallBackRedirect } = useSendEvents();

  return (
    <div
      className="banner-solution"
      style={{
        backgroundImage: window.innerWidth > 768 && `url(${banner.mainImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: window.innerWidth > 768 ? "816px" : "auto",
      }}
    >
      <div className="banner-content">
        <h2 className="text-7xl xs:text-black xs:text-start xs:text-4xl lg:text-5xl">
          {banner.title}
        </h2>
        <h3 className="pt-10 pb-4 px-2 text-3xl font-normal xs:text-lg xs:pt-2 xs:pb-2 xs:text-start xs:text-gray-500 lg:text-2xl">
          {banner.textRegular}
        </h3>
        <button
          onClick={() =>
            handleEventWithCallBackRedirect(
              () =>
                handleEvent({
                  event: "select_content",
                  action_type: "click on cta",
                  selection_name: "i want a consultancy",
                  module_type: "banner solution",
                  page_category: "product Spage",
                }),
              `${banner.link}?source=${encodeURIComponent(banner.source)}`
            )
          }
          className="action-primary"
        >
          {banner.actionPrimary}
        </button>
      </div>
    </div>
  );
};

export default BannerSolution;
