import React, { useState } from "react";
import useSendEvents from "../hooks/useSendEvents";

const Solutions = ({ data }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleSolution = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const { handleEvent, handleEventWithCallBackAction } = useSendEvents();

  return (
    <section className="mx-auto max-w-7xl lg:px-4 lg:text-center my-52 xs:my-20 2xl:max-w-6xl">
      <h1 className="mt-12 text-center text-5xl xs:text-3xl">{data.title}</h1>
      <p className="text-2xl text-center p-0 xs:text-lg">{data.textRegular}</p>
      <div className="grid grid-cols-2 xs:grid-cols-1 xs:gap-y-4 gap-x-4 pt-20">
        {data.solutionCard.map((solutionCard, index) => {
          const isExpanded = expandedIndex === index;
          return (
            <div
              key={index}
              className="bg-[#DCEBF0] rounded-lg shadow-md relative"
            >
              <div
                className="flex flex-col items-start cursor-pointer"
                onClick={() =>
                  handleEventWithCallBackAction(
                    () =>
                      handleEvent({
                        event: "interaction",
                        action_type: "click to open",
                        selection_name: isExpanded ? "minus icon" : "plus icon",
                        module_type: "card modal",
                        page_category: "sectors page",
                      }),
                    () => toggleSolution(index)
                  )
                }
              >
                <div className="flex flex-col p-4 text-start h-52">
                  <p className="text-[#ec0000] font-semibold text-sm ">
                    {solutionCard.subheading}
                  </p>
                  <h3 className="text-3xl max-w-lg lg:text-2xl mb-10 lg:mb-2">
                    {solutionCard.title}
                  </h3>
                </div>
                {expandedIndex !== index ? (
                  <div className="w-full h-full">
                    <img
                      src={solutionCard.image}
                      alt={solutionCard.title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                ) : (
                  <div
                    className={`overflow-hidden text-start mx-10 flex flex-col gap-y-2 text-2xl lg:text-lg transition-all duration-300 ${
                      isExpanded ? "h-auto" : "max-h-2"
                    }`}
                  >
                    <p className="2xl:text-2xl text-[#444444]">
                      {solutionCard.content}
                    </p>
                    <ul className="list-disc list-inside my-2 2xl:text-xl text-[#444444]">
                      {solutionCard.pOne && <li>{solutionCard.pOne}</li>}
                      {solutionCard.pTwo && <li>{solutionCard.pTwo}</li>}
                      {solutionCard.pThree && <li>{solutionCard.pThree}</li>}
                    </ul>
                    <a
                      href={solutionCard.link}
                      className="mt-10 font-medium pb-4 no-underline text-[#ec0000] cursor-pointer"
                    >
                      {solutionCard.actionTerciary}
                    </a>
                  </div>
                )}
              </div>
              <div
                onClick={() =>
                  handleEventWithCallBackAction(
                    () =>
                      handleEvent({
                        event: "interaction",
                        action_type: "click to open",
                        selection_name: isExpanded ? "minus icon" : "plus icon",
                        module_type: "card modal",
                        page_category: "sectors page",
                      }),
                    () => toggleSolution(index)
                  )
                }
                className={`absolute ${
                  isExpanded ? "right-4 -top-4" : "right-2 -bottom-10"
                }  text-white text-2xl font-bold w-10 h-10 flex items-center justify-center 
                             bg-[#ec0000] rounded-full my-5 cursor-pointer`}
              >
                {isExpanded ? "-" : "+"}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Solutions;
