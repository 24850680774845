import React from "react";
import "../styles/Iframe.css";

const Iframe = ({ data }) => {
  return (
    <div className="iframe-container text-center mx-auto max-w-6xl xs:px-4">
      <h1 className="mt-10 xl:text-4xl xs:text-2xl">{data.title}</h1>
      <p className="mb-2">{data.description}</p>
      <p className="text-lg font-bold text-[#ec0000]">{data.textTwo}</p>
      <div className="hidden grid-cols-2 gap-x-10 my-10 mx-20 xs:mx-0 xs:gap-x-2">
        {data.details.map((item, index) => (
          <div key={index} className="flex flex-col justify-center items-center mx-32 xs:mx-2">
            <p >{item.text}</p>
            <button className="sm:ml-0 bg-[#ec0000] px-8 py-2 xs:px-4 rounded-full transition-colors duration-200 text-[#ffffff] font-semibold mx-auto cursor-pointer hover:bg-white hover:text-[#ec0000] hover:border-[#ec0000] border-2">
              <a href={item.link} className="no-underline text-[#ffffff] hover:text-[#ec0000] ">
                {item.actionTerciary}
              </a>
            </button>
          </div>
        ))}
      </div>
      <iframe
        src={data.src}
        title="G Tapp Onboarding"
        frameBorder="0"
        width="100%"
        height="600"
        allow="geolocation *; camera *"
        allowFullScreen
      ></iframe>
      <p className="text-lg mt-3 text-[#767676]">{data.disclosure}</p>
    </div>
  );
};

export default Iframe;
