import React from "react";
import '../styles/BlogBannerRight.css'

const BlogBannerRight = ({ data }) => {
    return(
        <div className="blog-banner-right">
            <div className="banner-text">
                <h2>{data.title}</h2>
                <p>{data.pOne}</p>
                <p>{data.pTwo}</p>
                <p>{data.pThree}</p>
            </div> 
            <div className="banner-image">
                <img src={data.image} alt={data.title}/>
            </div>
        </div>        
    );
};

export default BlogBannerRight;