import React, { useState, useEffect } from "react";
import useSendEvents from "../hooks/useSendEvents";
import { useLocation, useNavigate } from "react-router-dom";

const CarouselSolutions = ({ data }) => {
  "";
  const isBgWhite = data.bgColor === "white";
  const getChunkSize = (width) => {
    if (width <= 768) return 1; // Mobile
    if (width <= 1023) return 2; // lg (pantallas grandes)
    return 3; // Desktop
  };

  const [chunkSize, setChunkSize] = useState(getChunkSize(window.innerWidth));
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setChunkSize(getChunkSize(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const chunkedData = [];
  for (let i = 0; i < data.solution.length; i += chunkSize) {
    chunkedData.push(data.solution.slice(i, i + chunkSize));
  }

  useEffect(() => {
    if (chunkedData.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === chunkedData.length - 1 ? 0 : prevIndex + 1
        );
      }, 8000);
      return () => clearInterval(interval);
    }
  }, [chunkedData.length]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? chunkedData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === chunkedData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const hasMultipleSlides = chunkedData.length > 1;

  const { handleEvent, handleEventWithCallBackRedirect } = useSendEvents();
  const location = useLocation();
  const navigate = useNavigate();

  const handleDispatch = (href, event) => {
    if (location.pathname.split("/")[2] == "sector") {
      handleEventWithCallBackRedirect(
        () =>
          handleEvent({
            event: "select_content",
            action_type: "click on cta",
            selection_name: "discover more",
            module_type: "product list slide",
            page_category: "sectors page",
            value: event,
          }),
        href
      );
    } else {
      navigate(href);
    }
  };

  return (
    <div className="w-full mt-10">
      <div className="flex flex-col text-center xs:px-4">
        <h1 className="text-5xl xs:text-start">{data.title}</h1>
        <p className="text-xl xs:text-start xs:pt-4">{data.text}</p>
      </div>

      <section className="mx-auto max-w-7xl mt-10 xl:px-6 lg:px-3 xs:px-0 overflow-hidden xs:mt-2 relative">
        <div
          className="w-full flex transition-transform duration-700 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {chunkedData.map((group, groupIndex) => (
            <div
              key={groupIndex}
              className="w-full flex-shrink-0 grid grid-cols-3 mr-6 xs:mr-0 lg:mr-2 lg:grid-cols-2 xs:grid-cols-1 gap-x-24 lg:px-4 lg:gap-x-10"
            >
              {group.map((item, index) => (
                <div
                  key={index}
                  className="grid grid-rows-2 xs:flex xs:flex-col w-full"
                >
                  <div className={isBgWhite ? "bg-white" : "bg-[#DCEBF0]"}>
                    <div className="h-96 px-4 py-10">
                      <a href={item.link}>
                        {" "}
                        {item.image && (
                          <img
                            src={item.image}
                            alt={item.title}
                            className="w-full h-full object-contain"
                          />
                        )}
                      </a>
                    </div>
                  </div>
                  <div className="grid grid-rows-2 justify-between px-4 bg-[#FBFBFB]">
                    <div className="flex flex-col h-48">
                      <div className="flex flex-row gap-x-4 px-10 xl:px-2 py-2 xs:px-2 text-[#23779a]">
                        <p>{item.textOne}</p>
                        <p>{item.textTwo}</p>
                      </div>
                      <h3 className="text-3xl text-[#EC0000]">
                        {item.titleOne}{" "}
                        <span className="text-black">{item.titleTwo}</span>
                      </h3>
                      <h3 className="text-3xl text-black">{item.text}</h3>
                    </div>
                    <div className="flex mb-14 items-end">
                      {item.actionSecondary && (
                        <button
                          onClick={() => handleDispatch(item.link, item.event)}
                          className={"action-secondary"}
                        >
                          {item.actionSecondary}
                        </button>
                      )}
                      {item.actionPrimary && (
                        <button
                          onClick={() =>
                            handleDispatch(
                              `${item.link}?source=${encodeURIComponent(
                                item.source
                              )}`, item.event
                            )
                          }
                          className="action-primary"
                        >
                          {item.actionPrimary}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </section>

      {hasMultipleSlides && (
        <div className="absolute right-32 lg:right-4 xs:right-2">
          <div className="flex justify-center items-center gap-x-6">
            <button
              onClick={handlePrev}
              className="w-12 h-12 pt-3 bg-white border border-black rounded-full flex items-center justify-center z-10 shadow-md"
            >
              <span className="text-4xl leading-none flex items-center justify-center rotate-180">
                &#8250;
              </span>
            </button>
            <button
              onClick={handleNext}
              className="w-12 h-12 pb-2 text-center bg-white border border-black rounded-full flex items-center justify-center z-10 shadow-md"
            >
              <span className="text-4xl leading-none  flex items-center justify-center">
                &#8250;
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CarouselSolutions;
