import React from "react";

const BannerSayYes = ({ data, color }) => {
  const totalImages = data.id === "gtapp" ? 5 : 6;

  return (
    <section className={`mx-auto max-w-7xl text-center lg:m-10 lg:mx-2 xs:m-0 xs:mb-10 flex flex-col justify-center p-12 xs:p-2 2xl:max-w-6xl ${color ? color : "bg-white"}`}>
      <h1 className="text-6xl lg:text-5xl xs:text-4xl xs:mt-10">{data.title}</h1>
      <p className="text-gray-500 mx-20 mt-7 xs:mx-2 text-xl">{data.textRegular}</p>
      <p className="text-gray-500 mx-20 my-2 xs:mx-2 text-xl">{data?.text}</p>
      <div className="flex justify-center gap-2">
        {Array.from({ length: totalImages }, (_, index) => {
          const imageNumber = index + 1;

          if (
            (data.id === "get-checkout" && imageNumber === 5) ||
            ((data.id === "centro-de-pagos" || data.id === "getlinkandpay") && imageNumber === 1)
          ) {
            return null;
          }

          const imagePath =
            data.id === "gtapp"
              ? `/media/images/pay-methods/gtapp/${imageNumber}.png`
              : `/media/images/pay-methods/${imageNumber}.png`;

          return (
            <div
              key={index}
              className="w-24 h-[60px] flex items-center justify-center p-1"
            >
              <img
                src={imagePath}
                className="rounded-[5px] max-w-full max-h-full"
              />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default BannerSayYes;
