import React from "react";
import "../styles/ProductSection.css";

const ProductsSection = ({ data }) => {

  return (
    <section className={`products-section ${data.class}`}>
      <h2 className="products-title">{data.title}</h2>
      <div className="products-container">
        {data.product.map((product, index) => (
          <div key={index} className={`product-card ${product.class}`}>
            <div className="product-content">
              {product.subheading && (
                <h4 className="product-subheading">{product.subheading}</h4>
              )}
              <h3 className="product-title">
                {product.titleOne} <span>{product.titleTwo}</span>
              </h3>
              {product.textBold || product.textRegular && (
                <div>
                  <h3 className="product-bold">{product.textBold}<span className="product-regular">{product.textRegular}</span></h3>                  
                </div>
              )}
            </div>
            <div className="product-image">
              <img src={product.productImg} alt={product.titleOne} />
            </div>
            <div className="product-actions">
              {product.actionSecondary && (
                <button className="action-secondary">
                  {product.actionSecondary}
                </button>
              )}
              {product.actionPrimary && (
                <a href={`${product.link}?source=${encodeURIComponent(product.source)}`}>
                  <button className="action-primary">
                    {product.actionPrimary}
                  </button>
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProductsSection;
