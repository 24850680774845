import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, fireStore, getDocs } from "../utils/firebase/config";
import useSendEvents from "../hooks/useSendEvents";

const BlogArticles = ({ data }) => {
  const [articles, setArticles] = useState(data.articles);
  const [primaryArticles, setPrimaryArticles] = useState(data.primaryArticle);
  const [currentPrimaryIndex, setCurrentPrimaryIndex] = useState(0);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const articlesCollection = collection(fireStore, "blogs");
        const articlesSnapshot = await getDocs(articlesCollection);
        const articlesFromFirebase = articlesSnapshot.docs.map((doc) => {
          const articleData = doc.data();
          const preview = articleData.preview;
          const formattedDate = formatDate(preview.date);

          return {
            id: doc.id,
            link: preview.link,
            image: preview.image,
            title: preview.title,
            date: formattedDate,
          };
        });

        setArticles((prevArticles) => [...prevArticles, ...articlesFromFirebase]);
      } catch (error) {
        console.error("Error al obtener los artículos:", error);
      }
    };

    fetchArticles();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return `Getnet - ${date.toLocaleDateString("es-ES", options)}`;
  };

  const [currentSlice, setCurrentSlice] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const navigate = useNavigate();

  useEffect(() => {
    const updateItemsPerPage = () => {
      setItemsPerPage(window.innerWidth < 640 ? 1 : 3);
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);

  const maxSlice = Math.ceil(articles.length / itemsPerPage);

  const handlePrev = () => {
    if (currentSlice > 0) {
      setCurrentSlice((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentSlice < maxSlice - 1) {
      setCurrentSlice((prev) => prev + 1);
    }
  };

  const handlePrimaryNext = () => {
    setCurrentPrimaryIndex((prevIndex) => (prevIndex + 1) % primaryArticles.length);
  };

  const handlePrimaryPrev = () => {
    setCurrentPrimaryIndex((prevIndex) =>
      prevIndex === 0 ? primaryArticles.length - 1 : prevIndex - 1
    );
  };

  const { handleEvent, handleEventWithCallBackRedirect } = useSendEvents();

  const handleDispatch = (link, title) => {
    handleEventWithCallBackRedirect(
      () =>
        handleEvent({
          event: "select_content",
          action_type: "click on article text",
          selection_name: title,
          module_type: "article section",
          page_category: "blog page",
        }),
      link
    );
  };

  return (
    <div className="mx-auto max-w-7xl text-center relative mb-10">
      <h1>{data.title}</h1>
      <p className="text-xl text-[#767676] xs:text-sm">{data.text}</p>
      <div className="grid grid-cols-2 bg-[#e8eef3] xs:grid-cols-1 gap-4 lg:grid-cols-1 mt-10 xl:mx-10 lg:mx-6 xs:mx-4 xs:max-h-[900px]">
        <div>
          <h3 className="m-4 text-start px-2">{data.subtitle}</h3>
          <div className="grid grid-rows-3 gap-y-5 xs:grid-rows-1">
            {articles
              .slice(currentSlice * itemsPerPage, currentSlice * itemsPerPage + itemsPerPage)
              .map((article, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 xs:grid-cols-1 gap-x-4 gap-y-1 pl-8 xs:p-4 cursor-pointer"
                  onClick={() => handleDispatch(article.link, article.title)}
                >
                  <div className="w-full h-64 overflow-hidden rounded-md">
                    <img src={article.image} className="w-full h-full object-cover" alt="" />
                  </div>
                  <div className="text-start mt-1 ml-3 xs:ml-0">
                    <p className="text-[#262626]">{article.date}</p>
                    <h3 className="text-[#262626] text-xl">{article.title}</h3>
                    <p className="text-[#696868] mt-4">{article.text}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="relative w-full h-auto xs:hidden cursor-pointer">
          <a href={primaryArticles[currentPrimaryIndex].link}>
            <img
              src={primaryArticles[currentPrimaryIndex].image}
              alt=""
              className="w-full h-full object-cover rounded-lg shadow-md"
            />
            <div className="absolute inset-0 flex flex-col justify-end items-start bg-gradient-to-t from-black/50 via-transparent to-transparent p-6">
              <p className="text-white text-xl mb-3 ml-5">
                {primaryArticles[currentPrimaryIndex].date}
              </p>
              <p className="text-white text-5xl mb-4 ml-5 max-w-lg text-start leading-normal">
                {primaryArticles[currentPrimaryIndex].title}
              </p>
            </div>
          </a>
        </div>

      </div>

      <div className="absolute right-4 -bottom-16 flex gap-x-5">
        <button
          onClick={() => {
            handlePrev();
            handlePrimaryPrev();
          }}
          disabled={currentSlice === 0}
          className="w-12 h-12 bg-white border border-black rounded-full flex items-center justify-center shadow-md disabled:opacity-50"
        >
          <span className="text-4xl leading-none">&#8249;</span>
        </button>
        <button
          onClick={() => {
            handleNext();
            handlePrimaryNext();
          }}
          disabled={currentSlice >= maxSlice - 1}
          className="w-12 h-12 bg-white border border-black rounded-full flex items-center justify-center shadow-md disabled:opacity-50"
        >
          <span className="text-4xl leading-none">&#8250;</span>
        </button>
      </div>
    </div>
  );
};

export default BlogArticles;
