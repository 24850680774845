import * as Yup from "yup";

export const template01ValidationSchema = Yup.object().shape({
  preview: Yup.object().shape({
    date: Yup.string().required("La fecha es obligatoria"),
    title: Yup.string().required("El título es obligatorio"),
    text: Yup.string().required("La descripcion es obligatoria"),
    image: Yup.mixed().required("La imagen es obligatoria"),
  }),
  welcomeData: Yup.object().shape({
    title: Yup.string().required("El título es obligatorio"),
    mainImage: Yup.mixed().required("La imagen es obligatoria"),
  }),
  recentData: Yup.object().shape({
    title: Yup.string().required("El título es obligatorio"),
    articles: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("El título es obligatorio"),
        date: Yup.string().required("La fecha es obligatoria"),
        image: Yup.mixed().required("La imagen es obligatoria"),
        link: Yup.string().required("El enlace es obligatorio"),
      })
    ),
  }),
});