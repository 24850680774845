import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../styles/Menu.css";
import useSendEvents from "../hooks/useSendEvents";
import { useLocation } from "react-router-dom";

const menuItems = [
  {
    title: "Pagos en Persona",
    route: "/nuestros-productos/pagosenpersona",
    children: [
      { title: "Get Smart", route: "/nuestros-productos/getsmart" },
      { title: "G Tapp", route: "/nuestros-productos/gtapp" },
      { title: "Get Advance", route: "/nuestros-productos/getadvance" },
      { title: "Get Mini", route: "/nuestros-productos/getmini" },
    ],
  },
  {
    title: "Pagos Digitales",
    route: "/nuestros-productos/pagosdigitales",
    children: [
      { title: "Get Checkout", route: "/nuestros-productos/getcheckout" },
      { title: "Get Link and Pay", route: "/nuestros-productos/getlinkandpay" },
      { title: "PayPal", route: "/nuestros-productos/paypal" },
    ],
  },
];

function Menu() {
  const { handleEvent, handleEventWithCallBackRedirect } = useSendEvents();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 834);
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 834);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [openItem, setOpenItem] = useState(null);

  const toggleAccordion = (item) => {
    setOpenItem(openItem === item ? null : item);
  };

  useEffect(() => {
    setExpanded(false); 
    setOpenItem(null);  
  }, [location.pathname]);
  return (
    <Navbar expanded={expanded} onToggle={setExpanded} expand="lg" className="bg-body border-bottom fixed-top">
      <Container className="px-2 mx-2 xs:x-0 xs:m-0">
        <Navbar.Brand href="/">
          <div className="flex flex-row gap-x-6 w-32 xs:w-14">
            <img
              className="w-full h-full"
              src={
                !isMobile
                  ? "/media/images/home/logo.png"
                  : "/media/images/home/logoG.svg"
              }
              alt="Logo Getnet"
            />
            <div className="border-l-2 border-gray-200 my-2 lg:hidden"></div>
          </div>
        </Navbar.Brand>

        <div className="hidden lg:flex flex-row justify-center items-center gap-x-2">
          <a href={`/nuestros-productos/contacto`} className="cursor-pointer">
            <img
              src="/media/images/header/communication_mail.png"
              alt="contanct"
            />
          </a>
          <div className="w-[1px] h-6 bg-slate-700 opacity-40" />
          <Navbar.Toggle
            aria-controls="navbar-nav"
            className={isMobile ? "mobile-toggle" : ""}
          />
        </div>
        <Navbar.Collapse id="navbar-nav">
          <Nav className="me-auto nav-getnet">
            <div className="nav-item ml-14 lg:ml-0">
              <button
                onClick={() =>
                  handleEventWithCallBackRedirect(
                    () =>
                      handleEvent({
                        event: "select_content",
                        action_type: "click on main menu",
                        selection_name: "who we are",
                        module_type: "header",
                        page_category: "home",
                      }),
                    "/nuestros-productos/sobregetnet"
                  )
                }
                className="nav-link"
              >
                ¿Quiénes Somos?
              </button>
            </div>
            <div className="nav-item dropdown lg:hidden">
              <button
                className="item-menu"
                onClick={() =>
                  handleEventWithCallBackRedirect(
                    () =>
                      handleEvent({
                        event: "select_content",
                        action_type: "click on main menu",
                        selection_name: "payments in person",
                        module_type: "header",
                        page_category: "home",
                      }),
                    "/nuestros-productos/pagosenpersona"
                  )
                }
              >
                <span className="nav-link dropdown-toggle">
                  Pagos en persona
                </span>
              </button>
              <div className="dropdown-menu">
                <button
                  onClick={() =>
                    handleEventWithCallBackRedirect(
                      () =>
                        handleEvent({
                          event: "select_content",
                          action_type: "click on main menu",
                          selection_name: "get smart",
                          module_type: "header",
                          page_category: "home",
                        }),
                      "/nuestros-productos/getsmart"
                    )
                  }
                  className="dropdown-item"
                >
                  Get Smart
                </button>
                <button
                  onClick={() =>
                    handleEventWithCallBackRedirect(
                      () =>
                        handleEvent({
                          event: "select_content",
                          action_type: "click on main menu",
                          selection_name: "g tapp",
                          module_type: "header",
                          page_category: "home",
                        }),
                      "/nuestros-productos/gtapp"
                    )
                  }
                  className="dropdown-item"
                >
                  G Tapp
                </button>
                <button
                  onClick={() =>
                    handleEventWithCallBackRedirect(
                      () =>
                        handleEvent({
                          event: "select_content",
                          action_type: "click on main menu",
                          selection_name: "get advance",
                          module_type: "header",
                          page_category: "home",
                        }),
                      "/nuestros-productos/getadvance"
                    )
                  }
                  className="dropdown-item"
                >
                  Get Advance
                </button>
                <button
                  onClick={() =>
                    handleEventWithCallBackRedirect(
                      () =>
                        handleEvent({
                          event: "select_content",
                          action_type: "click on main menu",
                          selection_name: "get mini",
                          module_type: "header",
                          page_category: "home",
                        }),
                      "/nuestros-productos/getmini"
                    )
                  }
                  className="dropdown-item"
                >
                  Get Mini
                </button>
              </div>
            </div>
            <div className="nav-item dropdown lg:hidden">
              <button
                className="item-menu"
                onClick={() =>
                  handleEventWithCallBackRedirect(
                    () =>
                      handleEvent({
                        event: "select_content",
                        action_type: "click on main menu",
                        selection_name: "digital payments",
                        module_type: "header",
                        page_category: "home",
                      }),
                    "/nuestros-productos/pagosdigitales"
                  )
                }
              >
                <span className="nav-link dropdown-toggle">
                  Pagos digitales
                </span>
              </button>
              <div className="dropdown-menu">
                <button
                  onClick={() =>
                    handleEventWithCallBackRedirect(
                      () =>
                        handleEvent({
                          event: "select_content",
                          action_type: "click on main menu",
                          selection_name: "get checkout",
                          module_type: "header",
                          page_category: "home",
                        }),
                      "/nuestros-productos/getcheckout"
                    )
                  }
                  className="dropdown-item"
                >
                  Get Checkout
                </button>
                <button
                  onClick={() =>
                    handleEventWithCallBackRedirect(
                      () =>
                        handleEvent({
                          event: "select_content",
                          action_type: "click on main menu",
                          selection_name: "get link and pay",
                          module_type: "header",
                          page_category: "home",
                        }),
                      "/nuestros-productos/getlinkandpay"
                    )
                  }
                  className="dropdown-item"
                >
                  Get Link and Pay
                </button>
                <button
                  onClick={() =>
                    handleEventWithCallBackRedirect(
                      () =>
                        handleEvent({
                          event: "select_content",
                          action_type: "click on main menu",
                          selection_name: "paypal",
                          module_type: "header",
                          page_category: "home",
                        }),
                      "/nuestros-productos/paypal"
                    )
                  }
                  className="dropdown-item"
                >
                  PayPal
                </button>
              </div>
            </div>

            {isMobile && (
              <div className="w-full">
                {menuItems.map((item) => (
                  <div key={item.title}>
                    <button
                      className="flex justify-between items-center w-full py-2 text-left transition-colors duration-300"
                      onClick={() => toggleAccordion(item.title)}
                    >
                      {item.title}
                      <span
                        className={`text-[#EC0000] transform transition-transform duration-300 ${
                          openItem === item.title ? "rotate-90" : ""
                        }`}
                      >
                        &#8250;
                      </span>
                    </button>

                    <div
                      className={`overflow-hidden transition-all duration-500 ease-in-out ${
                        openItem === item.title ? "max-h-[1000px]" : "max-h-0"
                      }`}
                    >
                      <div className="px-6">
                        {item.children.map((child) => (
                          <button
                            onClick={() =>
                              handleEventWithCallBackRedirect(
                                () =>
                                  handleEvent({
                                    event: "select_content",
                                    action_type: "click on main menu",
                                    selection_name: child.title,
                                    module_type: "header",
                                    page_category: "home",
                                  }),
                                child.route
                              )
                            }
                            key={child.title}
                            className="block py-2 text-black transition-colors duration-300"
                          >
                            {child.title}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="nav-item">
              <button
                onClick={() =>
                  handleEventWithCallBackRedirect(
                    () =>
                      handleEvent({
                        event: "select_content",
                        action_type: "click on main menu",
                        selection_name: "payment center",
                        module_type: "header",
                        page_category: "home",
                      }),
                    "/nuestros-productos/centrodepagos"
                  )
                }
                className="nav-link"
              >
                Centro de pagos
              </button>
            </div>
            <div className="nav-item">
              <button
                onClick={() =>
                  handleEventWithCallBackRedirect(
                    () =>
                      handleEvent({
                        event: "select_content",
                        action_type: "click on main menu",
                        selection_name: "blog",
                        module_type: "header",
                        page_category: "home",
                      }),
                    "/blog/index"
                  )
                }
                className="nav-link"
              >
                Blog
              </button>
            </div>
          </Nav>
          <button
            onClick={() =>
              handleEventWithCallBackRedirect(
                () =>
                  handleEvent({
                    event: "select_content",
                    action_type: "click on cta",
                    selection_name: "contact us",
                    module_type: "header",
                    page_category: "home",
                  }),
                "/nuestros-productos/contacto?source=Home/Header/Contactanos"
              )
            }
            className="nav-link"
          >
            <button className="btn btn-outline-danger btn-contact-header">
              Contáctanos
            </button>
          </button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Menu;
