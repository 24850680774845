import React from 'react';
import { useParams } from 'react-router-dom';
import Hoteles from '../pages/Hoteles';
import Restaurantes from '../pages/Restaurantes';
import Miscelaneas from '../pages/Miscelaneas';
import Pymes from '../pages/Pymes';
import Medicos from '../pages/Medicos';
import Universidades from '../pages/Universidades';
import Isos from '../pages/Isos';
import IsosTwo from '../pages/IsosTwo';
import OpenMarket from '../pages/OpenMarket';
import Refaccionarias from '../pages/Refaccionarias';

const SectorPage = () => {
  const { id } = useParams();

  if (id === 'hoteles') return <Hoteles />;
  if (id === 'restaurantes') return <Restaurantes />
  if (id === 'miscelaneas') return <Miscelaneas />
  if (id === 'pymes') return <Pymes />
  if (id === 'medicos') return <Medicos />
  if (id === 'universidades') return <Universidades />
  if (id === 'distribuidores-autorizados') return <Isos />
  if (id === 'openmarket') return <OpenMarket />
  if (id === "distribuidores-autorizados-getnet") return <IsosTwo />
  if (id === "refaccionarias-ferreterías")  return <Refaccionarias/>
  
  return <h1>Producto no encontrado</h1>;
};

export default SectorPage;
