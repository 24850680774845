import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import BlogWelcome from '../components/BlogWelcome';
import BlogBannerRight from '../components/BlogBannerRight';
import BlogSectionArticle from '../components/BlogSectionArticle';
import BlogRecent from '../components/BlogRecent';
import data from '../data/BlogArticle03.json';

const BlogArticle03 = () => {

    const { id } = useParams();
    const productData = data.find((product) => product.id === id);

    // if (!productData) {
    //     return <Navigate to="/404" />;
    // }

    return (
        <div className={`${productData.id}-page product-page`}>
            <BlogWelcome data={productData.general[0]} />
            <BlogBannerRight data={productData.bannerOne[0]}/>
            <BlogSectionArticle data={productData.sectionOne[0]} />
            <BlogSectionArticle data={productData.sectionTwo[0]} />
            <BlogSectionArticle data={productData.sectionThree[0]} />
            <BlogSectionArticle data={productData.sectionFour[0]} />
            <BlogSectionArticle data={productData.sectionFive[0]} />
            <BlogRecent data={productData.recentArticles[0]} />
        </div>
    );
};

export default BlogArticle03;