import React from "react";
import useSendEvents from "../hooks/useSendEvents";
import { useLocation, useNavigate } from "react-router-dom";

const BarPayments = ({ data }) => {


  const {
    handleEvent,
    handleEventWithCallBackRedirect,
  } = useSendEvents();

  const navigate = useNavigate();
  const location = useLocation();

  const handleDispatch = (link) => {
    if(link){

      if(location.pathname == '/nuestros-productos/pagosenpersona'){
        handleEventWithCallBackRedirect(
          () =>
            handleEvent({
              event: "select_content",
              action_type: "access to shortcuts",
              selection_name: "in store",
              module_type: "shortcuts",
              page_category: "product page",
            }),
          link
        );
      } else {
        navigate(link)
      }

    }
  }

  return (
    <div>
      <div className="flex items-center flex-wrap gap-x-24 justify-center m-4 xs:gap-x-6 pt-24">
        {data.map((item, index) => (
          <div
            className={`flex flex-col justify-center items-center ${
              item.link && "cursor-pointer"
            }`}
            key={index}
            onClick={() => handleDispatch(item.link)}
          >
            <div className="h-20">
              <img
                className="rounded-lg border-2 border-gray-300 p-3 shadow-md"
                src={item.image}
                alt="w-full h-full"
              />
            </div>
            <p className="mt-2 text-mda text-center">{item.text}</p>
          </div>
        ))}
      </div>
      <div className="w-full border-b border-gray-300 mb-20" />
    </div>
  );
};

export default BarPayments;
