import React from "react";

const Welcome = ({ data }) => {
  return (
    <section className="mx-32 grid grid-cols-2 mt-20 lg:mx-2 pt-4 lg:grid-cols-1 xs:grid-cols-1 xs:mx-2 xs:pt-10 2xl:pt-6 2xl:mx-0">
      <div className="mx-20 lg:mx-2 xs:mx-2 flex flex-col gap-y-1  justify-center">
        <p className="text-[#ec0000] text-lg">{data.welcomeSubheader.toUpperCase()}</p>
        <h1 className="text-5xl mr-32 lg:mr-10 xs:mr-2 xs:text-4xl 2xl:text-4xl">{data.welcomeTitleOne}</h1>
        <p className="text-xl text-[#444444] xs:text-lg 2xl:text-xl">{data.welcomeText}</p>
      </div>
      <div className="lg:mt-4 2xl:pr-16">
        <img src={data.welcomeImg} alt={data.welcomeTitleOne} />
      </div>
    </section>
  );
};

export default Welcome;
