import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams, Navigate } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Welcome from '../components/Welcome';
import Details from '../components/Details';
import TechnicalSpecifications from '../components/TechnicalSpecifications';
import Functionalities from '../components/Functionalities';
import BannerSayYes from '../components/BannerSayYes';
import Sectors from '../components/Sectors';
import FAQ from '../components/faq';
import CarouselSolutions from '../components/CarouselSolutions';
import data from '../data/GetSmart.json';

const GetSmart = () => {
  const { id } = useParams(); 
  const productData = data.find((product) => product.id === id);

  useEffect(() => {
              if (productData) {
                  document.title = `La Terminal más completa para cobrar con tarjeta | Getnet 
`;
              } else {
                  document.title = "Página no encontrada";
              }
          }, [productData]);

  if (!productData) {
    // Redirigir si el `id` no existe en el JSON
    return <Navigate to="/404" />;
  }

  return (
    <div className={`${productData.id}-page product-page`}>
      <Welcome data={productData.welcome[0]} />
      <Details details={productData.details} />
      <TechnicalSpecifications data={productData.technicalSpecifications} />
      <Functionalities data={productData.functionalities} />
      <BannerSayYes data={productData.bannerSayYes[0]} />
      <Sectors data={productData.sectors[0]} bg={true} />
      <FAQ data={productData.faq[0]} />
      <CarouselSolutions data={productData.carosuelSolutions[0]} />
    </div>
  );
};

export default GetSmart;

