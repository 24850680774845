import React, { useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import BannerImgBgW from "../components/BannerImgBgW";
import TechnicalSpecifications from "../components/TechnicalSpecifications";
import TestimonialsCarousel from "../components/TestimonialsBanner";
import Faq from "../components/faq";
import ContactForm from "../pages/ContactForm";
import data from "../data/IsosTwo.json";

const IsosTwo = () => {
    const { id } = useParams();
    const productData = data.find((product) => product.id === id);

    useEffect(() => {
        if (productData) {
            document.title = `Encuentra distribuidores autorizados de Getnet`;
        } else {
            document.title = "Página no encontrada";
        }
    }, [productData]);

    if (!productData) {
        return <Navigate to="/404" />;
    }

    return (
        <div className="isostwo">
            <BannerImgBgW data={productData.welcome[0]} />
            <TechnicalSpecifications data={productData.technicalSpecifications} />
            <TechnicalSpecifications data={productData.technicalSpecificationsTwo} />
            <TestimonialsCarousel data={productData.testimonials} />
            <Faq data={productData.faq[0]} />
            <ContactForm />
        </div>
    );
};

export default IsosTwo;
