import React, { useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import Welcome from '../components/Welcome';
import Details from '../components/Details';
import BannerSayYes from '../components/BannerSayYes';
import Modalities from '../components/Modalities';
import Sectors from '../components/Sectors';
import FAQ from '../components/faq';
import CarouselSolutions from '../components/CarouselSolutions';
import data from '../data/CentroDePagos.json';
import TechnicalSpecificationsCheckout from '../components/TechnicalSpecificationsCheckout';

const CentroDePagos = () => {
    const { id } = useParams();
    const productData = data.find((product) => product.id === id);

    useEffect(() => {
            if (productData) {
                document.title = `Centro de pago para la gestión integral de tus puntos de venta | Getnet`;
            } else {
                document.title = "Página no encontrada";
            }
        }, [productData]);

    if (!productData) {
    return <Navigate to="/404" />;
    }

    return (
        <div className={`${productData.id}-page product-page`}>
            <Welcome data={productData.welcome[0]} />
            <Details details={productData.details} checkout={true} />
            <TechnicalSpecificationsCheckout data={productData.technicalSpecifications} />
            <BannerSayYes data={productData.bannerSayYes[0]} />
            <Modalities data={productData.modalities} />
            <Sectors data={productData.sectors[0]} />
            <FAQ data={productData.faq[0]} />
            <CarouselSolutions data={productData.carosuelSolutions[0]} />
        </div>
    );
};

export default CentroDePagos;