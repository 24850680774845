import React from "react";

const ReferralProgram = ({ data }) => {
    return (
        <section className="text-center mx-auto block pb-10 bg-[#DEEDF2]">
            <h2 className="text-5xl xs:text-2xl 2xl:text-4xl">{data.title}</h2>
            <h3 className="text-5xl mt-10 xs:text-2xl 2xl:text-4xl">{data.text}</h3>
            <ul className="pt-10 text-xl max-w-5xl mx-auto block space-y-4 xs:pr-10">
                {data.options.map((item, index) => (
                    <li key={index}>
                        <strong>{item.oneBold || item.twoBold || item.threeBold}</strong> - {item.oneRegular || item.twoRegular || item.threeRegular}
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default ReferralProgram;