import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import BlogWelcomeDynamic from "../dynamic/BlogWelcomeDynamic";
import BlogBannerRightDynamic from "../dynamic/BlogBannerRightDynamic";
import BlogSectionArticleDynamic from "../dynamic/BlogSectionArticleDynamic";
import { Button } from "@nextui-org/react";
import BlogRecentDynamic from "../dynamic/BlogRecentDynamic";
import { template01ValidationSchema } from "../utils/validationSchema/Template01Schema";
import { template01InitialValues } from "../utils/initialValues/Template01InitialValues";
import { Save } from "lucide-react";
import { addDoc, collection, fireStore } from "../../../utils/firebase/config";
import { uploadFileToStorage } from "../../../services/uploadFile";
import ImageUploader from "../../../components/dashboard/blog/ImageUploader";

const Template01 = ({ data, isEditing }) => {
  const [loading, setLoading] = useState(false);

  const handleImageUpload = async (file) => {
    if (!file) return;

    const url = await uploadFileToStorage(file);
    if (url) {
      return url;
    } else {
      alert("Error al subir la imagen. Inténtalo de nuevo.");
    }
  };

  const processImageFields = async (values) => {
    const newValues = { ...values };
    if (values.preview.image instanceof File) {
      newValues.preview.image = await handleImageUpload(values.preview.image);
    }

    if (values.welcomeData.mainImage instanceof File) {
      newValues.welcomeData.mainImage = await handleImageUpload(
        values.welcomeData.mainImage
      );
    }

    newValues.bannerData = await Promise.all(
      values.bannerData.map(async (banner) => ({
        ...banner,
        image:
          banner.image instanceof File
            ? await handleImageUpload(banner.image)
            : banner.image,
      }))
    );

    newValues.sectionData = await Promise.all(
      values.sectionData.map(async (section) => ({
        ...section,
        image:
          section.image instanceof File
            ? await handleImageUpload(section.image)
            : section.image,
      }))
    );

    return newValues;
  };

  return (
    <div>
      {isEditing ? (
        <Formik
          initialValues={template01InitialValues}
          validationSchema={template01ValidationSchema}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);
            const processedValues = await processImageFields(values);
            const blogData = {
              template: 1,
              preview: processedValues.preview,
              data: {
                welcomeData: processedValues.welcomeData,
                bannerData: processedValues.bannerData,
                sectionData: processedValues.sectionData,
                recentData: processedValues.recentData,
              },
            };
            try {
              const docRef = await addDoc(
                collection(fireStore, "blogs"),
                blogData
              );
              console.log("Blog creado con ID:", docRef.id);
              resetForm();
            } catch (error) {
              console.error("Error al guardar el blog:", error);
            } finally {
              setLoading(false);
            }
          }}
        >
          {({ setFieldValue, values }) => (
            <Form className="p-6 bg-gray-50 rounded-lg shadow-md space-y-6">
              <div className="grid grid-cols-2 gap-x-2">
                <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
                  <h2 className="text-xl font-bold mb-4 text-gray-800">
                    Vista Previa
                  </h2>
                  <Field
                    id="preview.title"
                    name="preview.title"
                    placeholder="Ingrese el título de la vista previa"
                    className="w-full border border-gray-300 p-2 rounded-md"
                    onChange={(e) => {
                      setFieldValue("preview.title", e.target.value);
                      setFieldValue(
                        "preview.link",
                        `/blog/${e.target.value
                          .replace(/\s+/g, "-")
                          .toLowerCase()}`
                      );
                    }}
                  />
                  <ErrorMessage
                    name="preview.title"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                  <Field
                    id="preview.text"
                    name="preview.text"
                    placeholder="Ingrese la descrpicion de la vista previa"
                    className="w-full border border-gray-300 p-2 mt-2 rounded-md"
                  />
                  <ErrorMessage
                    name="preview.text"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                  <Field
                    id="preview.date"
                    name="preview.date"
                    type="date"
                    className="w-full border border-gray-300 p-2 mt-2 rounded-md"
                  />
                  <ErrorMessage
                    name="preview.date"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                  <ImageUploader
                    id="preview.image"
                    name="preview.image"
                    label="Imagen"
                    setFieldValue={setFieldValue}
                    fieldName="preview.image"
                    urlFieldName="preview.imageUrl"
                    imageUrl={values.preview.imageUrl}
                  />
                  <ErrorMessage
                    name="preview.image"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                  <Field
                    id="preview.link"
                    name="preview.link"
                    className="w-full border border-gray-300 p-2 rounded-md bg-gray-50"
                    readOnly
                  />
                </div>

                <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
                  <h2 className="text-xl font-bold mb-4 text-gray-800">
                    Datos de Bienvenida
                  </h2>
                  <Field
                    id="welcomeData.title"
                    name="welcomeData.title"
                    placeholder="Ingrese el título de bienvenida"
                    className="w-full border border-gray-300 p-2 rounded-md"
                  />
                  <ErrorMessage
                    name="welcomeData.title"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />

                  <ImageUploader
                    id="welcomeData.mainImage"
                    name="welcomeData.mainImage"
                    label="Imagen Principal"
                    setFieldValue={setFieldValue}
                    fieldName="welcomeData.mainImage"
                    urlFieldName="welcomeData.mainImageUrl"
                    imageUrl={values.welcomeData.mainImageUrl}
                  />
                  <ErrorMessage
                    name="welcomeData.mainImage"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>

              <div className="grid grid-cols-3 gap-2">
                {values.bannerData.map((banner, index) => (
                  <div
                    key={index}
                    className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm"
                  >
                    <h2 className="text-xl font-bold mb-4 text-gray-800">
                      Banner {index + 1}
                    </h2>
                    <Field
                      name={`bannerData[${index}].title`}
                      placeholder="Ingrese el título del banner"
                      className="w-full border border-gray-300 p-2 rounded-md"
                    />
                    <ErrorMessage
                      name={`bannerData[${index}].title`}
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    <Field
                      name={`bannerData[${index}].pOne`}
                      placeholder="Ingrese el primer párrafo"
                      className="w-full border border-gray-300 p-2 rounded-md mt-2"
                    />
                    <ErrorMessage
                      name={`bannerData[${index}].pOne`}
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    <Field
                      name={`bannerData[${index}].pTwo`}
                      placeholder="Ingrese el segundo párrafo"
                      className="w-full border border-gray-300 p-2 rounded-md mt-2"
                    />
                    <ErrorMessage
                      name={`bannerData[${index}].pTwo`}
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    <Field
                      name={`bannerData[${index}].pThree`}
                      placeholder="Ingrese el tercer párrafo"
                      className="w-full border border-gray-300 p-2 rounded-md mt-2"
                    />
                    <ErrorMessage
                      name={`bannerData[${index}].pThree`}
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    <ImageUploader
                      id={`bannerData[${index}].image`}
                      name={`bannerData[${index}].image`}
                      label="Imagen"
                      setFieldValue={setFieldValue}
                      fieldName={`bannerData[${index}].image`}
                      urlFieldName={`bannerData[${index}].imageUrl`}
                      imageUrl={values.bannerData[index].imageUrl}
                    />
                    <ErrorMessage
                      name={`bannerData[${index}].image`}
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                ))}
              </div>

              <div className="grid grid-cols-2 gap-4">
                {values.sectionData.map((section, index) => (
                  <div
                    key={index}
                    className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm"
                  >
                    <h2 className="text-xl font-bold mb-4 text-gray-800">
                      Sección de Artículo {index + 1}
                    </h2>
                    <Field
                      name={`sectionData[${index}].title`}
                      placeholder="Ingrese el título de la sección"
                      className="w-full border border-gray-300 p-2 rounded-md"
                    />
                    <ErrorMessage
                      name={`sectionData[${index}].title`}
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    <Field
                      name={`sectionData[${index}].pOneB`}
                      placeholder="Ingrese el primer párrafo (negrita)"
                      className="w-full border border-gray-300 p-2 rounded-md mt-2"
                    />
                    <ErrorMessage
                      name={`sectionData[${index}].pOneB`}
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    <Field
                      name={`sectionData[${index}].pOne`}
                      placeholder="Ingrese el primer párrafo"
                      className="w-full border border-gray-300 p-2 rounded-md mt-2"
                    />
                    <ErrorMessage
                      name={`sectionData[${index}].pOne`}
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    <Field
                      name={`sectionData[${index}].pTwoB`}
                      placeholder="Ingrese el segundo párrafo (negrita)"
                      className="w-full border border-gray-300 p-2 rounded-md mt-2"
                    />
                    <ErrorMessage
                      name={`sectionData[${index}].pTwoB`}
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    <Field
                      name={`sectionData[${index}].pTwo`}
                      placeholder="Ingrese el segundo párrafo"
                      className="w-full border border-gray-300 p-2 rounded-md mt-2"
                    />
                    <ErrorMessage
                      name={`sectionData[${index}].pTwo`}
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    <Field
                      name={`sectionData[${index}].pThreeB`}
                      placeholder="Ingrese el tercer párrafo (negrita)"
                      className="w-full border border-gray-300 p-2 rounded-md mt-2"
                    />
                    <ErrorMessage
                      name={`sectionData[${index}].pThreeB`}
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    <Field
                      name={`sectionData[${index}].pThree`}
                      placeholder="Ingrese el tercer párrafo"
                      className="w-full border border-gray-300 p-2 rounded-md mt-2"
                    />
                    <ErrorMessage
                      name={`sectionData[${index}].pThree`}
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    <ImageUploader
                      id={`sectionData[${index}].image`}
                      name={`sectionData[${index}].image`}
                      label="Imagen"
                      setFieldValue={setFieldValue}
                      fieldName={`sectionData[${index}].image`}
                      urlFieldName={`sectionData[${index}].imageUrl`}
                      imageUrl={values.sectionData[index].imageUrl}
                    />
                    <ErrorMessage
                      name={`sectionData[${index}].image`}
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                ))}
              </div>

              <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
                <h2 className="text-xl font-bold mb-4 text-gray-800">
                  Artículos Recientes
                </h2>
                <Field
                  id="recentData.title"
                  name="recentData.title"
                  placeholder="Ingrese el título de los artículos recientes"
                  className="w-full border border-gray-300 p-2 rounded-md"
                />
                <ErrorMessage
                  name="recentData.title"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="flex justify-end">
                <Button
                  color="primary"
                  disabled={loading}
                  className="text-white flex flex-row gap-x-2 rounded-xl"
                  type="submit"
                >
                  <Save className="w-4 h-4 mr-2" />{" "}
                  {loading ? "Cargando.." : "Guardar Todo"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          {data && (
            <>
              <BlogWelcomeDynamic data={data.welcomeData} />
              <BlogBannerRightDynamic data={data.bannerData[0]} />
              <BlogSectionArticleDynamic data={data.sectionData[0]} />
              <BlogSectionArticleDynamic data={data.sectionData[1]} />
              <BlogBannerRightDynamic data={data.bannerData[1]} />
              <BlogSectionArticleDynamic data={data.sectionData[2]} />
              <BlogBannerRightDynamic data={data.bannerData[2]} />
              <BlogSectionArticleDynamic data={data.sectionData[3]} />
              <BlogRecentDynamic data={data.recentData} />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Template01;
