import React from "react";
import data from "../data/Home.json";
import "../styles/ProductSection.css";
import useSendEvents from "../hooks/useSendEvents";

const ProductsSection = () => {
  const products = data[0]?.products[0]?.product;
  const isMobile = window.innerWidth <= 768;

  const { handleEvent, handleEventWithCallBackRedirect } = useSendEvents();

  return (
    <section className="m-2 xs:m-4 pb-32 xs:pb-6">
      <h2 className="text-center text-5xl xs:text-3xl xs:pt-10 lg:text-4xl">
        Productos que te pueden interesar.
      </h2>
      <div className="grid grid-cols-2 xs:grid-cols-1 2xl:mx-20 xl:mx-2 xs:mx-0 mt-14">
        {products.map((product, index) => (
          <div
            key={index}
            className={`${isMobile ? " principal-product" : product.class} ${index > 0 && "mt-5"
              }  ${index > 0 && !isMobile && "mx-40 lg:mx-2"} `}
          >
            <div className={`${index === 0 && "max-w-6xl lg:mx-20"} xs:mx-0`}>
              {product.subheading && (
                <h4
                  className={`${index > 0 || (index === 0 && isMobile)
                    ? "text-left text-[#ec0000] text-lg"
                    : "product-subheading"
                    }`}
                >
                  {product.subheading.toUpperCase()}
                </h4>
              )}
              <h3
                className={`${index > 0 || (index === 0 && isMobile)
                  ? "text-start"
                  : "text-center"
                  } text-[#EC0000]`}
              >
                {product.titleOne}{" "}
                <span className="text-black">{product.titleTwo}</span>
              </h3>
              <div
                className={`${index > 0 || (index === 0 && isMobile)
                  ? "text-start"
                  : "text-center"
                  }`}
              >
                <h3 className="text-black font-bold text-xl">
                  {product?.textBold}
                  <span className="product-regular">
                    {product?.textRegular}
                  </span>
                </h3>
              </div>
            </div>
            <div
              className={`flex ${index === 0 ? "flex-col" : "flex-col-reverse"
                } xs:flex-col gap-y-2`}
            >
              <div
                className={`flex mx-auto ${index > 0 && "h-72 mt-4"} ${index == 2 && "py-14"
                  }`}
              >
                <a href={product.link} className="mx-auto">
                  <img
                    src={product.productImg}
                    alt={product.titleOne}
                    className="w-full h-full object-contain"
                  />
                </a>
              </div>
              <div
                className={`flex flex-row  gap-x-2 ${index === 0
                  ? "justify-center items-center"
                  : "justify-start items-start mt-4"
                  } ${index > 0 && "xs:justify-center xs:items-start xs:pr-40"
                  }  gap-y-10`}
              >
                {product.actionSecondary && (
                  <button
                    onClick={() =>
                      handleEventWithCallBackRedirect(
                        () =>
                          handleEvent({
                            event: "select_content",
                            action_type: "click on cta",
                            selection_name: "know more",
                            module_type: "product of interest",
                            page_category: "home",
                          }),
                        product.link
                      )
                    }
                  >
                    <button className={`action-secondary`}>
                      {product.actionSecondary}
                    </button>
                  </button>
                )}
                {product.actionPrimary && (
                  <button
                    onClick={() =>
                      handleEventWithCallBackRedirect(
                        () =>
                          handleEvent({
                            event: "select_content",
                            action_type: "click on cta",
                            selection_name: "know more",
                            module_type: "product of interest",
                            page_category: "home",
                          }),
                        `${product.linkTwo}?source=${encodeURIComponent(
                            product.sourceContact
                        )}`
                      )
                    }
                  >
                    <button className="action-primary px-4 py-2">
                      {product.actionPrimary}
                    </button>
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProductsSection;
