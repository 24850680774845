import React from "react";

const DistributorsValues = ({ data }) => {
    if (!data || data.length === 0) {
        return null;
    }

    return (
        <section className={`bg-white py-10 xs:py-20 2xl:p-14`}>
            <div className="mx-auto max-w-7xl">
                {data[0]?.titletechincalSpecifications && (
                    <h1 className="text-5xl mb-10 xs:text-3xl text-center xs:mb-0">
                        {data[0].titletechincalSpecifications}
                    </h1>
                )}
                {data[0]?.subtitle && <h2 className="text-center text-4xl text-[#444444] max-w-5xl mx-auto xs:text-xl xs:p-6">{data[0].subtitle}</h2>}

                <div className="grid grid-cols-4 xs:grid-cols-1 max-w-7xl mx-auto gap-x-10 xs:my-10 my-20">
                    {Array.isArray(data[1]) &&
                        data[1].map(
                            (item, index) =>
                                (item.title ||
                                    item.image ||
                                    item.subtitle ||
                                    item.actionTerciary) && (
                                    <div
                                        key={index}
                                        className="flex flex-col items-start text-start gap-y-2 xs:items-center xs:text-center"
                                    >
                                        {item.image && (
                                            <div className="w-20">
                                                <img
                                                    src={item.image}
                                                    alt={item.title || "Specification Image"}
                                                    className="w-full h-full object-contain"
                                                />
                                            </div>
                                        )}
                                        {item.title && <h3 className="text-start">{item.title}</h3>}
                                        {item.subtitle && (
                                            <p className="pt-2 text-lg w-72 text-[#767676]">{item.subtitle}</p>
                                        )}
                                        {item.actionTerciary && (
                                            <a href={item.link} target="blank">
                                                <button className="action-terciary">
                                                    {item.actionTerciary}
                                                </button>
                                            </a>
                                        )}
                                    </div>
                                )
                        )}
                </div>
                <div className="flex justify-center gap-x-4 text-lg">
                    {data[0].text && <p>{data[0].text}</p>}
                    {data[0].actionTerciary && (
                        <p className="action-terciary">
                            <a
                                target="blank"
                                className="no-underline text-[#ec0000]"
                                href={data[0].link}
                            >
                                {data[0].actionTerciary}
                            </a>
                        </p>
                    )}
                </div>
            </div>
        </section>
    );
};

export default DistributorsValues;
