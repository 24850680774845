import React from "react";
const Advantages = ({ data }) => {

  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <div className="text-center mb-16">
        <h1 className="text-6xl text-gray-900 mb-6 xs:text-4xl">
          {data.title}
        </h1>
        <p className="text-xl mx-auto">
          {data.pOne}
        </p>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-8 mb-16 xs:grid-cols-1">
        {data.features.map((feature, index) => (
          <div
            key={index}
            className="bg-white rounded-xl shadow-lg p-6"
          >
            <div className="flex flex-col space-y-4">
              <div className="w-10 h-10 relative">
                <img
                  src={feature.featureImage}
                  alt={feature.featureTitle}
                  layout="fill"
                  objectFit="contain"
                />
              </div>
              <h3 className="text-xl text-gray-800">
                {feature.featureTitle}
              </h3>
              <p className="mt-10">{feature.featureText}</p>
            </div>
          </div>
        ))}
      </div>

      {data.advantage.map((item, index) => (
        <div
          key={index}
          className="flex items-start mb-12 xs:flex-col xs:mb-0 lg:items-center"
        >
          <div className="w-full h-full">
            <img
              src={item.mainImage}
              alt={item.title}
              className="object-cover w-full h-full"
            />
          </div>

          <div className="mt-20 ml-40 w-[80%] flex flex-col justify-center xs: w-100 xs:ml-0 lg:ml-10 lg:mt-10">
            <h2 className="text-5xl mb-10 xs:text-4xl xs:text-center">{item.title}</h2>
            <p className="text-lg leading-relaxed xs:text-center">
              {item.textRegular}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Advantages;