import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MainRouter from "./router/MainRouter";
import { NextUIProvider } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  return (
    <NextUIProvider navigate={navigate}>
      <MainRouter />
    </NextUIProvider>
  );
}

export default App;
