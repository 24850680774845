import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import emailjs from "emailjs-com";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import '../styles/ContactFormIsos.css';

function ContactFormIsos() {
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const currentLocation = useLocation(); 
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    company: "",
    rfc: "",
    website:"",
    segment:"",
    product: "",
    establishments: "",
    definition: "",
    clasification: "",
    interest: "",
    to_email: "roberto.gaytan@nexoit.com.mx,soporte_magento@microformas.com.mx",
    source: ""
  });

  useEffect(() => {
    const params = new URLSearchParams(currentLocation.search); 
    const source = params.get("source") || "ISOSGPOSolutions/Contacto/Isos";
    setFormData((prevData) => ({ ...prevData, source }));
  }, [currentLocation])

  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    let error = "";

    if (name === "fullName" && (!value || value.length < 3)) {
      error = "El nombre completo debe tener al menos 3 caracteres.";
    }

    if (name === "email" && (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value))) {
      error = "Por favor, ingresa un correo electrónico válido.";
    }

    if (name === "phone" && (!/^\d{10}$/.test(value))) {
      error = "El teléfono debe tener exactamente 10 dígitos.";
    }

    if (name === "rfc" && (!/^[A-ZÑ&]{3,4}\d{6}[A-Z\d]{3}$/.test(value))) {
      error = "Ingresa un RFC válido (Ejemplo: ABCD123456XYZ).";
    }
    if (name === "company" && (!value || value.length < 3)) {
      error = "El nombre de la empresa debe tener al menos 3 caracteres.";
    }

    if (name === "to_email" && (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value))) {
      error = "Ingresa un correo electrónico válido para el destinatario.";
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) newErrors[key] = error;
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; 
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const SERVICE_ID = "service_vpxsk5w";
    const TEMPLATE_ID = "template_lcwp2xa";
    const USER_ID = "P7JKzjomBJdoKohll";

    if (!validateForm()) {
        alert("Por favor, corrige los errores antes de enviar.");
        return;
      }

    emailjs
      .send(SERVICE_ID, TEMPLATE_ID, formData, USER_ID)
      .then(
        (response) => {
          alert("¡Formulario enviado exitosamente!");
          setFormData({
            fullName: "",
            email: "",
            phone: "",
            company: "",
            rfc: "",
            website:"",
            segment:"",
            product: "",
            establishments: "",
            definition: "",
            clasification: "",
            interest: "",
            to_email: "roberto.gaytan@nexoit.com.mx,soporte_magento@microformas.com.mx",
            source: ""
          }); 
        },
        (error) => {
          console.error("Error al enviar el mensaje:", error);
          alert("Hubo un problema al enviar el formulario.");
        }
      );
  };

  return (
        <Form onSubmit={handleSubmit} className="contact-form isos">
            <h1 className="source-text">Formulario desde: {formData.source}</h1>
            <h1>¿Te gustaría hablar con uno de nuestros expertos?</h1>
            <p>Compártenos tus datos y nos pondremos en contacto contigo.</p>
            <Row className="mb-3"> 
              <Form.Group as={Col} className="mb-6" controlId="formFullName">
                <Form.Control
                  type="text"
                  placeholder="Nombre completo"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  onBlur={handleChange}
                  isInvalid={!!errors.fullName}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-6" controlId="formEmail">
                <Form.Control
                type="email"
                placeholder="Correo electrónico comercial"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3"> 
              <Form.Group as={Col} className="mb-6" controlId="formPhone">
                <Form.Control
                type="tel"
                placeholder="Teléfono móvil"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                onBlur={handleChange}
                isInvalid={!!errors.phone}
                required
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-6" controlId="formCompany">
                <Form.Control
                  type="text"
                  placeholder="Nombre de la empresa"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  onBlur={handleChange}
                  isInvalid={!!errors.company}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3"> 
              <Form.Group as={Col} className="mb-6" controlId="formRFC">
                <Form.Control
                    type="text"
                    placeholder="Ingresa tu RFC"
                    name="rfc"
                    value={formData.rfc}
                    onChange={handleChange}
                    onBlur={handleChange}
                    isInvalid={!!errors.rfc}
                    required
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-6" controlId="formWebsite">
                <Form.Control
                  type="text"
                  placeholder="Sitio web de la empresa"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                  onBlur={handleChange}
                  required
                />
              </Form.Group>
            </Row>
            <Form.Group className="mb-3" controlId="formSegment">
              <Form.Control
                type="text"
                placeholder="¿Cuál es su principal segmento de actuación?"
                name="segment"
                value={formData.segment}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formProduct">
                <Form.Control
                as="textarea"
                rows={3}
                placeholder="Describe brevemente su solución o producto"
                name="product"
                value={formData.product}
                onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEstablishments">
              <Form.Control
                type="text"
                placeholder="¿Cuántos establecimientos se atienden con tu solución?"
                name="establishments"
                value={formData.establishments}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDefinition">
              <Form.Control
                type="text"
                placeholder="¿Cómo definirías hoy tu negocio?"
                name="definition"
                value={formData.definition}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formClasification">
              <Form.Control
                type="text"
                placeholder="¿Cómo clasifica su modelo de negocio?"
                name="clasification"
                value={formData.clasification}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formInterest">
              <Form.Select
                  name="interest"
                  value={formData.interest}
                  onChange={handleChange}
                  required
              >
                  <option value="">Estás interesado en :</option>
                  <option value="Ser Distribuidor Autorizado">Ser Distribuidor Autorizado</option>
                  <option value="Unirse al Programa de Referidos">Unirse al Programa de Referidos</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPrivacy">
              <Form.Check
                type="checkbox"
                label={
                  <span>
                    AVISO DE PRIVACIDAD: Acepto y conozco los{" "}
                    <a
                      href="/media/documents/Terminos-y-Condiciones.pdf" 
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Términos de uso
                    </a>{" "}
                    y{" "}
                    <a
                      href="/media/documents/Aviso2025.pdf" 
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Aviso y Política de Privacidad
                    </a>
                    .
                  </span>
                }
                checked={privacyAccepted}
                onChange={(e) => setPrivacyAccepted(e.target.checked)}
                isInvalid={!!errors.privacy}
                feedback={errors.privacy}
                required
              />
          </Form.Group>
            <Button variant="primary" type="submit" className="action-primary isos-btn">
                Enviar
            </Button>
        </Form>
    );
}

export default ContactFormIsos;
