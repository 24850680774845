import React from "react";
import '../styles/BlogRecent.css'

const BlogRecent = ({ data }) => {
    return (
        <section className="blog-recent-art">
            <h1>{data.title}</h1>
            <div className="recent-articles">
                {data.article.map((item, index) => (
                    <div key={index} className="recent-art">
                        <img src={item.image} alt={item.title} />
                        <p>{item.date}</p>
                        <a href={item.link}>
                            <h5>{item.title}</h5>
                        </a>
                    </div> 
                ))}
            </div>
        </section>
    )
};

export default BlogRecent;