import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";

const AboutTimeline = ({ data }) => {
    return (
        <div className="max-w-7xl mx-auto px-4 py-6">
            <h2 className="text-5xl text-center mb-12 xs:text-4xl">
                {data.title}
            </h2>

            <div className="max-w-7xl mx-auto 2xl:max-w-5xl">
                <img
                    src={data.timelineImage}
                    alt="Timeline"
                    width={1200}
                    height={400}
                    className="w-full h-full"
                />
            </div>

            <div className="relative w-full h-full 2xl:max-w-5xl mx-auto block">
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={30}
                    slidesPerView={1}
                    navigation={{
                        nextEl: ".timeline-next",
                        prevEl: ".timeline-prev",
                    }}
                    breakpoints={{
                        640: { slidesPerView: 2 },
                        1024: { slidesPerView: 4 },
                    }}
                >
                    {data.timelines.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className="h-full flex flex-col items-center">
                                <div className="w-[100%] h-16 bg-[#DAD9D9] flex items-center justify-center">
                                    <h3 className="text-3xl font-bold text-gray-800">
                                        {item.year}
                                    </h3>
                                </div>
                                <div className="bg-[#DEEDF2] p-6 w-[100%] relative min-h-[740px] xs:min-h-[500px] 2xl:min-h-[600px]">
                                    <p className="text-start text-lg leading-relaxed 2xl:text-medium">
                                        {item.text}
                                    </p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

                <div className="absolute inset-y-1/2 -left-16 -right-16 flex items-center justify-between w-[calc(100%+8rem)] z-10 
    xs:relative xs:top-auto xs:w-full xs:flex xs:justify-center xs:gap-4 xs:mt-4 xs:-left-0 xs:-right-5 lg:hidden">
                    <button className="timeline-prev w-12 h-12 bg-white border-2 border-gray-300 rounded-full flex items-center justify-center text-gray-700 shadow-lg hover:bg-gray-50 transition 
        xs:w-10 xs:h-10">
                        ‹
                    </button>
                    <button className="timeline-next w-12 h-12 bg-white border-2 border-gray-300 rounded-full flex items-center justify-center text-gray-700 shadow-lg hover:bg-gray-50 transition 
        xs:w-10 xs:h-10">
                        ›
                    </button>
                </div>



            </div>

        </div>
    );
};

export default AboutTimeline;