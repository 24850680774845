import React , {useEffect} from 'react';
import data from '../data/payments.json';
import BarPayments from '../components/BarPayments';
import WelcomeSection from '../components/WelcomeSection';
import ProductList from '../components/ProductList';
import BannerSayYes from '../components/BannerSayYes';
import BannerSolution from '../components/BannerSolution';
import FAQ from '../components/faq';
import '../styles/PaymentPersons.css';
import AdvantagesDigitalAndPerson from '../components/AdvantagesDigitalAndPerson';

const Personpayments = () => {
  const paymentData = data.find((item) => item.id === "paymentpersons");

  useEffect(() => {
          if (paymentData) {
              document.title = `Terminales de pago para tu negocio | Getnet.`;
          } else {
              document.title = "Página no encontrada";
          }
      }, [paymentData]);

  if (!paymentData) {
    return <p>Datos no encontrados</p>;
  }

  return (
    <div>
      <BarPayments data={paymentData.bar} />
      <WelcomeSection data={paymentData.welcome[0]} />
      <ProductList data={paymentData.products[0]} />
      <AdvantagesDigitalAndPerson data={paymentData.advantages[0]}/>
      <BannerSayYes data={paymentData.bannerSayYes[0]} color='bg-[#e8f2f6]'/>
      <BannerSolution data={paymentData.bannerSolution}/>
      <FAQ data={paymentData.faq[0]}/>
    </div>
  );
};

export default Personpayments;
