import React from "react";

const AboutFeatures = ({ data }) => {

    return (
        <div className="max-w-7xl mx-auto px-4 py-12">
            <div className="text-center mx-auto mb-12">
                <p className="text-xl mb-6">{data.pOne}</p>
                <p className="text-xl mt-10">{data.pTwo}</p>
            </div>

            <h2 className="text-6xl text-center mb-20 xs:text-4xl">
                {data.titleOne}
            </h2>

            <div className="text-center mb-20">
                <p className="text-xl mb-20">{data.pThree}</p>

                <div className="grid grid-cols-2 md:grid-cols-4 gap-6 px-4 mt-10 xs:grid-cols-1">
                    {data.cards.map((card, index) => (
                        <div
                            key={index}
                            className="bg-white rounded-xl shadow-lg p-6"
                        >
                            <div className="flex flex-col space-y-4">
                                <div className="w-10 h-10 relative">
                                    <img
                                        src={card.cardImage}
                                        alt={card.title}
                                        className="object-contain w-full h-full"
                                    />
                                </div>
                                <h3 className="text-xl text-left">
                                    {card.title}
                                </h3>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="text-center mb-20">
                <h2 className="text-5xl mb-8">{data.titleTwo}</h2>
                <p className="text-xl text-gray-700 max-w-5xl mx-auto mb-12">
                    {data.pFour}
                </p>

                <div className="flex flex-wrap justify-center gap-8">
                    {data.features.map((feature, index) => (
                        <div key={index} className="w-48 flex flex-col items-center p-4">
                            <div className="mb-4 w-16 h-16 relative">
                                <img
                                    src={feature.featureImage}
                                    alt={feature.featureTitle}
                                    layout="fill"
                                    objectFit="contain"
                                />
                            </div>
                            <h3 className="text-lg font-medium text-center">
                                {feature.featureTitle}
                            </h3>
                        </div>
                    ))}
                </div>
            </div>

            <div className="max-w-7xl mx-auto">
                <img
                    src={data.aboutImage}
                    alt="About GetSmart"
                    layout="responsive"
                    objectFit="contain"
                />
            </div>
        </div>
    );
};

export default AboutFeatures;