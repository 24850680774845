import React, { useState } from "react";
import ModalQuejasYSugerencias from "../pages/ModalQuejasYSugerencias";
import useSendEvents from "../hooks/useSendEvents";

const menuItems = [
  {
    title: "Pagos en persona",
    route: "/nuestros-productos/pagosenpersona",
    children: [
      { title: "Get Smart", route: "/nuestros-productos/getsmart" },
      { title: "G Tapp", route: "/nuestros-productos/gtapp" },
      { title: "Get Advance", route: "/nuestros-productos/getadvance" },
      { title: "Get Mini", route: "/nuestros-productos/getmini" },
    ],
  },
  {
    title: "Pagos digitales",
    route: "/nuestros-productos/pagosdigitales",
    children: [
      { title: "Get Checkout", route: "/nuestros-productos/getcheckout" },
      { title: "Get Link and Pay", route: "/nuestros-productos/getlinkandpay" },
    ],
  },
  {
    title: "Sectores",
    children: [
      {
        title: "Restaurantes",
        route: "/nuestros-productos/sector/restaurantes",
      },
      { title: "Hoteles", route: "/nuestros-productos/sector/hoteles" },
      { title: "Misceláneas", route: "/nuestros-productos/sector/miscelaneas" },
      { title: "PyMEs", route: "/nuestros-productos/sector/pymes" },
      { title: "Médicos", route: "/nuestros-productos/sector/medicos" },
      {
        title: "Universidades",
        route: "/nuestros-productos/sector/universidades",
      },
      {
        title: "Refaccionarias y Ferreterías",
        route: "/nuestros-productos/sector/refaccionarias-ferreterías",
      },
    ],
  },
];

function Footer() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const [openItem, setOpenItem] = useState(null);

  const toggleAccordion = (item) => {
    setOpenItem(openItem === item ? null : item);
  };

  const {
    handleEvent,
    handleEventWithCallBackRedirect,
    handleEventWithCallBackAction,
  } = useSendEvents();

  const filteredMenuItems = menuItems.filter(
    (item) => item.title !== "Sectores"
  );

  return (
    <div>
      <div className="footer xs:hidden">
        <div className="footer-top">
          <div className="footer-top-content">
            <button
              onClick={() =>
                handleEventWithCallBackRedirect(
                  () =>
                    handleEvent({
                      event: "select_content",
                      action_type: "click on footer menu",
                      selection_name: "getnet logo",
                      module_type: "footer",
                      page_category: "home",
                    }),
                  "/"
                )
              }
            >
              <img src="/media/images/footer/logo-footer.png" alt="getnet" />
            </button>
            <p className="footer-top-text text-lg pt-2 lg:hidden">
              Síguenos en{" "}
            </p>
            <div className="social rounded-3xl">
              <a href="https://www.facebook.com/GetnetMx" target="blank">
                <img
                  className="footer-top-image footer-img"
                  src="/media/images/footer/fb.png"
                  alt="Facebook"
                />
              </a>
              <a href="https://x.com/GetnetMx" target="blank">
                <img
                  className="footer-top-image footer-img"
                  src="/media/images/footer/x.png"
                  alt="Instagram"
                />
              </a>
              <a
                href=" https://www.linkedin.com/company/getnetmx/"
                target="blank"
              >
                <img
                  className="footer-top-image footer-img"
                  src="/media/images/footer/in.png"
                  alt="in"
                />
              </a>
              <a href=" https://www.instagram.com/getnetmx/" target="blank">
                <img
                  className="footer-top-image footer-img"
                  src="/media/images/footer/ins.png"
                  alt="Instagram"
                />
              </a>
              <a
                href="https://www.youtube.com/@getnetmexico9426"
                target="blank"
              >
                <img
                  className="footer-top-image footer-img"
                  src="/media/images/footer/yt.png"
                  alt="Yt"
                />
              </a>
              <a href=" https://www.tiktok.com/@getnet_mx" target="blank">
                <img
                  className="footer-top-image footer-img  p-[2px]"
                  src="/media/images/footer/tt.jpg"
                  alt="TikTok"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-5 lg:grid-cols-2 m-20">
          <div className="about-us">
            <p className="text-base font-bold">NOSOTROS</p>
            <ul class="m-0 p-0 text-base ">
              <li class="m-0 p-0">
                <button
                  onClick={() =>
                    handleEventWithCallBackRedirect(
                      () =>
                        handleEvent({
                          event: "select_content",
                          action_type: "click on footer menu",
                          selection_name: "payments in person",
                          module_type: "footer",
                          page_category: "home",
                        }),
                      "/nuestros-productos/pagosenpersona"
                    )
                  }
                  class="text-white no-underline"
                >
                  <p class="m-0 p-0">Pagos en persona</p>
                </button>
              </li>
              <li class="m-0 p-0">
                <button
                  onClick={() =>
                    handleEventWithCallBackRedirect(
                      () =>
                        handleEvent({
                          event: "select_content",
                          action_type: "click on footer menu",
                          selection_name: "digital payments",
                          module_type: "footer",
                          page_category: "home",
                        }),
                      "/nuestros-productos/pagosdigitales"
                    )
                  }
                  class="text-white no-underline"
                >
                  <p class="m-0 p-0">Pagos digitales</p>
                </button>
              </li>
              <li class="m-0 p-0">
                <button
                  onClick={() =>
                    handleEventWithCallBackRedirect(
                      () =>
                        handleEvent({
                          event: "select_content",
                          action_type: "click on footer menu",
                          selection_name: "payment center",
                          module_type: "footer",
                          page_category: "home",
                        }),
                      "/nuestros-productos/centrodepagos"
                    )
                  }
                  class="text-white no-underline"
                >
                  <p class="m-0 p-0">Centro de pagos</p>
                </button>
              </li>
            </ul>

            <br />

            <ul class="m-0 p-0 text-lg ">
              <li class="m-0 p-0">
                <button
                  onClick={() =>
                    handleEventWithCallBackRedirect(
                      () =>
                        handleEvent({
                          event: "select_content",
                          action_type: "click on footer menu",
                          selection_name: "who we are",
                          module_type: "footer",
                          page_category: "home",
                        }),
                      "/nuestros-productos/sobregetnet"
                    )
                  }
                  class="text-white no-underline"
                >
                  <p class="m-0 p-0">¿Quiénes Somos?</p>
                </button>
              </li>
              <li class="m-0 p-0">
                <button
                  onClick={() =>
                    handleEventWithCallBackAction(
                      () =>
                        handleEvent({
                          event: "select_content",
                          action_type: "click on footer menu",
                          selection_name: "complaints and Suggestions",
                          module_type: "footer",
                          page_category: "home",
                        }),
                      () => handleOpenModal()
                    )
                  }
                  class="m-0 p-0 cursor-pointer no-underline"
                >
                  Quejas y sugerencias
                </button>
              </li>
              <li class="m-0 p-0">
                <a
                  href="/media/documents/Aviso2025.pdf"
                  class="text-white no-underline"
                >
                  <p class="m-0 p-0">Aviso de Privacidad</p>
                </a>
              </li>
            </ul>
          </div>
          <div className="about-us">
            <p className="text-base font-bold">SECTORES</p>
            <ul class="m-0 p-0 text-base ">
              <li class="m-0 p-0">
                <a
                  href="/nuestros-productos/sector/restaurantes"
                  class="text-white no-underline"
                >
                  <p class="m-0 p-0">Restaurantes</p>
                </a>
              </li>
              <li class="m-0 p-0">
                <a
                  href="/nuestros-productos/sector/hoteles"
                  class="text-white no-underline"
                >
                  <p class="m-0 p-0">Hoteles</p>
                </a>
              </li>
              <li class="m-0 p-0">
                <a
                  href="/nuestros-productos/sector/miscelaneas"
                  class="text-white no-underline"
                >
                  <p class="m-0 p-0">Misceláneas</p>
                </a>
              </li>
              <li class="m-0 p-0">
                <a
                  href="/nuestros-productos/sector/pymes"
                  class="text-white no-underline"
                >
                  <p class="m-0 p-0">PyMEs</p>
                </a>
              </li>
              <li class="m-0 p-0">
                <a
                  href="/nuestros-productos/sector/medicos"
                  class="text-white no-underline"
                >
                  <p class="m-0 p-0">Médicos</p>
                </a>
              </li>
              <li class="m-0 p-0">
                <a
                  href="/nuestros-productos/sector/universidades"
                  class="text-white no-underline"
                >
                  <p class="m-0 p-0">Universidades</p>
                </a>
              </li>
              <li class="m-0 p-0">
                <a
                  href="/nuestros-productos/sector/refaccionarias-ferreterías"
                  class="text-white no-underline"
                >
                  <p class="m-0 p-0">Refaccionarias y ferreterías</p>
                </a>
              </li>
            </ul>

            <br />
          </div>

          <div className="client-service">
            <p className="text-base font-bold">SERVICIO AL CLIENTE</p>
            <p className="w-60 2xl:w-40">
              Si requieres soporte técnico para tu terminal Getnet, nuestro
              número de servicio al cliente es:
              <u> 5591779899</u>
            </p>
            <p className="w-60 2xl:w-40">
              Si estás interesado en ser cliente Getnet, nuestros números de
              atención son:
            </p>
            <p>
              Llamadas:{" "}
              <u className="underline font-bold text-white pl-2"> 5585263274</u>
            </p>
            <button
              onClick={() =>
                handleEventWithCallBackAction(() =>
                  handleEvent({
                    event: "external",
                    action_type: "click on footer menu",
                    selection_name: "whatsapp_open",
                    module_type: "footer",
                    page_category: "home",
                  })
                )
              }
            >
              WhatsApp:
              <a
                href="https://wa.me/525647844407"
                target="_blank"
                rel="noopener noreferrer"
                className="underline font-bold text-white pl-2"
              >
                5647844407
              </a>
            </button>
          </div>

          <div className="isos">
            <button
              onClick={() =>
                handleEventWithCallBackRedirect(
                  () =>
                    handleEvent({
                      event: "select_content",
                      action_type: "click on footer menu",
                      selection_name: "meet our authorized distributors",
                      module_type: "footer",
                      page_category: "home",
                    }),
                  "/nuestros-productos/sector/distribuidores-autorizados"
                )
              }
            >
              <p className="text-base text-start font-bold 2xl:pr-5">
                CONOCE A NUESTROS DISTRIBUIDORES AUTORIZADOS
              </p>
            </button>
          </div>

          <div className="payment-methods">
            <p className="text-base font-bold">MÉTODOS DE PAGO</p>
            <p className="text-base font-thin w-64">
              Adquiere tu Terminal con tarjetas participantes:
            </p>
            <div className="grid grid-cols-3 w-64 gap-2">
              {Array.from({ length: 7 }, (_, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg w-20 h-10 flex items-center justify-center p-1"
                >
                  <img
                    src={`/media/images/footer/${index + 1}.png`}
                    className="max-w-full max-h-full object-contain aspect-[5/3]"
                  />
                </div>
              ))}
            </div>

            <p className="w-52 pt-1">
              Descarga la app que te conecta con tu Terminal inteligente
            </p>
            <div className="grid grid-cols-3 max-w-80">
              <a
                href="https://play.google.com/store/apps/details?id=mx.com.mitec.optimit"
                target="blank"
              >
                <img src="/media/images/footer/AppStore.png" alt="PlayStore" />
              </a>
              <a
                href="https://apps.apple.com/mx/app/getnet-app/id1566625955"
                target="blank"
              >
                <img
                  src="/media/images/footer/GooglePlay.png"
                  alt="PlayStore"
                />
              </a>
              <a
                href="https://appgallery.huawei.com/app/C106621779"
                target="blank"
              >
                <img
                  src="/media/images/footer/AppGallery.png"
                  alt="PlayStore"
                />
              </a>
            </div>
          </div>
        </div>
        <ModalQuejasYSugerencias
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
        <h1 className="bg-[#0F0F0F] 2xl">@GetnetMx | getnet.mx</h1>
      </div>
      <div className="hidden xs:flex flex-col mt-20 ">
        <div className="bg-[#1A1A1A] text-white py-5 px-4">
          <span className="text-4xl leading-normal">
            ¿Te podemos echar una mano?
          </span>
          <p className="text-lg pt-3">
            Ponte en contacto con nosotros para descubrir la solución de pago
            que mejor se adapta a las necesidades únicas de tu empresa.
          </p>
          <button className="mt-7 bg-[#EC0000] px-6 py-2 rounded-full">
            <a
              className="no-underline text-white"
              href="/nuestros-productos/contacto"
            >
              Contactar con ventas
            </a>
          </button>
          <div className="mt-5 w-full h-[3px] bg-[#767676] opacity-50" />
          <div className="w-full flex flex-col text-lg mt-10">
            {filteredMenuItems.map((item) => (
              <div key={item.title}>
                <button
                  className="flex justify-between items-center w-full py-1 text-left text-xl transition-colors duration-300"
                  onClick={() => toggleAccordion(item.title)}
                >
                  {item.title}
                  <span
                    className={`text-[#EC0000] transform transition-transform duration-300 ${
                      openItem === item.title ? "rotate-90" : ""
                    }`}
                  >
                    &#8250;
                  </span>
                </button>
                <hr className="border-3 border-[#EC0000] w-10" />
                <div
                  className={`overflow-hidden transition-all duration-500 ease-in-out ${
                    openItem === item.title ? "max-h-[1000px]" : "max-h-0"
                  }`}
                >
                  <div className="px-6">
                    {item.children.map((child) => (
                      <a
                        key={child.title}
                        href={child.route}
                        className="block py-2 text-white text-xl transition-colors duration-300"
                      >
                        {child.title}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            <a
              className="no-underline text-white text-xl"
              href="/nuestros-productos/centrodepagos"
            >
              Centro de pagos
            </a>
            <hr className="border-3 border-[#EC0000] w-10" />
            <a
              className="no-underline text-white text-xl"
              href="/nuestros-productos/sobregetnet"
            >
              Quiénes somos
            </a>
            <hr className="border-3 border-[#EC0000] w-10" />
            <div>
              <button
                className="flex justify-between items-center w-full py-1 text-left text-xl transition-colors duration-300"
                onClick={() => toggleAccordion("Sectores")}
              >
                Sectores
                <span
                  className={`text-[#EC0000] transform transition-transform duration-300 ${
                    openItem === "Sectores" ? "rotate-90" : ""
                  }`}
                >
                  &#8250;
                </span>
              </button>
              <hr className="border-3 border-[#EC0000] w-10" />
              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  openItem === "Sectores" ? "max-h-[1000px]" : "max-h-0"
                }`}
              >
                <div className="px-6">
                  {menuItems
                    .find((item) => item.title === "Sectores")
                    .children.map((child) => (
                      <a
                        key={child.title}
                        href={child.route}
                        className="block py-2 text-white text-xl transition-colors duration-300"
                      >
                        {child.title}
                      </a>
                    ))}
                </div>
              </div>
            </div>
            <a className="no-underline text-white text-xl" href="/blog/index">
              Blog
            </a>
            <hr className="border-3 border-[#EC0000] w-10" />
            <p className="text-xl mt-4">Soporte técnico</p>
            <hr className="border-3 border-[#EC0000] w-10" />
            <div className="flex items-center">
              <img
                className="footer-top-image footer-img w-6"
                src="/media/images/footer/Phone-Mobile.png"
                alt="X"
              />
              <u className="text-xl pl-2">5591779889</u>
            </div>
            <p className="text-xl mt-4">Contrataciones:</p>
            <div className="flex items-center">
              <img
                className="footer-top-image footer-img w-6"
                src="/media/images/footer/Phone-Mobile.png"
                alt="X"
              />
              <u className="text-xl pl-2">5585263274</u>
            </div>
            <div className="flex items-center mt-3">
              <img
                className="footer-top-image footer-img w-6"
                src="/media/images/footer/WhatsApp-Mobile.png"
                alt="X"
              />
              <u className="text-xl pl-2">5647844407</u>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center mt-10 gap-y-6">
            <p className="text-xl">Síguenos en</p>
            <div className="flex flex-row gap-x-5">
              <a href="https://x.com/GetnetMx" target="blank">
                <img
                  className="footer-top-image footer-img w-8"
                  src="/media/images/footer/X-Mobile.png"
                  alt="X"
                />
              </a>
              <a href="https://www.facebook.com/GetnetMx" target="blank">
                <img
                  className="footer-top-image footer-img w-8"
                  src="/media/images/footer/Facebook-Mobile.png"
                  alt="Facebook"
                />
              </a>
              <a
                href=" https://www.linkedin.com/company/getnetmx/"
                target="blank"
              >
                <img
                  className="footer-top-image footer-img w-8"
                  src="/media/images/footer/LinkedIn-Mobile.png"
                  alt="in"
                />
              </a>
              <a href="https://www.instagram.com/getnetmx/" target="blank">
                <img
                  className="footer-top-image footer-img w-8"
                  src="/media/images/footer/Instagram-Mobile.png"
                  alt="Instagram"
                />
              </a>
              <a
                href="https://www.youtube.com/@getnetmexico9426"
                target="blank"
              >
                <img
                  className="footer-top-image footer-img w-8"
                  src="/media/images/footer/Yt-Mobile.png"
                  alt="Yt"
                />
              </a>
              <a href="https://www.tiktok.com/@getnet_mx" target="blank">
                <img
                  className="footer-top-image footer-img w-8"
                  src="/media/images/footer/TikTok-Mobile.png"
                  alt="TikTok"
                />
              </a>
            </div>
            <img
              className="footer-top-image footer-img w-32 mt-6"
              src="/media/images/footer/Logo.png"
              alt="get"
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-2 text-lg h-full bg-black text-white text-center py-10">
          <a
            className="no-underline text-white"
            href="/media/documents/Aviso2025.pdf"
          >
            Aviso de Privacidad
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
