import React from "react";
import homeData from "../data/Home.json";
import "../styles/BannerImgBg.css";
import { useLocation, useNavigate } from "react-router-dom";
import useSendEvents from "../hooks/useSendEvents";

const BannerImgBg = () => {

  const location = useLocation();
  const navigate = useNavigate();
  
  const {
    handleEvent,
    handleEventWithCallBackRedirect,
  } = useSendEvents();

  const dispatchAction = (href) => {
    if(location.pathname == '/'){
      handleEventWithCallBackRedirect(
        () =>
          handleEvent({
            event: "select_content",
            action_type: "click on cta",
            selection_name: "contact now",
            module_type: "hero",
            page_category: "home",
          }),
        href
      )
    } else {
      navigate(href)
    }
  }

  return (
    <div>
      {homeData.map((homeItem) =>
        homeItem.welcome.map((welcomeItem) => (
          <div className={welcomeItem.class} key={welcomeItem.class}>
            <h1 className="lg:text-5xl 2xl:text-4xl xs:text-7xl">
              <span>{welcomeItem.textBold}</span>
              {welcomeItem.textRegular}
            </h1>
            {welcomeItem.actionPrimary && (
              <button
                onClick={()=> dispatchAction(`${welcomeItem.link}?source=${encodeURIComponent(
                  welcomeItem.sourceContact
                )}`)}
              >
                <button className="action-primary ">
                  {welcomeItem.actionPrimary}
                </button>
              </button>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default BannerImgBg;
