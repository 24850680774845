import React, { useState, useEffect } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/CarosuelProducts.css";
import useSendEvents from "../hooks/useSendEvents";

const CarosuelProducts = ({ data }) => {
  const getChunkSize = (width) => {
    if (width <= 768) return 1;
    if (width <= 1023) return 2;
    return 3;
  };

  const carouselData = data[0];

  const [chunkSize, setChunkSize] = useState(getChunkSize(window.innerWidth));
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setChunkSize(getChunkSize(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const chunkedData = [];
  for (let i = 0; i < carouselData.solution.length; i += chunkSize) {
    chunkedData.push(carouselData.solution.slice(i, i + chunkSize));
  }

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? chunkedData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === chunkedData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const hasMultipleSlides = chunkedData.length > 1;

  const { handleEvent, handleEventWithCallBackAction } = useSendEvents();

  return (
    <div className="w-full my-10 bg-[#f5f9fb] pb-20">
      <div className="flex flex-col text-center xs:px-4 pt-32">
        <h1 className="text-5xl xs:text-start 2xl:text-4xl">
          {carouselData.title}
        </h1>
        <p className="text-xl xs:text-start xs:pt-4">
          {carouselData.textRegular}
        </p>
      </div>

      <section className="mx-auto max-w-7xl mt-10 overflow-hidden xs:mt-2 relative 2xl:max-w-6xl">
        <div
          className="w-full flex transition-transform duration-700 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {chunkedData.map((group, groupIndex) => (
            <div
              key={groupIndex}
              className="w-full flex-shrink-0 grid grid-cols-3 lg:grid-cols-2 xs:grid-cols-1 gap-x-10 lg:px-4"
            >
              {group.map((item, index) => (
                <div
                  key={index}
                  className="grid grid-rows-2 xs:flex xs:flex-col w-full"
                >
                  <div className="bg-[#DCEBF0]">
                    <div className="h-full w-90 ">
                      {item.cardImage && (
                        <img
                          src={item.cardImage}
                          alt={item.title}
                          className="w-full h-full object-fill"
                        />
                      )}
                    </div>
                  </div>
                  <div className="grid grid-rows-1 justify-between px-4 bg-white">
                    <div className="flex flex-col gap-y-5 xs:gap-y-2 h-52 mt-5 lg:h-72 ">
                      <h3 className="text-2xl lg;text-lg xs:text-xl 2xl:text-2xl">
                        {item.title}
                      </h3>
                      <p className="text-xl lg:text-lg xs:text-lg 2xl:text-medium">
                        {item.textRegular}
                      </p>
                      {item.textRegularTwo && (
                        <p className="2xl:text-sm">{item.textRegularTwo}</p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </section>

      {hasMultipleSlides && (
        <div className="absolute right-32 lg:right-4 xs:right-2">
          <div className="flex justify-center items-center gap-x-6">
            <button
              onClick={() =>
                handleEventWithCallBackAction(
                  () =>
                    handleEvent({
                      event: "interaction",
                      action_type: "click on page",
                      selection_name: "preview icon",
                      module_type: "carousel products",
                      page_category: "sectors page",
                    }),
                  () => handlePrev()
                )
              }
              className="w-12 h-12 pt-3 bg-white border border-black rounded-full flex items-center justify-center z-10 shadow-md"
            >
              <span className="text-4xl leading-none flex items-center justify-center rotate-180">
                &#8250;
              </span>
            </button>
            <button
              onClick={() =>
                handleEventWithCallBackAction(
                  () =>
                    handleEvent({
                      event: "interaction",
                      action_type: "click on page",
                      selection_name: "next icon",
                      module_type: "carousel products",
                      page_category: "sectors page",
                    }),
                  () => handleNext()
                )
              }
              className="w-12 h-12 text-center bg-white border border-black rounded-full flex items-center justify-center z-10 shadow-md"
            >
              <span className="text-4xl leading-none flex items-center justify-center">
                &#8250;
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CarosuelProducts;
