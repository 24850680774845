import React from "react";
import '../../../styles/BlogRecent.css'

const BlogRecentDynamic = ({ data }) => {
  return (
    <section className="blog-recent-art">
      <h1>{data.title}</h1>
      <div className="recent-articles">
        {data.articles.map((item, index) => (
          <div key={index} className="recent-art">
            <img src={item.image} alt={item.title} />
            <p>{item.date}</p>
            <a href={item.link}>
              <h5>{item.title}</h5>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BlogRecentDynamic;
