import React from "react";
import data from "../data/Home.json";
import useSendEvents from "../hooks/useSendEvents";

const Blog = () => {
  const blog = data[0]?.blog[0] || {};
  const articles = blog?.article || [];
  const { handleEvent, handleEventWithCallBackRedirect } = useSendEvents();

  // Función para agrupar los artículos secundarios en pares
  const groupSecondaryArticles = (articles) => {
    const grouped = [];
    let tempGroup = [];

    articles.forEach((article) => {
      if (article.class === "secondary-article") {
        tempGroup.push(article);
        if (tempGroup.length === 2) {
          grouped.push(tempGroup);
          tempGroup = [];
        }
      } else {
        if (tempGroup.length > 0) {
          grouped.push(tempGroup);
          tempGroup = [];
        }
        grouped.push([article]);
      }
    });

    if (tempGroup.length > 0) {
      grouped.push(tempGroup);
    }

    return grouped;
  };

  const groupedArticles = groupSecondaryArticles(articles);

  return (
    <section className="max-w-6xl flex flex-col gap-y-10 mx-auto py-20 px-4">
      <h2 className="text-center text-5xl xs:text-3xl">{blog.title}</h2>
      <div className="grid grid-cols-3 xs:grid-cols-1  gap-x-10 gap-y-8">
        {groupedArticles.map((group, index) => (
          <div
            key={index}
            className={`${group.length === 2 ? "grid gap-y-4" : ""}`}
          >
            {group.map((article, idx) => (
              <div
                key={idx}
                className={`${
                  article.class === "primary-article" ? "grid grid-rows-1" : ""
                } shadow-xl`}
              >
                {article.mainImage && (
                  <div className="w-full h-64 row-span-1">
                    <img
                      src={article.mainImage}
                      alt={article.title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                )}
                <div className="blog-content p-6 row-span-1">
                  <h3
                    className={`${
                      article.class === "primary-article"
                        ? "font-bold text-xl lg:h-30 xs:h-10"
                        : "text-xl font-semibold"
                    }`}
                  >
                    {article.title}
                  </h3>
                    <p className="text-[#464646]">{article.resume}</p>
                  <button
                      onClick={() =>
                        handleEventWithCallBackRedirect(
                          () =>
                            handleEvent({
                              event: "select_content",
                              action_type: "click on cta",
                              selection_name: "read more",
                              module_type: "check our blog",
                              page_category: "home",
                              value: article.title
                            }),
                            article.link
                        )
                      }
                  
                  className="no-underline">
                    <button
                      className={`${
                        article.class === "primary-article"
                          ? "py-2 px-4 border-[1px] border-[#ec0000] rounded-full text-[#ec0000]"
                          : "text-[#ec0000] flex flex-row no-underline gap-x-2"
                      }`}
                    >
                      {article.actionSecondary}
                      {article.class !== "primary-article" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="lucide lucide-move-right"
                        >
                          <path d="M18 8L22 12L18 16" />
                          <path d="M2 12H22" />
                        </svg>
                      )}
                    </button>
                  </button>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Blog;