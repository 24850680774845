import React, { useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import BannerImgBgW from '../components/BannerImgBgW';
import Advantages from '../components/Advantages';
import Sectors from '../components/Sectors';
import AboutFooter from '../components/AboutFooter';
import AboutFeatures from '../components/AboutFeatures';
import AboutTimeline from '../components/AboutTimeline';
import data from '../data/SobreGetnet.json';

const SobreGetnet = () => {
    const { id } = useParams();
    const productData = data.find((product) => product.id === id);
    
    useEffect(() => {
        if (productData) {
            document.title = `Líderes en soluciones de pago para negocios | Getnet`;
        } else {
            document.title = "Página no encontrada";
        }
    }, [productData]);

    if (!productData) {
        return <Navigate to="/404" />;
    }

    return (
        <div className={`${productData.id}-page product-page overflow-hidden`}>
            <BannerImgBgW data={productData.welcome[0]}/>
            <AboutFeatures data={productData.about[0]}/>
            <Advantages data={productData.advantages[0]}/>
            <AboutTimeline data={productData.timeline[0]} />
            <Sectors data={productData.sectors[0]} />
            <AboutFooter data={productData.footer[0]} />
        </div> 
    );
};

export default SobreGetnet;