import React from "react";
import { Form, Formik } from "formik";
import { useAuthStore } from "../../stores/auth/auth.store";
import { useFormHandler } from "../../hooks/useFormHandler";
import { LoginvalidationSchema } from "../../utils/validations/schema/LoginSchema";
import { LoginInitialValues } from "../../utils/validations/initialValues/LoginInitialValues";
import { getFirebaseErrorMessage } from "../../utils/firebase/errors/Errors";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  Button,
  Input,
  Spinner,
  Card,
  CardBody,
  CardHeader,
  Divider,
} from "@nextui-org/react";
import { auth, doc, fireStore, getDoc } from "../../utils/firebase/config";
import { LogIn } from "lucide-react";

const SignIn = () => {
  const loginUser = useAuthStore((state) => state.loginUser);

  const {
    errorMessages,
    isLoading,
    setIsLoading,
    handleResponse,
    handleError,
  } = useFormHandler({
    onSuccess: async (response) => {
      try {
        loginUser(response);
      } catch (error) {
        handleError(error);
      }
    },
    onError: (error) => {
      console.error("Login error:", error);
    },
  });

  const handleOnSubmit = async ({ email, password }) => {
    setIsLoading(true);
    try {
      const userFirebase = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log(userFirebase, 'user')
      const redDoc = (
        await getDoc(doc(fireStore, `usuarios/${userFirebase.user.uid}`))
      ).data();
      const response = {
        name: redDoc.name,
        email,
        area: redDoc.area,
        id: userFirebase.user.uid,
        accessToken: userFirebase.user.accessToken,
      };
      handleResponse(response);
    } catch (error) {
      handleError(getFirebaseErrorMessage(error));
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 flex items-center justify-center p-4">
      <div className="w-full max-w-md">
        <Card className="w-full shadow-xl bg-white">
          <CardHeader className="flex flex-col gap-2 items-center pt-8 pb-4">
            <img
              src="/media/images/home/logoG.svg"
              alt="logo pasatex"
              className="h-16 w-auto mb-2"
            />
            <h1 className="text-2xl font-bold text-gray-800">Bienvenido</h1>
            <p className="text-sm text-gray-500">
              Inicia sesión para continuar
            </p>
          </CardHeader>
          <Divider />
          <CardBody className="px-6 py-8">
            <Formik
              initialValues={LoginInitialValues}
              validationSchema={LoginvalidationSchema()}
              onSubmit={handleOnSubmit}
              validateOnChange={true}
            >
              {({ errors, touched, isSubmitting, handleChange }) => (
                <Form className="flex flex-col gap-6">
                  <Input
                    placeholder="Correo electronico"
                    name="email"
                    onChange={handleChange}
                    isInvalid={touched.email && !!errors.email}
                    errorMessage={touched.email ? errors.email : ""}
                  />
                  <Input
                    placeholder="Contraseña"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    isInvalid={touched.password && !!errors.password}
                    errorMessage={touched.password ? errors.password : ""}
                  />
                  {errorMessages && (
                    <div className="text-danger text-sm text-center bg-danger-50 p-2 rounded-lg">
                      {errorMessages}
                    </div>
                  )}

                  <Button
                    type="submit"
                    color="primary"
                    className="w-full bg-[#ec0000] text-white text-center flex rounded-xl"
                    size="lg"
                    disabled={isSubmitting}
                    startContent={!isLoading && <LogIn size={20} />}
                  >
                    {!isLoading ? (
                      "Iniciar sesión"
                    ) : (
                      <Spinner color="white" size="sm" />
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default SignIn;
