import React from "react";

const PayPalFAQ = ({ data }) => {
    return (
        <div className="bg-[#EDEEF0] pt-20 pb-20 px-4 flex flex-col items-center">
            <div className="text-center space-y-6">
                {Object.values(data).map((text, index) => {
                    const formattedText = text.replace(/^(\d+)/, (match) => `<sup>${match}</sup>`);
                    return (
                        <p key={index} className="text-lg text-gray-800 text-start max-w-7xl 2xl:max-w-5xl lg:text-medium xs:text-sm xs:p-2" dangerouslySetInnerHTML={{ __html: formattedText }} />
                    );
                })}
            </div>
        </div>
    );
};

export default PayPalFAQ;