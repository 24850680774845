import React from "react";
import '../styles/BlogWelcome.css'

const BlogWelcome = ({ data }) => {
    return (
        <div className={data.class}>
            <img src={data.mainImage} alt={data.title}/>
            <h1>{data.title}</h1>
        </div>
    )
}
export default BlogWelcome; 