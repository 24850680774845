import { LayoutDashboard, FileText, LogOut } from "lucide-react";
import React from "react";
import { useAuthStore } from "../../../stores/auth/auth.store";
import { useLocation, Link } from "react-router-dom"; 
const menuItems = [
  { icon: LayoutDashboard, text: "Banners", href: "/dashboard/banners" },
  { icon: FileText, text: "Blog", href: "/dashboard/blog" },
];

const Sidebar = () => {
  const logout = useAuthStore((state) => state.logout);
  const location = useLocation(); 

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="w-64 min-h-screen bg-gray-800 text-white p-5">
      {menuItems.map((item, index) => {
        const isActive = location.pathname === item.href;

        return (
          <Link
            key={index}
            to={item.href} 
            className={`flex items-center p-3 mb-2 rounded-lg transition-colors no-underline ${
              isActive
                ? "bg-gray-700 text-white"
                : "text-gray-300 hover:bg-gray-700 hover:text-white"
            }`}
          >
            <item.icon className="mr-3" />
            <span className="text-sm">{item.text}</span>
          </Link>
        );
      })}

      <div
        onClick={handleLogout}
        className="flex items-center p-3 mb-2 rounded-lg transition-colors text-red-500 hover:bg-gray-700 cursor-pointer"
      >
        <LogOut className="mr-3" />
        <span className="text-sm">Cerrar Sesión</span>
      </div>
    </div>
  );
};

export default Sidebar;