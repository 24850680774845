import { AuthErrorCodes } from "firebase/auth";

export const getFirebaseErrorMessage = (error) => {
  let errorMessage = "Ocurrió un error. Por favor intenta de nuevo.";

  if (error.code) {
    switch (error.code) {
      case AuthErrorCodes.INVALID_EMAIL:
        errorMessage = "El correo electrónico no es válido.";
        break;
      case AuthErrorCodes.USER_DISABLED:
        errorMessage = "La cuenta está deshabilitada.";
        break;
      case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
        errorMessage = "Demasiados intentos. Por favor intenta más tarde.";
        break;
      case AuthErrorCodes.INVALID_DYNAMIC_LINK_DOMAIN:
        errorMessage = "El dominio del enlace dinámico proporcionado no está autorizado.";
        break;
      case AuthErrorCodes.TENANT_ID_MISMATCH:
        errorMessage = "El ID del tenant no coincide.";
        break;
      default:
        errorMessage = "Error desconocido. Intenta nuevamente.";
    }
  }

  return errorMessage;
};
