import { v4 } from "uuid";
import {
  getDownloadURL,
  ref,
  storage,
  uploadBytesResumable,
} from "../utils/firebase/config";

export const uploadFileToStorage = async (document) => {
  try {
    const uploadTask = await uploadBytesResumable(
      ref(storage, `files/${v4()}`),
      document
    );
    const fileRef = ref(storage, uploadTask.metadata.fullPath);
    return await getDownloadURL(fileRef);
  } catch (error) {
    console.log(error);
    return null;
  }
};
