import React from 'react'

const AdvantagesDigitalAndPerson = ({ data }) => {
  const formatTextWithSuperscript = (text) => {
    return text.replace(/(?<!\d\/)(\d+)(?!(\d|%|\/\d))/g, (match) => `<sup>${match}</sup>`);
  };
  
  return (
    <div className="advantages mx-auto max-w-7xl my-20 2xl:max-w-6xl xs:py-4 lg:px-10 xs:px-2">
      <h1 className="text-center xs:text-3xl">{data.title}</h1>
      <div>
        {data.advantage.map((item, index) => (
          <div
            className={`flex ${window.innerWidth <= 768 ? 'flex-col' : index % 2 === 0 ? "flex-row " : "flex-row-reverse "
              } gap-x-4 mt-20 xs:mt-4 items-center`}
            key={index}
          >
            <div className="w-1/2 xs:w-full xs:px-4">
              <img
                src={item.mainImage}
                alt={item.title}
                className="w-full h-full object-contain"
              />
            </div>
            <div
              className={`${window.innerWidth <= 768 ? 'mx-2 mt-4 ' : 'adv-text'} w-1/2  ${index % 2 !== 0 ? "text-start mr-10" : "ml-20"
                } xs:w-full xs:px-4`}
            >
              <h3 className="text-5xl max-w-lg mb-10 xs:text-2xl xs:mb-2 2xl:text-4xl">{item.title}</h3>
              <p className="text-[#767676] max-w-xl text-xl xs:text-medium lg:text-lg"
                dangerouslySetInnerHTML={{ __html: formatTextWithSuperscript(item.textRegular) }}
              />
              {item.textRegularTwo && (
                <p className="text-[#767676] font-semibold">
                  {item.textRegularTwo}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdvantagesDigitalAndPerson
