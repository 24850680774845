import React, { useEffect } from 'react';
import data from '../data/payments.json';
import WelcomeSection from '../components/WelcomeSection';
import BarPayments from '../components/BarPayments';
import ProductList from '../components/ProductList';
import BannerSayYes from '../components/BannerSayYes';
import BannerSolution from '../components/BannerSolution';
import FAQ from '../components/faq';
import AdvantagesDigitalAndPerson from '../components/AdvantagesDigitalAndPerson';
const OnlinePayments = () => {
  const paymentData = data.find((item) => item.id === "onlinepayment");

  useEffect(() => {
            if (paymentData) {
                document.title = `Soluciones de pago en línea seguras y rápidas | Getnet`;
            } else {
                document.title = "Página no encontrada";
            }
        }, [paymentData]);
  
    if (!paymentData) {
      return <p>Datos no encontrados</p>;
    }

  if (!paymentData) {
    return <p>Datos no encontrados</p>;
  }

  return (
    <div>
      <BarPayments data={paymentData.bar} />
      <WelcomeSection data={paymentData.welcome[0]} showIcons={false} color='text-gray-400' />
      <ProductList data={paymentData.products[0]} showIcons={false} elements={2} />
      <AdvantagesDigitalAndPerson data={paymentData.advantages[0]}/>
      <BannerSayYes data={paymentData.bannerSayYes[0]} color="bg-[#deedf2]"/>
      <BannerSolution data={paymentData.bannerSolution}/>
      <FAQ data={paymentData.faq[0]}/>
    </div>
  );
};

export default OnlinePayments;
