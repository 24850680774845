import { useState } from "react";



export const useFormHandler = ({
  onSuccess,
  onError,
}) => {
  const [errorMessages, setErrorMessages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleResponse = (
    response
  ) => {
    setIsLoading(false);
    if (response?.error) {
      setErrorMessages("Ha ocurrido un error");
      if (onError) {
        onError("Ha ocurrido un Error");
      }
    } else {
      if (onSuccess) {
        onSuccess(response);
      }
    }
  };

  const handleError = (error) => {
    setIsLoading(false);
    setErrorMessages(error || "Ha ocurrido un error");
  };

  return {
    errorMessages,
    isLoading,
    setIsLoading,
    handleResponse,
    handleError,
  };
};
