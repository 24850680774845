import { useNavigate } from "react-router-dom";


const BlogTemplate = () => {

  const navigate = useNavigate();

  const templates = [
    {
      id: 1,
      name: "Plantilla 1",
      description: "Plantilla basada en el artículo de blog número 1",
    },
    {
      id: 2,
      name: "Plantilla 2",
      description: "Plantilla basada en el artículo de blog número 2",
    },
    {
      id: 3,
      name: "Plantilla 3",
      description: "Plantilla basada en el artículo de blog número 3",
    },
  ];

  return (
<div className="container mx-auto p-8">
  <h1 className="text-5xl font-bold text-center mb-4 text-gray-900">
    Elige tu Plantilla
  </h1>

  <p className="text-lg text-center text-gray-600 mb-8 max-w-2xl mx-auto">
    Aquí podrás seleccionar tu plantilla favorita para crear un blog único y personalizado.
    ¡Explora las opciones y comienza a dar vida a tus ideas!
  </p>

  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
    {templates.map((template) => (
      <div
        key={template.id}
        className="cursor-pointer border border-gray-200 rounded-lg p-6 hover:shadow-xl transition-all duration-300 transform hover:scale-105 bg-white"
        onClick={() => navigate(`/dashboard/templateForm/${template.id}`)}
      >
        <h3 className="text-center text-xl font-semibold text-gray-800 mt-4">
          {template.name}
        </h3>
        <p className="text-center text-sm text-gray-500 mt-2">
          {template.description}
        </p>
      </div>
    ))}
  </div>
</div>
  );
};

export default BlogTemplate;
