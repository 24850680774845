import React from "react";
import useSendEvents from "../hooks/useSendEvents";

const TechnicalSpecifications = ({ data }) => {
  const { handleEvent, handleEventWithCallBackAction } = useSendEvents();

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <section className={`bg-[#DEEDF2] py-10`}>
      <div className="mx-auto max-w-7xl">
        {data[0]?.titletechincalSpecifications && (
          <h1 className="xs:text-3xl text-center">
            {data[0].titletechincalSpecifications}
          </h1>
        )}
        {data[0]?.subtitle && <h2>{data[0].subtitle}</h2>}

        <div className="grid grid-cols-3 xs:grid-cols-1 max-w-7xl mx-auto gap-x-10 xs:my-10 my-20">
          {Array.isArray(data[1]) &&
            data[1].map(
              (item, index) =>
                (item.title ||
                  item.image ||
                  item.subtitle ||
                  item.actionTerciary) && (
                  <div
                    key={index}
                    className="flex flex-col items-center text-center gap-y-2"
                  >
                    {item.title && <h3 className="pb-2">{item.title}</h3>}
                    {item.image && (
                      <div className="w-20">
                        <img
                          src={item.image}
                          alt={item.title || "Specification Image"}
                          className="w-full h-full object-contain"
                        />
                      </div>
                    )}
                    {item.subtitle && (
                      <p className="pt-2 text-lg w-72">{item.subtitle}</p>
                    )}
                    {item.actionTerciary && (
                      <button
                        className="action-terciary"
                        onClick={() => {
                          [item.link, item.linkTwo, item.linkThree].forEach((url) => {
                            if (url) window.open(url, "_blank");
                          });
                        }}
                      >
                        {item.actionTerciary}
                      </button>
                    )}

                  </div>
                )
            )}
        </div>
        <div className="flex justify-center gap-x-4 text-lg">
          {data[0].text && <p>{data[0].text}</p>}
          {data[0].actionTerciary && (
            <button
              onClick={() =>
                handleEventWithCallBackAction(() =>
                  handleEvent({
                    event: "select_content",
                    action_type: "click on cta",
                    selection_name: "here",
                    module_type: "technical specifications",
                    page_category: "product page",
                  })
                )
              }
            >
              <p className="action-terciary">
                <a
                  target="blank"
                  className="no-underline text-[#ec0000]"
                  href={data[0].link}
                >
                  {data[0].actionTerciary}
                </a>
              </p>
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default TechnicalSpecifications;
