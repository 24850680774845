import React from "react";
import "../styles/BannerAbout.css";

const BannerAbout = ({ data }) => {
  if (!data) {
    return null; // No renderiza nada si `data` no está definido
  }

  return (
    <div className="flex bg-[#DEEDF2] xs:px-2">
      <div className={` flex  ${window.innerWidth <= 768 ? 'flex-col' : ' flex-row  mx-auto max-w-5xl  justify-between gap-x-10'}  my-20 xs:my-10 `}>
        <div className="flex flex-col justify-center text-start mb-10">
          {data.title && <h1 className="w-[400px] xs:px-4">{data.title}</h1>}
          <ul className="options-list mt-10 xs:mt-2">
            {data.options?.one && <li>{data.options.one}</li>}
            {data.options?.two && <li>{data.options.two}</li>}
            {data.options?.three && <li>{data.options.three}</li>}
          </ul>
        </div>

        <div className="w-full xs:w-96">
          {data.image && (
            <img
              src={data.image}
              className="w-full h-full object-cover xs:px-4"
              alt={data.title || "Banner Image"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerAbout;
