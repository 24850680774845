import React, { useEffect, useState } from "react";
import { doc, fireStore, getDoc, updateDoc } from "../utils/firebase/config";
import DynamicCarouselBanner from "./dashboard/banner/DynamicCarouselBanner";

const PaymentSolutionsCarousel = () => {
  const [initialValues, setInitialValues] = useState({
    title: "",
    cards: [
      {
        cardImage: "",
        mainText: "",
        secondaryText: "",
        textRegular: "",
        fileDownload: "",
      },
    ],
  });

  useEffect(() => {
    (async () => {
      const redDoc = (
        await getDoc(doc(fireStore, `home/sYoXZj1hFy1qJXDOFo93`))
      ).data();

      if (!redDoc) {
        return;
      }

      // Set initial values from Firestore
      setInitialValues({
        title: redDoc.paymentSolutions?.title || "",
        cards: redDoc.paymentSolutions?.cards || [
          {
            cardImage: "",
            mainText: "",
            secondaryText: "",
            textRegular: "",
            fileDownload: "",
          },
        ],
      });
    })();
  }, []);


  return (
    <div className="flex-1">
      <DynamicCarouselBanner paymentSolutionsElement={initialValues} />
    </div>
  );
};

export default PaymentSolutionsCarousel;
