import React, {useEffect} from 'react';
import { useParams, Navigate } from 'react-router-dom';
import Welcome from '../components/Welcome';
import Details from '../components/Details';
import BannerSayYes from '../components/BannerSayYes';
import Sectors from '../components/Sectors';
import FAQ from '../components/faq';
import CarouselSolutions from '../components/CarouselSolutions';
import data from '../data/GetLinkAndPay.json';
import TechnicalSpecificationsCheckout from '../components/TechnicalSpecificationsCheckout';

const GetLinkAndPay = () => {
  const { id } = useParams(); 
  const productData = data.find((product) => product.id === id); 

  useEffect(() => {
                      if (productData) {
                          document.title = `Cobra con un link de pago fácil y seguro | Getnet`;
                      } else {
                          document.title = "Página no encontrada";
                      }
                  }, [productData]);

  if (!productData) {
    // Redirigir si el `id` no existe en el JSON
    return <Navigate to="/404" />;
  }

  return (
    <div className={`${productData.id}-page product-page`}>
      <Welcome data={productData.welcome[0]} />
      <Details details={productData.details}  checkout={true} />
      <TechnicalSpecificationsCheckout data={productData.technicalSpecifications} />
      <BannerSayYes data={productData.bannerSayYes[0]} />
      <Sectors data={productData.sectors[0]} bg={true} />
      <FAQ data={productData.faq[0]} />
      <CarouselSolutions data={productData.carosuelSolutions[0]} />
    </div>
  );
};

export default GetLinkAndPay;

