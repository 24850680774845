import React from "react";

const TechnicalSpecificationsCheckout = ({ data }) => {
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <section className="max-w-7xl mx-auto justify-center lg:px-14 xs:px-2 py-10 2xl:max-w-6xl">
      {data[0]?.titletechincalSpecifications && (
        <h1 className="text-center xs:text-4xl lg:text-6xl">{data[0].titletechincalSpecifications}</h1>
      )}
      {data[0]?.subtitle && <h2>{data[0].subtitle}</h2>}

      <div className="grid grid-cols-3 xs:grid-cols-1 gap-x-20 xs:my-10 my-32">
        {Array.isArray(data[1]) &&
          data[1].map(
            (item, index) =>
              (item.title ||
                item.image ||
                item.subtitle ||
                item.actionTerciary) && (
                <div
                  key={index}
                  className="flex flex-col justify-start text-start gap-y-2 xs:text-center xs:justify-center xs:items-center"
                >
                  {item.image && (
                    <div className="w-24">
                      <img
                        src={item.image}
                        alt={item.title || "Specification Image"}
                        className="w-full h-full object-contain"
                      />
                    </div>
                  )}
                  {item.title && <h3 className="">{item.title}</h3>}

                  {item.subtitle && (
                    <p className="pt-2 text-xl text-gray-500 xs:text-center">{item.subtitle}</p>
                  )}
                  {item.actionTerciary && (
                    <a href={item.link} target="blank">
                      <button className="action-terciary">
                        {item.actionTerciary}
                      </button>
                    </a>
                  )}
                </div>
              )
          )}
      </div>
      <div className="action-tech">
        {data[0].text && <p>{data[0].text}</p>}
        {data[0].actionTerciary && (
          <p className="action-terciary">
            <a target="blank" href={data[0].link}>
              {data[0].actionTerciary}
            </a>
          </p>
        )}
      </div>
    </section>
  );
};

export default TechnicalSpecificationsCheckout;
