import React,{ useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import Welcome from '../components/Welcome';
import BannerSayYes from '../components/BannerSayYes';
import Plugins from '../components/Plugin';
import PluginsBenefits from '../components/PluginBenefits';
import OurPlugins from '../components/OurPlugins';
import Sectors from '../components/Sectors';
import FAQ from '../components/faq';
import CarouselSolutions from '../components/CarouselSolutions';
import data from '../data/GetCheckout.json';
import Details from '../components/Details';
import TechnicalSpecificationsCheckout from '../components/TechnicalSpecificationsCheckout';

const GetCheckout = () => {
  const { id } = useParams(); 
  const productData = data.find((product) => product.id === id); 
  
    useEffect(() => {
                    if (productData) {
                        document.title = `Procesador de pagos online | Getnet`;
                    } else {
                        document.title = "Página no encontrada";
                    }
                }, [productData]);

  if (!productData) {
    return <Navigate to="/404" />;
  }

  return (
    <div className={`${productData.id}-page product-page`}>
      <Welcome data={productData.welcome[0]} />
      <Details details={productData.details} checkout={true} />
      <TechnicalSpecificationsCheckout data={productData.technicalSpecifications} />
      <BannerSayYes data={productData.bannerSayYes[0]} />
      <Plugins data={productData.plugins}/>
      <PluginsBenefits data={productData.pluginBenefits}/>
      <OurPlugins data={productData.ourPlugins}/>
      <Sectors data={productData.sectors[0]} bg={true} />
      <FAQ data={productData.faq[0]} />
      <CarouselSolutions data={productData.carosuelSolutions[0]} />
    </div>
  );
};

export default GetCheckout;

