import { recentArticles } from "../recentlyArt";

export const template01InitialValues = {
  preview: {
    date: "",
    title: "",
    text: "",
    image: "",
    link: "",
  },
  welcomeData: { title: "", mainImage: null },
  bannerData: [
    { title: "", pOne: "", pTwo: "", pThree: "", image: null },
    { title: "", pOne: "", pTwo: "", pThree: "", image: null },
    { title: "", pOne: "", pTwo: "", pThree: "", image: null },
  ],
  sectionData: [
    {
      title: "",
      pOneB: "",
      pOne: "",
      pTwoB: "",
      pTwo: "",
      pThreeB: "",
      pThree: "",
      image: null,
    },
    {
      title: "",
      pOneB: "",
      pOne: "",
      pTwoB: "",
      pTwo: "",
      pThreeB: "",
      pThree: "",
      image: null,
    },
    {
      title: "",
      pOneB: "",
      pOne: "",
      pTwoB: "",
      pTwo: "",
      pThreeB: "",
      pThree: "",
      image: null,
    },
    {
      title: "",
      pOneB: "",
      pOne: "",
      pTwoB: "",
      pTwo: "",
      pThreeB: "",
      pThree: "",
      image: null,
    },
  ],
  recentData: {
    title: "",
    articles: recentArticles,
  },
};
