import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  fireStore,
} from "../utils/firebase/config";
import BlogArticle01 from "../pages/BlogArticle01";
import BlogArticle02 from "../pages/BlogArticle02";
import BlogArticle03 from "../pages/BlogArticle03";
import BlogArticle04 from "../pages/BlogArticle04";
import BlogArticle05 from "../pages/BlogArticle05";
import BlogArticle06 from "../pages/BlogArticle06";
import BlogArticle07 from "../pages/BlogArticle07";
import BlogArticle08 from "../pages/BlogArticle08";
import BlogArticle09 from "../pages/BlogArticle09";
import BlogArticle10  from "../pages/BlogArticle10";
import { Spinner } from "@nextui-org/react";
import { templates } from "../pages/blog/utils/template";

const BlogPageArticle = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);

  const articleComponents = {
    "estrategia-de-marketing-para-no-marketers": BlogArticle01,
    "fidelizacion-clientes-temporada": BlogArticle02,
    'seguridad-en-transacciones-en-linea': BlogArticle03,
    "gastos-fijos-variables": BlogArticle04,
    "conoce-get-checkout": BlogArticle05,
    "plugins-getnet": BlogArticle06,
    "soluciones-pago": BlogArticle07,
    "ligas-pago": BlogArticle08,
    "pagos-contactless": BlogArticle09,
    "importancia-sitio-web":BlogArticle10,
  };

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const blogsCollection = collection(fireStore, "blogs");
        const link = `/blog/${id}`
        const q = query(blogsCollection, where("preview.link", "==", link));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const articleDoc = querySnapshot.docs[0];
          setArticle({ id: articleDoc.id, ...articleDoc.data() });
        } else {
          setArticle(null);
        }
      } catch (error) {
        console.error("Error al obtener el artículo:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner size="lg" />
      </div>
    );
  }

  const ArticleComponent = articleComponents[id];

  if (ArticleComponent && !loading) {
    return <ArticleComponent />;
  }
  
  if (article) {
    const template = templates.find((template) => template.id = article.template)
    return template.render(article.data, false)
  }

  return (
    <h1 className="text-center text-2xl font-bold mt-10">
      Artículo no encontrado
    </h1>
  );
};

export default BlogPageArticle;