import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const storeApi = (set) => ({
  currentUser: {
    fullName: "",
    email: "",
    area: "",
  },
  id: "",
  token: "",
  isAuthenticated: false,
  loginUser: (response) => {
    set({
      currentUser: {
        fullName: response.name,
        email: response.email,
        area: response.area,
      },
      id: response.id,
      token: response.accessToken,
      isAuthenticated: true,
    });
  },
  logout: () => {
    set({
      currentUser: {
        fullName: "",
        email: "",
        area: "",
      },
      id: "",
      token: "",
      isAuthenticated: false,
    });
  },
});

export const useAuthStore = create()(
  devtools(persist(storeApi, { name: "auth" }))
);

useAuthStore.subscribe((nextState) => {
  const { isAuthenticated } = nextState;
  if (!isAuthenticated) {
    localStorage.clear();
  }
});
